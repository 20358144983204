import { comlinkPush } from '@blue-agency/im-shared-front'
import { BizAnakinBffServicePromiseClient } from '@blue-agency/proton/biz_anakin_bff'
import { callWithRetry } from './callWithRetry'
import { logRpcErr } from './logRpcErr'

const hostname = process.env.REACT_APP_API_HOST
if (!hostname) throw new Error('hostname not found')

const bizAnakinBffClient = new BizAnakinBffServicePromiseClient(hostname)

if (process.env.REACT_APP_GRPC_WEB_DEVTOOLS_ENABLED === 'true') {
  // @ts-ignore
  const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {})
  enableDevTools([bizAnakinBffClient])
}

export function callBizAnakinBffService<
  T extends keyof BizAnakinBffServicePromiseClient
>(
  rpcName: T,
  req: Parameters<BizAnakinBffServicePromiseClient[T]>,
  loggedInStaffGuid: string | undefined
): ReturnType<BizAnakinBffServicePromiseClient[T]> {
  const metadata: Record<string, string> = {
    rpc: rpcName,
  }

  if (loggedInStaffGuid) {
    metadata['loggedInStaffGuid'] = loggedInStaffGuid
  }

  comlinkPush({
    action: 'call_rpc',
    metadata,
  })

  // @ts-expect-error
  return callWithRetry(() =>
    // @ts-expect-error
    bizAnakinBffClient[rpcName].apply(bizAnakinBffClient, req).catch((e) => {
      logRpcErr(rpcName, e, loggedInStaffGuid)
      throw e
    })
  )
}
