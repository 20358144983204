import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateOrganizationRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateOrganizationRequestValue = {
  name: string
}

export function useRequestUpdateOrganization() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateOrganization = useCallback(
    async (params: UpdateOrganizationRequestValue) => {
      const req = new UpdateOrganizationRequest()
      req.setName(params.name)

      const metadata = await buildMetadata()

      return callBizAnakinBffService(
        'updateOrganization',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestUpdateOrganization }
}
