import { Txt, WeakColorSeparator } from '@blue-agency/rogue'
import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`

export const RowTitle = styled(Txt)`
  flex: 0 0 180px;
  width: 180px;
`

export const RowTxt = styled(Txt)`
  flex: 1;
`

export const Separator = styled(WeakColorSeparator)`
  margin-top: 20px;
`
