import { useCallback, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RegisterOrganizationForm, validationScheme } from '../formScheme'

export function useInputStep(
  completedForm: RegisterOrganizationForm | undefined,
  onComplete: (form: RegisterOrganizationForm) => void
) {
  const { register, formState, handleSubmit } =
    useForm<RegisterOrganizationForm>({
      resolver: yupResolver(validationScheme),
      mode: 'onChange',
      defaultValues: completedForm,
    })
  const [agreement, setAgreement] = useState(false)

  const toggleAgreement = useCallback(() => {
    setAgreement((prev) => !prev)
  }, [])

  const onSubmit = useCallback<SubmitHandler<RegisterOrganizationForm>>(
    (data) => {
      if (!agreement) {
        window.alert('利用規約とプライバシーポリシーに同意してください')
        return
      }

      onComplete(data)
    },
    [agreement, onComplete]
  )

  const handleFormSubmit = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit]
  )

  return {
    register,
    errors: formState.errors,
    agreement,
    toggleAgreement,
    handleFormSubmit,
  }
}
