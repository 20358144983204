import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetConnectedIPAddressRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetConnectedIpAddress() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetConnectedIpAddress = useCallback(async () => {
    const req = new GetConnectedIPAddressRequest()
    const metadata = await buildMetadata()

    return callBizAnakinBffService(
      'getConnectedIPAddress',
      [req, metadata],
      loggedInStaff?.guid
    )
  }, [buildMetadata, loggedInStaff])

  return { requestGetConnectedIpAddress }
}
