import React from 'react'
import { AuthContainer, getInternalUrls } from '@blue-agency/im-shared-front'
import { Switch, Route } from 'react-router-dom'
import { AuthorizedRoutes } from './AuthorizedRoutes'
import { UnauthorizedRoutes } from './UnauthorizedRoutes'

// 一括ログアウト時にiframeでログアウトするためのエンドポイント
// 未ログインでもアクセスされる
// e.g. anakinログイン済み・hutt未ログインのときanakinでログアウト
const FrontchannelLogout = () => {
  const { authClient } = AuthContainer.useContainer()
  authClient.frontchannelLogout()
  return null
}

export const AuthRoutes = () => {
  const { isLoggedIn } = AuthContainer.useContainer()
  return (
    <Switch>
      <Route
        exact
        path={getInternalUrls().frontchannelLogout.bizAnakinFront}
        component={FrontchannelLogout}
      />
      {isLoggedIn ? <AuthorizedRoutes /> : <UnauthorizedRoutes />}
    </Switch>
  )
}
