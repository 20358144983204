import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { Link } from '@/components/Link'
import { ListContentLayout } from '@/components/ListContentLayout'
import { Loading } from '@/components/Loading'
import { ListPageHeader } from '@/components/PageHeader'
import { SettingStaffListPageContainer } from '@/pages/SettingStaffListPage/SettingStaffListPageContainer'
import { INTERNAL_PATHS } from '@/services/urlService'
import { Sidebar } from './Sidebar'
import { StaffsTable } from './StaffsTable'

const sizePerPage = 100

export const SettingStaffListPage: React.VFC = provide(
  SettingStaffListPageContainer
)(() => {
  const {
    isLoading,
    onSelectTable,
    activeTable,
    activeStaffList,
    staffLength,
    suspendedStaffLength,
    staffListFilterCondition,
    onUpdateStaffListFilterCondition,
    onResetStaffListFilterCondition,
  } = SettingStaffListPageContainer.useContainer()

  if (isLoading) {
    return <Loading />
  }

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <ListPageHeader
        title="ユーザー一覧"
        right={
          <Link to={INTERNAL_PATHS.settings.staffs.registerSelect}>
            <PrimaryButton
              widthSize="L1"
              comlinkPushParams={{ action: 'click_register_user' }}
            >
              ユーザー登録
            </PrimaryButton>
          </Link>
        }
      />
      <ListContentLayout
        sidebar={
          <Sidebar
            activeTable={activeTable}
            activeStaffCount={activeStaffList.staffs.length}
            staffLength={staffLength}
            suspendedStaffLength={suspendedStaffLength}
            onSelectTable={onSelectTable}
            staffListFilterCondition={staffListFilterCondition}
            onUpdateStaffListFilterCondition={onUpdateStaffListFilterCondition}
            onResetStaffListFilterCondition={onResetStaffListFilterCondition}
          />
        }
      >
        <StaffsTable staffList={activeStaffList} sizePerPage={sizePerPage} />
      </ListContentLayout>
    </PageLayoutWithGlonavi>
  )
})
