import * as yup from 'yup'
import { ActivateStaffRequestValue } from '@/services/bffService'
import {
  passwordConfirmationSchema,
  passwordSchema,
} from '@/services/formService'

export type ActivateStaffForm = Pick<ActivateStaffRequestValue, 'password'> & {
  passwordConfirmation: string
}

export const validationScheme: yup.SchemaOf<ActivateStaffForm> = yup.object({
  password: passwordSchema,
  passwordConfirmation: passwordConfirmationSchema,
})
