import { useCallback, useState } from 'react'
import { assertNever } from '@/assertions'
import { StaffDraft } from './StaffDraft'

type Step = 'input' | 'confirm' | 'completed'

type Result =
  | {
      step: 'input'
      goNext?: (staffDrafts: StaffDraft[]) => void
    }
  | {
      step: 'confirm'
      staffDrafts: StaffDraft[]
      goBackToInput: () => void
      goNext: () => void
    }
  | {
      step: 'completed'
      staffDrafts: StaffDraft[]
    }

export function useSettingStaffRegisterByFilePage(): Result {
  const [step, setStep] = useState<Step>('input')

  const [staffDrafts, setStaffDrafts] = useState<StaffDraft[]>()

  const onNextOnInput = useCallback((staffDrafts: StaffDraft[]) => {
    setStaffDrafts(staffDrafts)
    setStep('confirm')
  }, [])

  const goBackToInput = useCallback(() => {
    setStaffDrafts(undefined)
    setStep('input')
  }, [])

  const goNextOnConfirm = useCallback(() => {
    setStep('completed')
  }, [])

  if (step === 'input') {
    return {
      step,
      goNext: onNextOnInput,
    }
  }

  if (!staffDrafts) throw new Error('staffDrafts is undefined')

  if (step === 'confirm') {
    return {
      step: 'confirm',
      staffDrafts,
      goBackToInput,
      goNext: goNextOnConfirm,
    }
  }
  if (step === 'completed') {
    return {
      step: 'completed',
      staffDrafts,
    }
  }

  assertNever(step)
}
