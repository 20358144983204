import { WeakColorSeparator } from '@blue-agency/rogue'
import { FormRow } from '@blue-agency/rogue/im'
import styled from 'styled-components'

export const FormSection = styled.div`
  padding: 0 20px;
`

export const StyledFormRow = styled(FormRow)`
  margin-top: 20px;
`

export const Separator = styled(WeakColorSeparator)`
  margin-top: 20px;
`
