import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { theme, ErrorBox as _ErrorBox } from '@blue-agency/rogue'
import styled from 'styled-components'
import { ErrorTxt } from '@/components/ErrorTxt'
import { SettingsSection } from '@/components/SettingsSection'
import { SettingStaffRegisterByFormPageContainer } from '../SettingStaffRegisterByFormPageContainer'
import { Body } from './Body'
import { Buttons } from './Buttons'

export const Input: React.VFC = () => {
  const { submitErrors } =
    SettingStaffRegisterByFormPageContainer.useContainer()

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>ユーザー登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          {submitErrors['email'] && (
            <ErrorBox>
              <ErrorTxt>{submitErrors['email']}</ErrorTxt>
            </ErrorBox>
          )}
          <SettingsSection title="基本情報">
            <Body />
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <Buttons />
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const ErrorBox = styled(_ErrorBox)`
  width: 960px;
  margin: 20px auto;
  background-color: rgba(0, 0, 0, 0);
`
