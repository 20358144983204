export const password = {
  code: '1',
  type: 'password',
} as const

export const microsoft = {
  code: '2',
  type: 'microsoft',
} as const

export const google = {
  code: '3',
  type: 'google',
} as const

export type Password = typeof password
export type Microsoft = typeof microsoft
export type Google = typeof google
export type AuthnType = Password | Microsoft | Google

const types: Record<AuthnType['code'], AuthnType> = {
  [password.code]: password,
  [microsoft.code]: microsoft,
  [google.code]: google,
}

export const authnTypeOf = (value: AuthnType['code']): AuthnType => types[value]
