import { GetOrganizationByRegisterTokenRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from '@/services/bffService/bffClient'

export function useRequestGetOrganizationByRegisterToken() {
  const requestGetOrganizationByRegisterToken = async (token: string) => {
    const req = new GetOrganizationByRegisterTokenRequest()
    req.setToken(token)
    return callBizAnakinBffService(
      'getOrganizationByRegisterToken',
      [req],
      undefined
    )
  }
  return { requestGetOrganizationByRegisterToken }
}
