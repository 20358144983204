import React from 'react'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { SettingStaffEditPageContainer } from '../../SettingStaffEditPageContainer'
import { AuthnTypeSection } from './AuthnTypeSection'
import { AuthzTypeSection } from './AuthzTypeSection'
import { EmailSection } from './EmailSection'
import { NameSection } from './NameSection'

export const Body: React.VFC<PropsWithClassName> = ({ className }) => {
  const { staff } = SettingStaffEditPageContainer.useContainer()
  if (!staff) return null
  return (
    <div className={className}>
      <NameSection />
      <EmailSection />
      <AuthnTypeSection staff={staff} />
      <AuthzTypeSection />
    </div>
  )
}
