import React from 'react'
import { AuthnTypeSectionInput } from '@/components/AuthnTypeSectionInput'
import { SettingStaffRegisterByFormPageContainer } from '../../SettingStaffRegisterByFormPageContainer'

export const AuthnTypeSection: React.VFC = () => {
  const { register, completedForm } =
    SettingStaffRegisterByFormPageContainer.useContainer()
  return (
    <AuthnTypeSectionInput
      registerAuthnType={register('authnType')}
      defaultValue={completedForm?.authnType ?? 'password'}
    />
  )
}
