import { AuthzType } from '@blue-agency/im-shared-front'
import * as yup from 'yup'
import type { AuthnType } from '@/services/authnService'
import { CsvBody } from '.'
import { StaffDraft } from '../../StaffDraft'

export type DetailValidationError = {
  rowNumber: number
  message: string
}

type Response =
  | {
      type: 'error'
      errors: DetailValidationError[]
    }
  | {
      type: 'valid'
      staffDrafts: StaffDraft[]
    }

type Option = {
  // この件数以上エラーがあるときは validation を中止する
  errorLimit: number
}

export async function validateDetail(
  body: CsvBody,
  option?: Option
): Promise<Response> {
  const validationErrors: DetailValidationError[] = []
  const staffDrafts: StaffDraft[] = []

  const checkDuplicateEmail = (currentRowIndex: number): boolean => {
    return body.some((row, i) => {
      const isCurrentRow = i === currentRowIndex
      if (isCurrentRow) {
        return false
      }

      const targetEmail = body[currentRowIndex]?.[4]

      return row[4] === targetEmail
    })
  }

  let rowNumber = 1
  for (const row of body) {
    if (
      option?.errorLimit !== undefined &&
      validationErrors.length >= option.errorLimit
    ) {
      break
    }
    try {
      const rowResult = await validationSchema.validate(
        {
          familyName: row[0],
          givenName: row[1],
          familyNameKana: row[2],
          givenNameKana: row[3],
          mail: row[4],
          authnType: row[5],
          authzType: row[6],
        },
        { abortEarly: false }
      )

      if (rowResult === undefined) {
        throw new Error('エラーが発生しました')
      }

      if (checkDuplicateEmail(rowNumber - 1)) {
        // yup の validate に合わせるために errors プロパティを持つオブジェクトを throw する
        // eslint-disable-next-line no-throw-literal
        throw {
          errors: [`${rowResult.mail}は、取込ファイル内で重複しています。`],
        }
      }

      staffDrafts.push(rowResult)
    } catch (e) {
      // https://eslint.org/docs/rules/no-loop-func を避けるために変数を取る
      const rowNum = rowNumber
      if (e.errors) {
        e.errors.forEach((error: string) => {
          validationErrors.push({
            rowNumber: rowNum,
            message: error,
          })
        })
      } else {
        // yup の validate 以外の Error のときは throw する
        throw e
      }
    }
    rowNumber += 1
  }

  if (validationErrors.length >= 1) {
    return {
      type: 'error',
      errors: validationErrors,
    }
  }
  return {
    type: 'valid',
    staffDrafts,
  }
}

const validationSchema: yup.SchemaOf<StaffDraft> = yup.object({
  familyName: buildBasicStringSchema('姓'),
  givenName: buildBasicStringSchema('名'),
  familyNameKana: buildBasicStringSchema('姓(かな)'),
  givenNameKana: buildBasicStringSchema('名(かな)'),
  mail: yup
    .string()
    .email('メールアドレスが不正です。')
    .required('メールアドレスは、必須項目です。データを登録してください。'),
  authnType: yup
    .mixed<AuthnType['code']>()
    .required('認証方法は、必須項目です。データを登録してください。')
    .oneOf(
      ['1', '2', '3'],
      '認証方法は、規定のフォーマット（半角数字）で登録してください。'
    ),
  authzType: yup
    .mixed<AuthzType['type']>()
    .required('権限タイプは、必須項目です。データを登録してください。')
    .oneOf(
      ['1', '2', '3'],
      '権限タイプは、規定のフォーマット（半角数字）で登録してください。'
    ),
})

function buildBasicStringSchema(field: string) {
  return yup
    .string()
    .required(`${field}は、必須項目です。データを登録してください。`)
    .max(255, `${field}は、255文字以下で登録してください。`)
}
