import { useCallback } from 'react'
import {
  AuthzType,
  authzTypeToProto,
  useLoggedInStaff,
} from '@blue-agency/im-shared-front'
import { UpdateStaffRequest } from '@blue-agency/proton/biz_anakin_bff'
import { Name, NameKana } from '@blue-agency/proton/web/v2/name_data_pb'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateStaffRequestValue = {
  guid: string
  familyName: string
  givenName: string
  familyNameKana: string
  givenNameKana: string
  authzType: AuthzType['type']
}

export function useRequestUpdateStaff() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateStaff = useCallback(
    async (params: UpdateStaffRequestValue) => {
      const name = new Name()
      name.setFamilyName(params.familyName)
      name.setGivenName(params.givenName)

      const nameKana = new NameKana()
      nameKana.setFamilyNameKana(params.familyNameKana)
      nameKana.setGivenNameKana(params.givenNameKana)

      const req = new UpdateStaffRequest()
      req.setGuid(params.guid)
      req.setName(name)
      req.setNameKana(nameKana)
      req.setAuthzType(authzTypeToProto(params.authzType))

      const metadata = await buildMetadata()

      return callBizAnakinBffService(
        'updateStaff',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestUpdateStaff }
}
