import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { SettingsSection } from '@/components/SettingsSection'
import { SettingStaffRegisterByFormPageContainer } from '../SettingStaffRegisterByFormPageContainer'
import { Body } from './Body'
import { Buttons, cautionText } from './Bottom'

export const Confirm: React.VFC = () => {
  const { onSubmit, completedForm } =
    SettingStaffRegisterByFormPageContainer.useContainer()

  if (!completedForm) {
    throw new Error('invalid transition')
  }

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper onSubmit={onSubmit} bottomHeight={127}>
        <Page.Header>
          <Page.HeaderTitle>ユーザー登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="内容の確認">
            <Body completedForm={completedForm} />
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomCautionText>{cautionText}</Page.BottomCautionText>
          <Page.BottomButtons>
            <Buttons />
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}
