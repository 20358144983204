import React from 'react'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { PageLayout } from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { SuspenseWithLoadingOverlay } from '@/components/SuspenseWithLoadingOverlay'
import { CustomBizAnakinBffGrpcError } from '@/services/bffService'
import { InvalidArgument } from './InvalidArgument'
import { Registered } from './Registered'
import { useRegisterOrganization } from './useRegisterOrganization'

export const RegisterOrganizationPage: React.VFC = (props) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SuspenseWithLoadingOverlay>
        <Content {...props} />
      </SuspenseWithLoadingOverlay>
    </ErrorBoundary>
  )
}

const Content: React.VFC = () => {
  usePromptBeforeUnload()

  const { renderStep } = useRegisterOrganization()
  return (
    <PageLayout backgroundColor={theme.color.gray[5]} disableLogoLink>
      {renderStep()}
    </PageLayout>
  )
}

const ErrorFallback = ({ error }: FallbackProps) => {
  if (error instanceof CustomBizAnakinBffGrpcError) {
    if (error.isAlreadyExists) {
      return <Registered />
    }
    if (error.isInvalidArgument) {
      return <InvalidArgument />
    }
  }

  throw error
}
