import React from 'react'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Modal } from '@/components/Modal'
import { Name } from '@/services/staffService/name'

type Props = PropsWithClassName<{
  name: Name
  active: boolean
  onClose: () => void
  onSubmit: () => void
}>

export const UnregisterStaffModal: React.VFC<Props> = ({
  active,
  name,
  className,
  onClose,
  onSubmit,
}: Props) => {
  return (
    <Modal.Wrapper
      active={active}
      title="ユーザーの削除"
      stopScroll
      className={className}
      onClose={onClose}
    >
      <Modal.Body>
        <Modal.Txt>「{name.fullName}」を削除します。</Modal.Txt>
        <Modal.Txt>削除したデータを復元することはできません。</Modal.Txt>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_unregister_staff_button',
          }}
        >
          キャンセル
        </Modal.CancelButton>
        <Modal.DangerSubmitButton
          onClick={onSubmit}
          comlinkPushParams={{
            action: 'click_unregister_staff_button',
          }}
        >
          削除
        </Modal.DangerSubmitButton>
      </Modal.Footer>
    </Modal.Wrapper>
  )
}
