import React from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { theme, Txt as _Txt, Icon as _Icon } from '@blue-agency/rogue'
import {
  TertiaryButton,
  SecondaryDangerButton,
  SecondaryButton,
} from '@blue-agency/rogue/im'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { AuthnTypeSection } from '@/components/AuthnTypeSection'
import { Form } from '@/components/Form'
import { TextLink } from '@/components/Link'
import { SettingsSection } from '@/components/SettingsSection'
import {
  InactiveStaffBadge,
  SuspendedStaffBadge,
  ResumedStaffBadge,
  RegistrationExpiredStaffBadge,
} from '@/components/StaffBadge'
import { Staff } from '@/services/staffService/types'
import { INTERNAL_PATHS } from '@/services/urlService'
import { ResumeStaffModal } from './ResumeStaffModal'
import { SendRegisterStaffMailModal } from './SendRegisterStaffMailModal'
import { SuspendStaffModal } from './SuspendStaffModal'
import { UnregisterStaffModal } from './UnregisterStaffModal'
import { useResumeStaff } from './useResumeStaff'
import { useSendRegisterStaffMail } from './useSendRegisterStaffMail'
import { useSuspendStaff } from './useSuspendStaff'
import { useUnregisterStaff } from './useUnregisterStaff'

type Props = PropsWithClassName<{ staff: Staff }>

export const Content: React.VFC<Props> = ({ staff, className }) => {
  const loggedInStaff = useLoggedInStaff()

  const { sendRegisterStaffMailModal, onSubmitSendRegisterStaffMail } =
    useSendRegisterStaffMail(staff.guid)

  const { unregisterStaffModal, onSubmitUnregisterStaff } = useUnregisterStaff(
    staff.guid
  )

  const { suspendStaffModal, onSubmitSuspendStaff } = useSuspendStaff(
    staff.guid
  )

  const { resumeStaffModal, onSubmitResumeStaff } = useResumeStaff(staff.guid)

  const title = staff.suspended ? (
    <SettingSectionTitle>
      <SettingSectionTitleTxt size="l">ユーザー詳細</SettingSectionTitleTxt>
      <SettingSectionTitleItem>
        <SuspendedStaffBadge />
      </SettingSectionTitleItem>
    </SettingSectionTitle>
  ) : (
    'ユーザー詳細'
  )

  const renderBadge = () => {
    if (staff.isRegistrationExpired) {
      return <RegistrationExpiredStaffBadge />
    }
    if (staff.status.isRegistered()) {
      return <InactiveStaffBadge />
    }
    if (staff.status.isResumed()) {
      return <ResumedStaffBadge />
    }
  }

  const canSendMail = staff.status.isRegistered() || staff.status.isResumed()

  return (
    <Wrapper className={className}>
      <SettingsSection title={title}>
        {staff.suspended ? (
          <SuspendedStaffToolbar onClickResume={resumeStaffModal.open} />
        ) : (
          <StaffToolbar
            staff={staff}
            disableSuspendButton={
              !!loggedInStaff && loggedInStaff.guid === staff.guid
            }
            onClickDelete={unregisterStaffModal.open}
            onClickSuspend={suspendStaffModal.open}
          />
        )}
        <Form.Section>
          <Form.Row>
            <Form.Title>氏名</Form.Title>
            <Form.Item>
              <Form.Label>氏名</Form.Label>
              <Form.Field>
                <Txt>{staff.name.familyName}</Txt>
                <Txt>{staff.name.givenName}</Txt>
              </Form.Field>
            </Form.Item>
          </Form.Row>
          <Form.Row>
            <Form.Item>
              <Form.Label>かな</Form.Label>
              <Form.Field>
                <Txt>{staff.nameKana.familyName}</Txt>
                <Txt>{staff.nameKana.givenName}</Txt>
              </Form.Field>
            </Form.Item>
          </Form.Row>
        </Form.Section>
        <Form.Section>
          <Form.Row>
            <Form.Title>メールアドレス</Form.Title>
            <Form.Item>
              <Form.EmailField>
                <Form.EmailTxt>{staff.email}</Form.EmailTxt>
                {canSendMail && (
                  <Form.EmailButtons>
                    {renderBadge()}
                    <SendRegisterStaffMailButton
                      comlinkPushParams={{
                        action: 'open_send_register_staff_mail_modal',
                      }}
                      onClick={sendRegisterStaffMailModal.open}
                    >
                      再送信
                    </SendRegisterStaffMailButton>
                  </Form.EmailButtons>
                )}
              </Form.EmailField>
            </Form.Item>
          </Form.Row>
        </Form.Section>
        <AuthnTypeSection authnType={staff.authnType.type} />
        <Form.Section>
          <Form.Row>
            <Form.Title>権限タイプ</Form.Title>
            <Form.Item>
              <Form.Field>
                <Txt>{staff.authzType.name}</Txt>
              </Form.Field>
            </Form.Item>
          </Form.Row>
        </Form.Section>
      </SettingsSection>
      <SendRegisterStaffMailModal
        active={sendRegisterStaffMailModal.active}
        onClose={sendRegisterStaffMailModal.close}
        onSubmit={onSubmitSendRegisterStaffMail}
      />
      <UnregisterStaffModal
        name={staff.name}
        active={unregisterStaffModal.active}
        onClose={unregisterStaffModal.close}
        onSubmit={onSubmitUnregisterStaff}
      />
      <SuspendStaffModal
        name={staff.name}
        active={suspendStaffModal.active}
        onClose={suspendStaffModal.close}
        onSubmit={onSubmitSuspendStaff}
      />
      <ResumeStaffModal
        name={staff.name}
        active={resumeStaffModal.active}
        onClose={resumeStaffModal.close}
        onSubmit={onSubmitResumeStaff}
      />
    </Wrapper>
  )
}

const StaffToolbar: React.VFC<{
  staff: Staff
  disableSuspendButton: boolean
  onClickDelete: () => void
  onClickSuspend: () => void
}> = ({ staff, disableSuspendButton, onClickDelete, onClickSuspend }) => (
  <Toolbar>
    <ToolElement>
      <EditButton
        to={generatePath(INTERNAL_PATHS.settings.staffs.edit, {
          guid: staff.guid,
        })}
      >
        <Icon name="edit" />
        編集
      </EditButton>
    </ToolElement>
    {staff.status.isRegistered() && (
      <ToolElement>
        <DeleteButton to="#" onClick={onClickDelete}>
          <Icon name="delete" />
          削除
        </DeleteButton>
      </ToolElement>
    )}
    {staff.status.isActivated() && (
      <ToolElement>
        <SuspendButton
          disabled={disableSuspendButton}
          comlinkPushParams={{ action: 'click_suspend_staff' }}
          onClick={onClickSuspend}
        >
          利用停止
        </SuspendButton>
      </ToolElement>
    )}
  </Toolbar>
)

const SuspendedStaffToolbar: React.VFC<{ onClickResume: () => void }> = ({
  onClickResume,
}) => (
  <Toolbar>
    <ToolElement>
      <ResumeButton
        comlinkPushParams={{ action: 'click_resume_staff' }}
        onClick={onClickResume}
      >
        利用再開
      </ResumeButton>
    </ToolElement>
  </Toolbar>
)

const Wrapper = styled.div``

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  margin-bottom: -20px;
  height: 40px;
`

const Icon = styled(_Icon)`
  vertical-align: middle;
  margin-right: 7px;
`

const ToolElement = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 12px;
  margin-left: 20px;
`

const EditButton = styled(TextLink)`
  color: ${theme.color.navy[1]};
`

const DeleteButton = styled(TextLink)`
  color: ${theme.color.red[2]};
`

const SuspendButton = styled(SecondaryDangerButton).attrs({ widthSize: 'M' })``

const ResumeButton = styled(SecondaryButton).attrs({ widthSize: 'M' })``

const SendRegisterStaffMailButton = styled(TertiaryButton).attrs({
  widthSize: 'M',
  heightSize: 'S',
})`
  padding: 6px;
  margin-left: 20px;
`

const Txt = styled(Form.Txt).attrs({ size: 'm' })`
  &:not(:first-child) {
    margin-left: 4px;
  }
`

const SettingSectionTitle = styled.div`
  display: flex;
  vertical-align: middle;
`

const SettingSectionTitleTxt = styled(_Txt)`
  margin: auto 0;
`

const SettingSectionTitleItem = styled.div`
  margin: auto 0;
`
