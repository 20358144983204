import React from 'react'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Form } from '@/components/Form'

export const EmailSection = ({
  email,
  className,
}: PropsWithClassName<{ email: string }>): React.ReactElement => {
  return (
    <Form.Section className={className}>
      <Form.Row>
        <Form.Title>メールアドレス</Form.Title>
        <Form.Item>
          <Form.EmailField>
            <Form.EmailTxt>{email}</Form.EmailTxt>
          </Form.EmailField>
        </Form.Item>
      </Form.Row>
    </Form.Section>
  )
}
