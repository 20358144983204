import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { theme, Txt } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Link } from '@/components/Link'
import { SettingsSection } from '@/components/SettingsSection'
import { INTERNAL_PATHS } from '@/services/urlService'
import { StaffDraft } from '../StaffDraft'

type Props = {
  staffDrafts: StaffDraft[]
}

export const Completed: React.VFC<Props> = (props) => {
  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>ユーザー一括登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="登録完了">
            <SectionInner>
              <MainTextWrapper>
                <Txt size="xxl" color={theme.color.green[4]}>
                  {props.staffDrafts.length}
                </Txt>
                <Txt size="l">件のユーザー登録が完了しました</Txt>
              </MainTextWrapper>

              <SubTextWrapper>
                <Txt>
                  登録したメールアドレスに、利用開始メールを送信しました。
                  <br />
                  メールをご確認いただき、ログインしてください。
                </Txt>
              </SubTextWrapper>
            </SectionInner>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <NoStyledLink to={INTERNAL_PATHS.settings.staffs.index}>
              <TertiaryButton
                widthSize="L2"
                comlinkPushParams={{
                  action: 'click_back_to_staffs_index',
                }}
              >
                一覧へ戻る
              </TertiaryButton>
            </NoStyledLink>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const MainTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SubTextWrapper = styled.div`
  margin-top: 16px;
  text-align: center;
`

const SectionInner = styled.div`
  padding: 48px 20px;
`

const NoStyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
