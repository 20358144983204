import React, { useCallback, useMemo } from 'react'
import {
  commonErrorToast,
  useLoggedInStaff,
} from '@blue-agency/im-shared-front'
import { Txt, alertToast, toast } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import * as Sentry from '@sentry/react'
import { useHistory, generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { ChangeAuthnTypeModal } from '@/components/ChangeAuthnTypeModal'
import { Form } from '@/components/Form'
import { authnTypeToText } from '@/components/authnType'
import { useModal } from '@/hooks/useModal'
import type { AuthnType } from '@/services/authnService'
import { CustomBizAnakinBffGrpcError } from '@/services/bffService'
import { Staff } from '@/services/staffService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useUpdateStaffAuthnType } from './useUpdateStaffAuthnType'

export const AuthnTypeSection: React.VFC<{ staff: Staff }> = ({ staff }) => {
  const { mutateAsync } = useUpdateStaffAuthnType(staff.guid)
  const pathToStaffDetailPage = useMemo(
    () =>
      generatePath(INTERNAL_PATHS.settings.staffs.detail, { guid: staff.guid }),
    [staff.guid]
  )
  const history = useHistory()
  const onSubmit = useCallback(
    async (newAuthnType: AuthnType['type']) => {
      try {
        await mutateAsync({
          guid: staff.guid,
          authnType: newAuthnType,
        })
        toast('認証方法を変更しました')
        history.push(pathToStaffDetailPage)
      } catch (e) {
        if (
          e instanceof CustomBizAnakinBffGrpcError &&
          e.isFailedPrecondition
        ) {
          alertToast(
            '処理に失敗しました。\nブラウザを再読込して、もう一度お試しください。'
          )
          return
        }
        Sentry.captureException(e)
        commonErrorToast()
        return
      }
    },
    [staff.guid, mutateAsync, history, pathToStaffDetailPage]
  )

  const modal = useModal()
  const authnTypeText = authnTypeToText(staff.authnType.type)

  const loggedInStaff = useLoggedInStaff()

  return (
    <Form.Section>
      <Form.Row>
        <Form.Title>認証方法</Form.Title>
        <Form.Item>
          <Form.Field>
            <AuthnTypeBox>
              <Txt>{authnTypeText}</Txt>
              {loggedInStaff?.guid !== staff.guid &&
                staff.status.isActivated() && (
                  <Button
                    onClick={modal.open}
                    comlinkPushParams={{
                      action: 'open_change_authn_type_modal',
                    }}
                  >
                    認証方法を変更
                  </Button>
                )}
            </AuthnTypeBox>
          </Form.Field>
        </Form.Item>
      </Form.Row>
      <ChangeAuthnTypeModal
        active={modal.active}
        close={modal.close}
        authnType={staff.authnType.type}
        onSubmit={onSubmit}
      />
    </Form.Section>
  )
}

const Button = styled(TertiaryButton).attrs({
  widthSize: 'L1',
  heightSize: 'S',
})``

const AuthnTypeBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`
