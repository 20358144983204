import { useCallback, useEffect, useState, useRef } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import {
  useStaffListQueryParam,
  StaffList,
  StaffListFilterCondition,
  convertFilterConditionToQueryString,
} from '@/services/staffService'
import { useStaffList } from './useStaffList'
import { StaffTable } from './useStaffsTable'

type Response = {
  isLoading: boolean
  activeTable: StaffTable
  onSelectTable: (table: StaffTable) => void
  activeStaffList: StaffList
  staffLength: number
  suspendedStaffLength: number
  staffListFilterCondition: StaffListFilterCondition
  onUpdateStaffListFilterCondition: (
    condition: StaffListFilterCondition
  ) => void
  onResetStaffListFilterCondition: () => void
}

const defaultStaffListFilterCondition: StaffListFilterCondition = {
  freeWord: '',
  status: {
    isRegisteredOrResumed: true,
    isActivated: true,
    isExpired: true,
  },
}

function useSettingStaffListPage(): Response {
  const { setQuery, activeTableParam, filterConditionParam } =
    useStaffListQueryParam()

  const [activeTable, setActiveTable] = useState<StaffTable>(
    activeTableParam ?? 'staffs'
  )

  const onSelectTable = useCallback((table: StaffTable) => {
    setActiveTable(table)
  }, [])

  const initialStaffListFilterCondition =
    filterConditionParam ?? defaultStaffListFilterCondition
  const [staffListFilterCondition, setStaffListFilterCondition] =
    useState<StaffListFilterCondition>(initialStaffListFilterCondition)

  /**
   * 選択している一覧(activeTable)が変わった時に検索条件をデフォルトに戻す
   * queryから検索条件を復元する場合、queryの値で初期化した後に初回useEffectが走るとデフォルトに戻されてしまうので、
   * 初回レンダリングでは処理をスキップしてその後activeTableに変更があった時のみデフォルトに戻すようにする
   */
  const isFirstRenderingRef = useRef(true)
  useEffect(() => {
    if (isFirstRenderingRef.current) {
      isFirstRenderingRef.current = false
      return
    }
    setStaffListFilterCondition(defaultStaffListFilterCondition)
  }, [activeTable])

  const onUpdateStaffListFilterCondition = useCallback(
    (condition: StaffListFilterCondition) => {
      setStaffListFilterCondition(condition)
    },
    []
  )

  const onResetStaffListFilterCondition = useCallback(() => {
    setStaffListFilterCondition(defaultStaffListFilterCondition)
  }, [])

  const staffList = useStaffList('staffs')
  const suspendedStaffList = useStaffList('suspendedStaffs')

  const isLoading =
    activeTable === 'staffs'
      ? staffList.isLoading
      : suspendedStaffList.isLoading

  const activeStaffList =
    activeTable === 'staffs'
      ? staffList.staffList.filter(staffListFilterCondition)
      : suspendedStaffList.staffList.filter(staffListFilterCondition)

  const staffLength = staffList.staffLength
  const suspendedStaffLength = suspendedStaffList.staffLength

  useEffect(() => {
    setQuery(
      {
        at: activeTable,
        fc: convertFilterConditionToQueryString(staffListFilterCondition),
      },
      'replaceIn'
    )
  }, [staffListFilterCondition, activeTable, setQuery])

  return {
    isLoading,
    activeTable,
    onSelectTable,
    activeStaffList,
    staffLength,
    suspendedStaffLength,
    staffListFilterCondition,
    onUpdateStaffListFilterCondition,
    onResetStaffListFilterCondition,
  }
}

export const SettingStaffListPageContainer = createContainer(
  useSettingStaffListPage
)
