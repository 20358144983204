import * as yup from 'yup'
import {
  basicStringSchema,
  passwordSchema,
  passwordConfirmationSchema,
} from '@/services/formService'

export const validationScheme: yup.SchemaOf<RegisterOrganizationForm> =
  yup.object({
    organizationName: basicStringSchema,

    givenName: basicStringSchema,
    familyName: basicStringSchema,
    givenNameKana: basicStringSchema,
    familyNameKana: basicStringSchema,

    password: passwordSchema,
    passwordConfirmation: passwordConfirmationSchema,
  })

export type RegisterOrganizationForm = {
  organizationName: string

  givenName: string
  familyName: string

  givenNameKana: string
  familyNameKana: string

  password: string
  passwordConfirmation: string
}
