import React from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Link } from '@/components/Link'

type Props = {
  title: React.ReactNode
  href: string
  isExternalLink?: boolean
}

export const SettingsCard = ({
  className,
  title,
  href,
  isExternalLink = false,
}: PropsWithClassName<Props>): React.ReactElement => {
  return isExternalLink ? (
    <WrapperAnchor className={className} href={href}>
      <Title>{title}</Title>
      <ArrowIcon />
    </WrapperAnchor>
  ) : (
    <Wrapper className={className} to={href}>
      <Title>{title}</Title>
      <ArrowIcon />
    </Wrapper>
  )
}

const sharedStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;

  border: 1px solid ${theme.color.gray[4]};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border-color: ${theme.color.navy[2]};
  }
`

const Wrapper = styled(Link)`
  ${sharedStyle}
`

const WrapperAnchor = styled.a`
  ${sharedStyle}
  text-decoration: none;
`

const ArrowIcon = styled(Icon).attrs({ name: 'arrow', size: 'm' })`
  transform: rotate(180deg);
`

const Title = styled(Txt).attrs({ size: 'l' })``
