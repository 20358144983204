import React from 'react'
import { Txt as _Txt, RequireTxt, theme } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { PropsWithClassName, PropsWithChildren } from '@/@types/propsWithTypes'
import { LineClampedTxt } from './LineClampedTxt'

type SectionProps = PropsWithChildren<
  PropsWithClassName<{ unBordered?: boolean }>
>

const Section = ({
  unBordered = false,
  children,
  className,
}: SectionProps): React.ReactElement => {
  return (
    <SectionWrapper className={className} bordered={!unBordered}>
      {children}
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div.attrs({ 'data-component': 'section' })<{
  bordered: boolean
}>`
  padding: 20px 0px;
  margin: 0px 20px;

  ${({ bordered }) =>
    bordered &&
    css`
      &[data-component='section'] + & {
        border-top: 1px solid ${theme.color.gray[4]};
      }
    `}
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 135px 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'section-title item';

  &:not(:first-of-type) {
    margin-top: 20px;
  }
`

const Title = styled.div`
  grid-area: section-title;
  align-self: start;
  font-size: ${theme.fontSize.m};
  display: flex;
`

const Item = styled.div`
  display: grid;
  grid-template-columns: 130px 30px 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'label required field'
    'helper helper helper';
  grid-area: item;
`

const Label = styled(_Txt).attrs({ size: 's', bold: true })`
  grid-area: label;
  align-self: start;
`

const Required = styled(RequireTxt).attrs({ size: 's' })`
  grid-area: required;
`

const Field = styled.div`
  grid-area: field;
  display: flex;
  align-self: start;
  align-items: start;
`

const Txt = styled(_Txt).attrs({ size: 'm' })`
  height: 20px;
  margin-bottom: 12px;
`

const EmailField = styled(Field)`
  display: flex;
  flex-direction: row;
`

const EmailTxt = styled(LineClampedTxt).attrs({ size: 'm', line: 4 })`
  height: 100%;
`

const EmailButtons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 6px;
  max-width: 270px;
  height: 24px;
`

interface HelperProps {
  textAlign?: 'left' | 'right'
}
const Helper = styled.div<HelperProps>`
  grid-area: helper;
  margin-top: 12px;
  margin-left: 160px;
  text-align: ${(props) => props.textAlign || 'left'};
  font-size: ${theme.fontSize.s};
  color: ${theme.color.gray[1]};
`

export const Form = {
  Section,
  Row,
  Title,
  Item,
  Label,
  Required,
  Field,
  Txt,
  EmailField,
  EmailTxt,
  EmailButtons,
  Helper,
}
