import React from 'react'
import { Txt } from '@blue-agency/rogue'
import { Form } from '@/components/Form'
import type { AuthnType } from '@/services/authnService'
import { authnTypeToText } from './authnType'

export const AuthnTypeSection: React.VFC<{
  authnType: AuthnType['type']
}> = ({ authnType }) => {
  return (
    <Form.Section>
      <Form.Row>
        <Form.Title>認証方法</Form.Title>
        <Form.Item>
          <Form.Field>
            <Txt>{authnTypeToText(authnType)}</Txt>
          </Form.Field>
        </Form.Item>
      </Form.Row>
    </Form.Section>
  )
}
