import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { Dropdown as _Dropdown, RadioGroup, theme } from '@blue-agency/rogue'
import { InputText as _InputText } from '@blue-agency/rogue/im'
import { DevTool } from '@hookform/devtools'
import styled from 'styled-components'
import { Form } from '@/components/Form'
import { SettingsSection } from '@/components/SettingsSection'
import { ScopeSpecifiedStaffsFormRow } from './ScopeSpecifiedStaffsFormRow'
import {
  subnetMaskOptions,
  scopeOptions,
  SettingIpFilterNewPageContainer,
} from './SettingIpFilterNewPageContainer'
import { TooltipForIpAddress, TooltipForScopeType } from './Tooltip'
import * as C from './components'
import { useCurrentIp } from './useCurrentIp'
import { useStaffs } from './useStaffs'

export const Input: React.VFC = () => {
  const { currentIp } = useCurrentIp()

  const {
    displayScopeUsers,
    cidrFields,

    register,
    control,
    errors,

    scopeSpecifiedStaffGuidFields,
    onAddScopeSpecifiedStaffGuid,
    onDeleteScopeSpecifiedStaffGuid,

    onAddCidrInput,
    onDeleteCidrInput,
    onConfirm,
    onCancelInput,
  } = SettingIpFilterNewPageContainer.useContainer()

  const { staffs } = useStaffs()

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper bottomHeight={127}>
        <Page.Header>
          <Page.HeaderTitle>IPアドレス制限登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="フィルター情報">
            <Form.Section>
              <C.SectionWrapper>
                <Form.Row>
                  <C.FormTitle>フィルター名</C.FormTitle>
                  <Form.Item>
                    <C.FormRequired>必須</C.FormRequired>
                    <C.FormField>
                      <InputText
                        {...register('name')}
                        placeholder="フィルター名"
                        onBlurError={errors['name']?.message}
                      />
                    </C.FormField>
                  </Form.Item>
                </Form.Row>
              </C.SectionWrapper>
              <C.SectionWrapper>
                <Form.Row>
                  <C.FormTitle>
                    対象範囲
                    <TooltipForScopeType />
                  </C.FormTitle>
                  <Form.Item>
                    <C.FormRequired>必須</C.FormRequired>
                    <C.FormField>
                      <RadioGroup
                        {...register('scopeType')}
                        direction="row"
                        options={scopeOptions}
                      />
                    </C.FormField>
                  </Form.Item>
                </Form.Row>
                {displayScopeUsers && (
                  <ScopeSpecifiedStaffsFormRow
                    register={register}
                    staffs={staffs}
                    scopeSpecifiedStaffGuidFields={
                      scopeSpecifiedStaffGuidFields
                    }
                    onSelect={(staff) =>
                      onAddScopeSpecifiedStaffGuid(
                        staff.guid,
                        staff.name.fullName
                      )
                    }
                    onDelete={onDeleteScopeSpecifiedStaffGuid}
                    onBlurError={(() => {
                      const error = errors?.scopeSpecifiedStaffs
                      // FIXME: Runtimeで型が合わない
                      return error !== undefined
                        ? '入力してください'
                        : undefined
                    })()}
                  />
                )}
              </C.SectionWrapper>
              <C.SectionWrapper>
                {cidrFields.map((item, index) => {
                  const error = (errors.cidrs ?? [])[index]
                  return (
                    <CidrRow key={item.id}>
                      <Form.Row>
                        {index === 0 && (
                          <C.FormTitle>
                            IPアドレス
                            <TooltipForIpAddress />
                          </C.FormTitle>
                        )}
                        <Form.Item>
                          <C.FormRequired>必須</C.FormRequired>
                          <C.FormField>
                            <IpAddressInputText
                              {...register(`cidrs.${index}.ipAddress` as const)}
                              placeholder="例）111.111.111.111"
                              defaultValue={item.ipAddress}
                              onBlurError={error?.ipAddress?.message}
                              errorNoWrap
                            />
                            <SubnetMaskDropdown
                              {...register(
                                `cidrs.${index}.subnetMask` as const
                              )}
                              options={subnetMaskOptions}
                              value={item.subnetMask}
                              onBlurError={error?.subnetMask?.message}
                            />
                            {index > 0 && (
                              <C.DeleteCidrTxtButton
                                onClick={() => onDeleteCidrInput(index)}
                              />
                            )}
                          </C.FormField>
                        </Form.Item>
                      </Form.Row>
                      {index === 0 && currentIp && (
                        <Form.Row>
                          <Form.Item>
                            <C.FormField>
                              <C.InputNote>
                                現在接続されているアドレス：{currentIp}
                              </C.InputNote>
                            </C.FormField>
                          </Form.Item>
                        </Form.Row>
                      )}
                    </CidrRow>
                  )
                })}
                <CidrRow>
                  <Form.Row>
                    <Form.Item>
                      <C.FormField>
                        <C.AddCidrTxtButton onClick={onAddCidrInput} />
                      </C.FormField>
                    </Form.Item>
                  </Form.Row>
                </CidrRow>
              </C.SectionWrapper>
            </Form.Section>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomCautionText>{C.cautionText}</Page.BottomCautionText>
          <Page.BottomButtons>
            <C.BackButton
              onClick={onCancelInput}
              comlinkPushParams={{
                action: 'click_back_to_ip_filters_page_button',
              }}
            >
              一覧に戻る
            </C.BackButton>
            <C.SubmitButton
              type="submit"
              onClick={onConfirm}
              comlinkPushParams={{
                action: 'click_confirm_new_ip_filter_button',
              }}
            >
              内容を確認
            </C.SubmitButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
      <DevTool control={control} placement="bottom-right" />
    </PageLayout>
  )
}

const InputText = styled(_InputText).attrs({ size: 'll' })``

const IpAddressInputText = styled(_InputText).attrs({ size: 'm' })``

const SubnetMaskDropdown = styled(_Dropdown).attrs({ size: 'ss' })`
  margin-left: 20px;
`

export const CidrRow = styled.div`
  padding-top: 18px;
`
