import { useState } from 'react'
import { useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import {
  Modal,
  theme,
  toast,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { captureException } from '@sentry/react'
import styled from 'styled-components'
import { useCreateAtsConnectToken } from './useCreateAtsConnectToken'

type Props = {
  active: boolean
  onClose: () => void
}

export const GenerateAPITokenModal: React.VFC<Props> = (props) => {
  const [token, setToken] = useState('')
  const { mutateAsync } = useCreateAtsConnectToken()

  const generateToken = useCallback(async () => {
    try {
      const res = await mutateAsync()
      setToken(res.getToken())
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }
  }, [mutateAsync])

  const copyTokenToClipboard = useCallback(() => {
    copyToClipboard(token).then(() => {
      toast('連携用トークンをコピーしました')
    })
  }, [token])

  const onClose = useCallback(() => {
    setToken('')
    props.onClose()
  }, [props])

  return (
    <Modal
      active={props.active}
      title="連携用トークン発行"
      onClose={onClose}
      size="m"
    >
      <Description>
        トークンは発行後24時間以内にご利用の連携先サービスに設定してください。
      </Description>
      <ModalBody>
        {token !== '' ? (
          <TokenArea>
            <Txt bold>連携用トークン</Txt>
            <TokenTxt>{token}</TokenTxt>
            <TertiaryButton
              onClick={copyTokenToClipboard}
              widthSize="M"
              comlinkPushParams={{ action: 'copy_token' }}
            >
              コピー
            </TertiaryButton>
          </TokenArea>
        ) : (
          <GenerateTokenButton
            onClick={generateToken}
            comlinkPushParams={{
              action: 'generate_api_token',
            }}
          >
            連携用トークン発行
          </GenerateTokenButton>
        )}
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <TertiaryButton
          widthSize="L1"
          onClick={onClose}
          comlinkPushParams={{
            action: 'close_generate_api_token_modal',
          }}
        >
          閉じる
        </TertiaryButton>
      </ModalFooter>
    </Modal>
  )
}

const copyToClipboard = async (txt: string): Promise<void> => {
  await navigator.clipboard.writeText(txt)
}

const Description = styled(Txt)`
  margin-top: 20px;
  margin-left: 20px;
`

const ModalBody = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const GenerateTokenButton = styled(PrimaryButton).attrs({ widthSize: 'L2' })`
  margin-bottom: 50px;
`

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

const TokenArea = styled.div`
  width: 460px;
  background-color: ${theme.color.gray[5]};
  display: flex;
  flex-direction: column;
  padding: 13px;
`

const TokenTxt = styled(Txt)`
  width: 100%;
  margin: 5px 0;
  overflow-x: scroll;
  white-space: nowrap;
`
