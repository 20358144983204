import React from 'react'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Modal } from '@/components/Modal'

type Props = PropsWithClassName<{
  active: boolean
  onClose: () => void
  onSubmit: () => void
}>

export const SendRegisterStaffMailModal: React.VFC<Props> = ({
  active,
  className,
  onClose,
  onSubmit,
}: Props) => {
  return (
    <Modal.Wrapper
      active={active}
      title="利用開始メールの再送信"
      stopScroll
      className={className}
      onClose={onClose}
    >
      <Body>
        <Modal.Txt>利用開始メールを再送信します。よろしいですか？</Modal.Txt>
      </Body>
      <Modal.Footer>
        <Modal.CancelButton
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_send_register_staff_mail_button',
          }}
        >
          キャンセル
        </Modal.CancelButton>
        <Modal.SubmitButton
          onClick={onSubmit}
          comlinkPushParams={{
            action: 'click_send_register_staff_mail_button',
          }}
        >
          送信
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal.Wrapper>
  )
}

const Body = styled(Modal.Body)`
  height: 74px;
`
