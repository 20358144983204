import * as yup from 'yup'
import type { AuthnType } from '@/services/authnService'
import { password, microsoft, google } from '@/services/authnService'

// formSchema.tsに置くとwebpackがim-shared-frontを読み込む際にエラーが起きてstorybookが壊れる。
// 回避策として、formSchema.tsとはファイルを分けて直接importする
// https://stadium-co-jp.slack.com/archives/CKX08ELM7/p1638238211189800
export const authnTypeSchema = yup
  .mixed<AuthnType['type']>()
  .default(password.type)
  .required()
  .oneOf([password.type, microsoft.type, google.type], '選択してください')
