import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { useAuthz } from '@/services/authzService'
import { Content } from './Content'
import { SettingStaffRegisterByFormPageContainer } from './SettingStaffRegisterByFormPageContainer'

export const SettingStaffRegisterByFormPage = provide(
  SettingStaffRegisterByFormPageContainer
)((_: PropsWithClassName): React.ReactElement => {
  const { authorize } = useAuthz()
  authorize()

  usePromptBeforeUnload()

  return <Content />
})
