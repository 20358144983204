import { useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { alertToast, toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useMutation, useQueryClient } from 'react-query'
import { useModal } from '@/hooks/useModal'
import {
  CustomBizAnakinBffGrpcError,
  useRequestSuspendStaff,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export const useSuspendStaff = (guid: string) => {
  const suspendStaffModal = useModal(false)

  const { mutateAsync: suspendStaff, isLoading } = useSuspendStaffMutation(guid)

  const onSubmitSuspendStaff = useCallback(async () => {
    if (isLoading) return

    try {
      await suspendStaff()

      // TODO: テーマカラーをFigmaに揃える
      // TODO: '✗'ボタンを消す
      toast('ユーザー情報を保存しました')
    } catch (e) {
      if (e instanceof CustomBizAnakinBffGrpcError && e.isFailedPrecondition) {
        alertToast(
          '処理に失敗しました。\nブラウザを再読込して、もう一度お試しください。'
        )
      } else {
        Sentry.captureException(e)
        commonErrorToast()
        return
      }
    }
    suspendStaffModal.close()
  }, [suspendStaffModal, isLoading, suspendStaff])

  return {
    suspendStaffModal,
    onSubmitSuspendStaff,
  }
}

function useSuspendStaffMutation(guid: string) {
  const queryClient = useQueryClient()
  const { requestSuspendStaff } = useRequestSuspendStaff()

  return useMutation(() => requestSuspendStaff(guid), {
    onSuccess: () => {
      queryClient.refetchQueries([QUERY_KEY.getStaff, guid])
      queryClient.invalidateQueries(QUERY_KEY.listStaffs)
    },
  })
}
