import { useCallback } from 'react'
import { VerifyRegisterTokenRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'

export function useRequestVerifyRegisterToken() {
  const requestVerifyRegisterToken = useCallback(async (token: string) => {
    const req = new VerifyRegisterTokenRequest()
    req.setToken(token)

    return callBizAnakinBffService('verifyRegisterToken', [req], undefined)
  }, [])

  return { requestVerifyRegisterToken }
}
