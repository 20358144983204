import React from 'react'
import { Txt, theme } from '@blue-agency/rogue'
import styled from 'styled-components'

const InactiveStaffBadge: React.VFC = () => <Inactive>利用開始待ち</Inactive>

const SuspendedStaffBadge: React.VFC = () => <Suspended>利用停止中</Suspended>

const ResumedStaffBadge: React.VFC = () => <Resumed>利用再開待ち</Resumed>

const RegistrationExpiredStaffBadge: React.VFC = () => (
  <RegistrationExpired>有効期限切れ</RegistrationExpired>
)

const Badge = styled(Txt).attrs({
  size: 's',
})`
  height: 20px;
  min-width: 90px;
  padding: auto;
  margin-left: 20px;
  text-align: center;
  line-height: 18px;
  background-color: ${theme.color.white[1]};
`

const Inactive = styled(Badge).attrs({ color: theme.color.yellow[1] })`
  border: 1px solid ${theme.color.yellow[1]};
`

const Suspended = styled(Badge).attrs({ color: theme.color.red[1] })`
  border: 1px solid ${theme.color.red[1]};
`

const Resumed = styled(Badge).attrs({ color: theme.color.magenta[1] })`
  border: 1px solid ${theme.color.magenta[1]};
`

const RegistrationExpired = styled(Badge).attrs({ color: theme.color.red[2] })`
  border: 1px solid ${theme.color.red[2]};
`

export {
  InactiveStaffBadge,
  SuspendedStaffBadge,
  ResumedStaffBadge,
  RegistrationExpiredStaffBadge,
}
