import React from 'react'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Modal } from '@/components/Modal'
import { Name } from '@/services/staffService/name'

type Props = PropsWithClassName<{
  name: Name
  active: boolean
  onClose: () => void
  onSubmit: () => void
}>

export const ResumeStaffModal: React.VFC<Props> = ({
  active,
  name,
  className,
  onClose,
  onSubmit,
}: Props) => {
  return (
    <Modal.Wrapper
      active={active}
      title="利用再開"
      stopScroll
      className={className}
      onClose={onClose}
    >
      <Modal.Body>
        <Modal.Txt>「{name.fullName}」の利用停止を解除します。</Modal.Txt>
        <Modal.Txt>
          登録されたメールアドレスに、再度利用開始メールが送信されますので
          <br />
          ログイン手続きを完了してください。
        </Modal.Txt>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_resume_staff_button',
          }}
        >
          キャンセル
        </Modal.CancelButton>
        <Modal.SubmitButton
          onClick={onSubmit}
          comlinkPushParams={{
            action: 'click_resume_staff_button',
          }}
        >
          利用再開
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal.Wrapper>
  )
}
