import * as yup from 'yup'
import { authzTypeSchema, basicStringSchema } from '@/services/formService'
import { Form } from './SettingStaffEditPageContainer'

export const validationScheme: yup.SchemaOf<Form> = yup.object({
  givenName: basicStringSchema,
  familyName: basicStringSchema,
  givenNameKana: basicStringSchema,
  familyNameKana: basicStringSchema,
  authzType: authzTypeSchema,
})
