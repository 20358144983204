import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { Loading } from '@/components/Loading'
import { SettingsSection } from '@/components/SettingsSection'
import { SettingStaffEditPageContainer } from '../SettingStaffEditPageContainer'
import { Body as _Body } from './Body'
import { Buttons } from './Buttons'

export const Input: React.VFC = () => {
  const { isLoading } = SettingStaffEditPageContainer.useContainer()

  if (isLoading) return <Loading />

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>ユーザー詳細編集</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="ユーザー情報">
            <Body />
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <Buttons />
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const Body = styled(_Body)`
  padding: 20px 0;
`
