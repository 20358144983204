import { useQuery } from 'react-query'
import { useRequestGetAtsConnectV1V2 } from '@/services/bffService/useRequestGetAtsConnectV1V2'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useGetAtsConnectV1V2(guid: string) {
  const { requestGetAtsConnectV1V2 } = useRequestGetAtsConnectV1V2()
  return useQuery([QUERY_KEY.atsConnectV1V2, guid], () =>
    requestGetAtsConnectV1V2(guid)
  )
}
