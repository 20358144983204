import React, { useRef, useEffect } from 'react'
import { theme, Txt, Sort as _Sort } from '@blue-agency/rogue'
import { Pagination as _Pagination } from '@blue-agency/rogue/im'
import { format } from 'date-fns'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { LineClampedTxt } from '@/components/LineClampedTxt'
import {
  InactiveStaffBadge,
  RegistrationExpiredStaffBadge,
  ResumedStaffBadge,
} from '@/components/StaffBadge'
import {
  Table as _Table,
  TableHead,
  TableHeadRow,
  TableRow as _TableRow,
  TableBody,
} from '@/components/Table'
import { authnTypeToText } from '@/components/authnType'
import { StaffList, useStaffListQueryParam } from '@/services/staffService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useStaffsTable } from './useStaffsTable'

type StaffsTableProps = PropsWithClassName<{
  staffList: StaffList
  sizePerPage: number
}>

export const StaffsTable: React.VFC<StaffsTableProps> = (props) => {
  const ctx = useStaffsTable(props.staffList, props.sizePerPage)

  const emptyListMessage = ctx.staffList.staffs.length === 0 && (
    <EmptyListMessage>
      <Txt size="l">該当する情報はありません</Txt>
    </EmptyListMessage>
  )

  const wrapperRef = useRef<HTMLDivElement>(null)

  const history = useHistory()

  const { query, scrollTopParam, setQuery, saveQueryToLocalStorage } =
    useStaffListQueryParam()

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = 0
    }
  }, [ctx.page])

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = scrollTopParam
    }
  }, [scrollTopParam])

  return (
    <Wrapper className={props.className}>
      <Pagination
        page={ctx.page}
        count={ctx.pageCount}
        onRequestChange={ctx.onPageChange}
      />
      <TableWrapper ref={wrapperRef}>
        <Table>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell property="name">
                <TableHeadCellInner>
                  <SmallText>氏名</SmallText>
                  <Sort
                    order={
                      ctx.sort.key === 'nameKana' ? ctx.sort.order : 'none'
                    }
                    onClick={() => {
                      ctx.onSort('nameKana')
                    }}
                  />
                </TableHeadCellInner>
              </TableHeadCell>
              <TableHeadCell property="authzType">
                <SmallText>権限タイプ</SmallText>
              </TableHeadCell>
              <TableHeadCell property="email">
                <SmallText>メールアドレス</SmallText>
              </TableHeadCell>
              <TableHeadCell property="authnType">
                <SmallText>認証方法</SmallText>
              </TableHeadCell>
              <TableHeadCell property="registeredAt">
                <TableHeadCellInner>
                  <SmallText>登録日</SmallText>
                  <Sort
                    order={
                      ctx.sort.key === 'registeredAt' ? ctx.sort.order : 'none'
                    }
                    onClick={() => {
                      ctx.onSort('registeredAt')
                    }}
                  />
                </TableHeadCellInner>
              </TableHeadCell>
              <TableHeadCell property="badge"></TableHeadCell>
              <TableHeadCell property="padding"></TableHeadCell>
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {ctx.staffList.getPage(ctx.page, props.sizePerPage).map((staff) => {
              const detailUrl = generatePath(
                INTERNAL_PATHS.settings.staffs.detail,
                {
                  guid: staff.guid,
                }
              )
              const onClick = (
                e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => {
                const st = wrapperRef.current?.scrollTop ?? 0
                setQuery({ st: st }, 'pushIn')

                // NOTE: setQueryしてからquery.st経由でstを取得すると↑のref経由で取得している値以前のstが入ってしまうので直接渡す
                const q = `?at=${query.at}&cp=${query.cp}&fc=${query.fc}&ss=${query.ss}&st=${st}`
                saveQueryToLocalStorage(staff.guid, q)

                // ctrlキーとクリックで別タブ遷移、デフォルトは同タブ遷移
                if ((e.ctrlKey && !e.metaKey) || (!e.ctrlKey && e.metaKey)) {
                  window.open(detailUrl)
                  return
                }
                history.push({
                  pathname: detailUrl,
                })
              }
              const renderBadge = () => {
                if (staff.isRegistrationExpired) {
                  return <RegistrationExpiredStaffBadge />
                }
                if (staff.status.isRegistered()) {
                  return <InactiveStaffBadge />
                }
                if (staff.status.isResumed()) {
                  return <ResumedStaffBadge />
                }
                return null
              }
              return (
                <TableBodyRow key={staff.guid} suspended={staff.suspended}>
                  <TableBodyCell property="name">
                    <TableCellLink onClick={onClick}>
                      <div>
                        <NameTxt>{staff.name.fullName}</NameTxt>
                        <NameKanaTxt>{staff.nameKana.fullName}</NameKanaTxt>
                      </div>
                    </TableCellLink>
                  </TableBodyCell>
                  <TableBodyCell property="authzType">
                    <TableCellLink onClick={onClick}>
                      {staff.authzType.name}
                    </TableCellLink>
                  </TableBodyCell>
                  <TableBodyCell property="email">
                    <TableCellLink onClick={onClick}>
                      <EmailTxt>{staff.email}</EmailTxt>
                    </TableCellLink>
                  </TableBodyCell>
                  <TableBodyCell property="authnType">
                    <TableCellLink onClick={onClick}>
                      {authnTypeToText(staff.authnType.type)}
                    </TableCellLink>
                  </TableBodyCell>
                  <TableBodyCell property="registeredAt">
                    <TableCellLink onClick={onClick}>
                      {formatRegisteredAt(staff.registeredAt)}
                    </TableCellLink>
                  </TableBodyCell>
                  <TableBodyCell property="badge">
                    <TableCellLink onClick={onClick}>
                      {renderBadge()}
                    </TableCellLink>
                  </TableBodyCell>
                  <TableBodyCell property="padding">
                    <TableCellLink onClick={onClick} />
                  </TableBodyCell>
                </TableBodyRow>
              )
            })}
          </TableBody>
        </Table>
        {emptyListMessage}
      </TableWrapper>
    </Wrapper>
  )
}

function formatRegisteredAt(d: Date): string {
  return format(d, 'yyyy/MM/dd')
}

type Property =
  | 'name'
  | 'authzType'
  | 'email'
  | 'registeredAt'
  | 'badge'
  | 'padding'
  | 'authnType'

const cellMargin = 28

const width: Record<Property, number> = {
  name: 115,
  authzType: 140,
  email: 250,
  registeredAt: 75,
  badge: 120,
  padding: 30,
  authnType: 150,
}

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  name: css`
    display: flex;
    justify-content: center;
    flex: 0 0 ${width.name + cellMargin * 2}px;
    padding-left: ${cellMargin}px;
  `,
  authzType: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.authzType + cellMargin}px;
    justify-content: flex-start;
  `,
  email: css`
    flex: 0 0 ${width.email + cellMargin}px;
    justify-content: flex-start;
  `,
  registeredAt: css`
    flex: 0 0 ${width.registeredAt}px;
    justify-content: flex-start;
  `,
  badge: css`
    flex: 0 0 ${width.badge}px;
    justify-content: center;
  `,
  padding: css`
    flex: 1 0 auto;
  `,
  authnType: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.authnType + cellMargin}px;
    justify-content: flex-start;
  `,
}

const NameTxt = styled(LineClampedTxt).attrs({ size: 'm', line: 1 })`
  width: ${width.name}px;
`

const NameKanaTxt = styled(LineClampedTxt).attrs({
  size: 'xs',
  line: 1,
  color: theme.color.gray[1],
})`
  width: ${width.name}px;
`

const EmailTxt = styled(LineClampedTxt).attrs({ size: 's', line: 2 })`
  width: ${width.email}px;
`

const SmallText = styled(Txt).attrs({ size: 'm' })``

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
`

const Table = styled(_Table)`
  th:last-of-type {
    justify-content: flex-start;
    padding-left: ${width.padding}px;
  }
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;
`

const EmptyListMessage = styled.div`
  text-align: center;
  padding-top: 56px;
`

type TableBodyRowProps = {
  suspended: boolean
}

const TableBodyRow = styled(_TableRow)<TableBodyRowProps>`
  cursor: pointer;
  ${({ suspended }) =>
    suspended &&
    css`
      background-color: ${theme.color.gray[4]};
    `}
`

type TableCellProps = {
  property: Property
  hover?: boolean
}

const TableHeadCell = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 44px;
  color: ${theme.color.navy[1]};
  background: ${theme.color.navy[3]};
  font-size: ${theme.fontSize.m};
  ${({ property }) => propertyStyles[property]}
  justify-content: left;
`

const TableBodyCell = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 60px;
  color: ${theme.color.navy[1]};
  font-size: ${theme.fontSize.m};
  ${({ property }) => propertyStyles[property]}
  ${({ property }) =>
    property === 'name' &&
    css`
      justify-content: flex-start;
      padding-left: 28px;
    `}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: ${theme.color.gray[4]};
      }
    `}
`

const TableCellLink = styled.a`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  text-decoration: none;
  margin: 0 -15px;
  padding: 0 15px;
  color: inherit;
`

const TableWrapper = styled.div`
  background-color: ${theme.color.white[1]};
  flex: 1;
  overflow-y: scroll;
`

const Pagination = styled(_Pagination)`
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  background-color: ${theme.color.white[1]};
  border-top: 1px solid ${theme.color.gray[4]};
`

const Sort = styled(_Sort).attrs({ weakColor: theme.color.navy[2] })``

const TableHeadCellInner = styled.div`
  display: flex;
  justify-content: center;

  > svg {
    margin-left: 5px;
  }
`
