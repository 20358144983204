import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateAtsConnectV1V2Request } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export type RequestUpdateAtsConnectV1V2Args = {
  guid: string
  name: string
  note: string
  defaultWebInterviewGuideGuid: string
}
export function useRequestUpdateAtsConnectV1V2() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateAtsConnectV1V2 = useCallback(
    async (args: RequestUpdateAtsConnectV1V2Args) => {
      const metadata = await buildMetadata()

      const req = new UpdateAtsConnectV1V2Request()
      req.setGuid(args.guid)
      req.setName(args.name)
      req.setNote(args.note)
      req.setDefaultWebInterviewGuideGuid(args.defaultWebInterviewGuideGuid)

      return callBizAnakinBffService(
        'updateAtsConnectV1V2',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestUpdateAtsConnectV1V2,
  }
}
