import { useCallback } from 'react'
import {
  AuthzType,
  authzTypeToProto,
  useLoggedInStaff,
} from '@blue-agency/im-shared-front'
import { BatchRegisterStaffsRequest } from '@blue-agency/proton/biz_anakin_bff'
import { Name, NameKana } from '@blue-agency/proton/web/v2/name_data_pb'
import { AuthnType, authnTypeOf } from '@/services/authnService'
import { authnTypeToProto } from './authnType'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

type Staff = {
  familyName: string
  givenName: string
  familyNameKana: string
  givenNameKana: string
  mail: string
  authnType: AuthnType['code']
  authzType: AuthzType['type']
}

export function useRequestBatchRegisterStaffs() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestBatchRegisterStaffs = useCallback(
    async (staffs: Staff[]) => {
      const metadata = await buildMetadata()
      const req = new BatchRegisterStaffsRequest()

      const reqStaffsList = staffs.map((staff) => {
        const s = new BatchRegisterStaffsRequest.Staff()

        const name = new Name()
        name.setFamilyName(staff.familyName)
        name.setGivenName(staff.givenName)

        s.setName(name)

        const nameKana = new NameKana()
        nameKana.setFamilyNameKana(staff.familyNameKana)
        nameKana.setGivenNameKana(staff.givenNameKana)

        s.setNameKana(nameKana)

        s.setEmail(staff.mail)
        s.setAuthnType(authnTypeToProto(authnTypeOf(staff.authnType).type))
        s.setAuthzType(authzTypeToProto(staff.authzType))

        return s
      })

      req.setStaffsList(reqStaffsList)

      return callBizAnakinBffService(
        'batchRegisterStaffs',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )
  return { requestBatchRegisterStaffs }
}
