import React from 'react'
import { FixedBottomFormPage as Page } from '@blue-agency/im-shared-front'
import { ErrorBox as _ErrorBox, theme } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { ErrorTxt } from '@/components/ErrorTxt'
import { Form } from '@/components/Form'
import { InputPassword } from '@/components/InputPassword'
import { SettingsSection } from '@/components/SettingsSection'
import { useEditPassword } from './useEditPassword'

export const Content: React.VFC = () => {
  const { register, onSubmit, onCancel, errors, submitError } =
    useEditPassword()

  const hasSubmitErrors = {
    currentPassword: submitError === 'currentPasswordMismatch',
    newPassword: submitError === 'tooWeakPassword',
    newPasswordConfirm: submitError === 'tooWeakPassword',
  }

  return (
    <>
      <Page.Body>
        {submitError === 'currentPasswordMismatch' && (
          <ErrorBox>
            <ErrorTxt>現在のパスワードが正しくありません。</ErrorTxt>
          </ErrorBox>
        )}
        {submitError === 'tooWeakPassword' && (
          <ErrorBox>
            <ErrorTxt>
              パスワードが安全ではないため、登録できませんでした。新しいパスワードを設定してください。
            </ErrorTxt>
          </ErrorBox>
        )}
        <SettingsSection title="パスワード情報">
          <Form.Section>
            <Form.Row>
              <Form.Title>現在のパスワード</Form.Title>
              <FormItem>
                <Form.Required>必須</Form.Required>
                <Form.Field>
                  <InputPassword
                    {...register('currentPassword')}
                    size="ll"
                    placeholder="現在のパスワード"
                    onBlurError={errors['currentPassword']?.message}
                    hasSubmitError={hasSubmitErrors.currentPassword}
                  />
                </Form.Field>
              </FormItem>
            </Form.Row>
          </Form.Section>
          <Form.Section>
            <Form.Row>
              <NewPasswordFormTitle>新パスワード</NewPasswordFormTitle>
              <FormItem>
                <Form.Required>必須</Form.Required>
                <PasswordField>
                  <InputPassword
                    {...register('newPassword')}
                    size="ll"
                    placeholder="半角英数字記号で8文字以上"
                    onBlurError={errors['newPassword']?.message}
                    hasSubmitError={hasSubmitErrors.newPassword}
                  />
                </PasswordField>
              </FormItem>
            </Form.Row>
          </Form.Section>
          <Form.Section>
            <Form.Row>
              <Form.Title>新パスワード（確認）</Form.Title>
              <FormItem>
                <Form.Required>必須</Form.Required>
                <Form.Field>
                  <InputPassword
                    {...register('newPasswordConfirm')}
                    size="ll"
                    placeholder="半角英数字記号で8文字以上"
                    onBlurError={errors['newPasswordConfirm']?.message}
                    hasSubmitError={hasSubmitErrors.newPasswordConfirm}
                  />
                </Form.Field>
              </FormItem>
            </Form.Row>
          </Form.Section>
        </SettingsSection>
      </Page.Body>
      <Page.Bottom>
        <Page.BottomButtons>
          <TertiaryButton
            widthSize="L1"
            comlinkPushParams={{ action: 'cancel_edit_password' }}
            onClick={onCancel}
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            widthSize="L1"
            type="submit"
            comlinkPushParams={{ action: 'save_edit_password' }}
            onClick={onSubmit}
          >
            保存
          </PrimaryButton>
        </Page.BottomButtons>
      </Page.Bottom>
    </>
  )
}

const ErrorBox = styled(_ErrorBox)`
  width: 960px;
  margin: 11px auto 16px;
  background-color: ${theme.color.white[1]};
`

const FormItem = styled(Form.Item)`
  grid-template-columns: 30px 1fr;
  grid-template-areas: 'required field';
`

const PasswordField = styled(Form.Field)`
  display: block;
`

const NewPasswordFormTitle = styled(Form.Title)`
  align-self: flex-start;
  margin-top: 5px;
`
