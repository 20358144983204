export const EXTERNAL_PATHS = {
  terms: 'https://interview-maker.jp/terms/terms-of-service/',
  privacy: 'https://interview-maker.jp/terms/privacy-policy/',
  help: {
    registerOrganizationError:
      'https://im-cp.zendesk.com/hc/ja/articles/900005551283',
    supportDesk: 'https://im-cp.zendesk.com/hc/ja/articles/900004593866',
    authzType: 'https://im-cp.zendesk.com/hc/ja/articles/900005428183',
    authnType: 'https://im-cp.zendesk.com/hc/ja/articles/4410425259929',
    atsConnect: 'https://im-cp.zendesk.com/hc/ja/articles/4589485328921',
  },
}
