import React from 'react'
import { InputText } from '@blue-agency/rogue/im'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Form } from '@/components/Form'
import { SettingStaffRegisterByFormPageContainer } from '../../SettingStaffRegisterByFormPageContainer'

export const EmailSection = ({
  className,
}: PropsWithClassName): React.ReactElement => {
  const { register, errors, submitErrors } =
    SettingStaffRegisterByFormPageContainer.useContainer()

  return (
    <Form.Section className={className}>
      <Form.Row>
        <Form.Title>メールアドレス</Form.Title>
        <Form.Item>
          <Form.Required>必須</Form.Required>
          <Form.Field>
            <InputText
              {...register('email')}
              size="l"
              placeholder="interview@example.co.jp"
              onBlurError={errors['email']?.message}
              hasSubmitError={!!submitErrors['email']}
              autoComplete="off"
            />
          </Form.Field>
        </Form.Item>
      </Form.Row>
    </Form.Section>
  )
}
