export const QUERY_KEY = {
  verifyRegisterOrganizationToken: 'verifyRegisterOrganizationToken',
  verifyRegisterToken: 'verifyRegisterToken',
  listStaffs: 'listStaffs',
  getStaff: 'getStaff',
  getOrganization: 'getOrganization',
  getOrganizationByRegisterToken: 'getOrganizationByRegisterToken',
  getGlobalNavigation: 'getGlobalNavigation',
  getConnectedIpAddress: 'getConnectedIpAddress',
  getIpFilter: 'getIpFilter',
  listIpFilters: 'listIpFilters',
  atsConnectV3: 'atsConnectV3',
  atsConnectV1V2: 'atsConnectV1V2',
  apiSettings: 'apiSettings',
  webInterviewGuides: 'webInterviewGuides',
  listSelectionStepMastersForOption: 'listSelectionStepMastersForOption',
} as const
