import { authnTypeToText } from '@/components/authnType'
import { password, microsoft, google } from '@/services/authnService'

export { validatePrecondition } from './precondition'
export { validateDetail } from './validateDetail'
export type { DetailValidationError } from './validateDetail'

export type CsvHeader = string[]
export type CsvBody = string[][]

export const validCsvHeader = [
  '姓',
  '名',
  '姓(かな)',
  '名(かな)',
  'メールアドレス',
  `認証方法（${authnTypeToText(password.type)}は${
    password.code
  }、${authnTypeToText(microsoft.type)}は${microsoft.code}、${authnTypeToText(
    google.type
  )}は${google.code}を入力してください）`,
  '権限タイプ（管理者権限は1、採用アシスタント権限は2、一般権限は3を入力してください）',
]
