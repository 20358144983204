import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { assertIsDefined } from '@/assertions'
import { useRequestGetOrganization } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useOrganization() {
  const { isLoading, data } = useGetOrganizationQuery()

  const organization = useMemo(() => {
    const org = data?.getOrganization()
    if (org === undefined) return

    const name = org.getName()
    return {
      name,
    }
  }, [data])

  const getOrganization = () => {
    assertIsDefined(organization)
    return organization
  }

  return {
    isLoading,
    getOrganization,
  }
}

function useGetOrganizationQuery() {
  const { requestGetOrganization } = useRequestGetOrganization()
  return useQuery(QUERY_KEY.getOrganization, requestGetOrganization)
}
