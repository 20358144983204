import React from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { Txt as _Txt, theme } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { generatePath } from 'react-router'
import styled, { css } from 'styled-components'
import { Link } from '@/components/Link'
import { ListContentLayout } from '@/components/ListContentLayout'
import { Loading } from '@/components/Loading'
import { ListPageHeader } from '@/components/PageHeader'
import {
  Table,
  TableHead,
  TableHeadRow as _TableHeadRow,
  TableRow,
  TableBody,
} from '@/components/Table'
import { IpFilter } from '@/services/ipFilterService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useIpFilters } from './useIpFilters'

export const SettingIpFilterListPage: React.VFC = () => {
  const { isLoading, ipFilters } = useIpFilters()

  if (isLoading) return <Loading />

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <ListPageHeader
        title="IPアドレス制限一覧"
        right={
          <Link to={INTERNAL_PATHS.settings.ipfilters.new}>
            <PrimaryButton
              widthSize="L1"
              comlinkPushParams={{ action: 'click_create_ipfilter' }}
            >
              フィルター登録
            </PrimaryButton>
          </Link>
        }
      />
      <ListContentLayout sidebar={<></>}>
        <Wrapper>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableHeadRow>
                  <TableHeadCell property="name">
                    <TableHeadInner>フィルター名</TableHeadInner>
                  </TableHeadCell>
                  <TableHeadCell property="scope">
                    <TableHeadInner>対象範囲</TableHeadInner>
                  </TableHeadCell>
                </TableHeadRow>
              </TableHead>
              <IpFiltersTableBody ipFilters={ipFilters} />
            </Table>
          </TableWrapper>
        </Wrapper>
      </ListContentLayout>
    </PageLayoutWithGlonavi>
  )
}

const IpFiltersTableBody: React.VFC<{ ipFilters: IpFilter[] }> = ({
  ipFilters,
}) => {
  if (ipFilters.length === 0) {
    return (
      <TableBody>
        <EmptyListMessage>
          <LargeTxt>登録された情報はありません</LargeTxt>
        </EmptyListMessage>
      </TableBody>
    )
  }

  return (
    <TableBody>
      {ipFilters.map((ipFilter) => {
        const detailLink = generatePath(
          INTERNAL_PATHS.settings.ipfilters.detail,
          {
            guid: ipFilter.guid,
          }
        )
        return (
          <TableRow key={ipFilter.guid}>
            <TableBodyCell property="name">
              <TableCellLink to={detailLink}>{ipFilter.name}</TableCellLink>
            </TableBodyCell>
            <TableBodyCell property="scope">
              <TableCellLink to={detailLink}>
                {ipFilter.scopeSummary}
              </TableCellLink>
            </TableBodyCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const TableWrapper = styled.div`
  background-color: ${theme.color.white[1]};
  flex: 1;
  overflow-y: scroll;
`
const TableHeadRow = styled(_TableHeadRow)`
  background-color: ${theme.color.navy[3]};
`

type TableCellProps = {
  property: 'name' | 'scope'
}
const propertyStyles = {
  name: css`
    width: 240px;
    padding-left: 30px;
  `,
  scope: css`
    width: 240px;
  `,
}

const TableHeadCell = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 44px;
  font-size: ${theme.fontSize.m};
  ${({ property }) => propertyStyles[property]}
`
const TableHeadInner = styled(_Txt).attrs({ size: 'm' })``
const TableBodyCell = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 60px;
  font-size: ${theme.fontSize.m};
  ${({ property }) => propertyStyles[property]}
`
const TableCellLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
`
const EmptyListMessage = styled.div`
  text-align: center;
  padding-top: 56px;
`
const LargeTxt = styled(_Txt).attrs({ size: 'l' })``
