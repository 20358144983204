import React from 'react'
import { AuthnTypeSection } from './AuthnTypeSection'
import { AuthzTypeSection } from './AuthzTypeSection'
import { EmailSection } from './EmailSection'
import { NameSection } from './NameSection'

export const Body = (): React.ReactElement => {
  return (
    <>
      <NameSection />
      <EmailSection />
      <AuthnTypeSection />
      <AuthzTypeSection />
    </>
  )
}
