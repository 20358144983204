import React from 'react'
import { FixedBottomFormPage as Page } from '@blue-agency/im-shared-front'
import {
  Txt,
  InputText,
  CheckBox,
  NewTabLink,
  ErrorBox,
} from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { ErrorTxt } from '@/components/ErrorTxt'
import { Form } from '@/components/Form'
import { InputPassword } from '@/components/InputPassword'
import { SettingsSection } from '@/components/SettingsSection'
import { EXTERNAL_PATHS } from '@/services/urlService'
import { RegisterOrganizationForm } from '../formScheme'
import { useInputStep } from './useInputStep'

type Props = {
  form?: RegisterOrganizationForm
  onComplete: (form: RegisterOrganizationForm) => void
  hasOccurredTooWeakPasswordError: boolean
}

export const InputStep = ({
  form,
  onComplete,
  hasOccurredTooWeakPasswordError,
}: Props): React.ReactElement => {
  const { register, errors, handleFormSubmit, agreement, toggleAgreement } =
    useInputStep(form, onComplete)

  return (
    <Page.Wrapper onSubmit={handleFormSubmit}>
      <Page.Header>
        <Page.HeaderTitle>
          インタビューメーカーご利用開始フォーム
        </Page.HeaderTitle>
      </Page.Header>
      <Page.Body>
        <Description>
          インタビューメーカーにお申込いただき、ありがとうございます。
          <br />
          あなたの企業情報、担当者情報を入力してください。
          <br />
          このページの入力が完了すると、すぐにご利用を開始することができます。
        </Description>
        {hasOccurredTooWeakPasswordError && (
          <TooWeakPasswordErrorBox>
            <ErrorTxt>
              パスワードが安全ではないため、登録できませんでした。新しいパスワードを設定してください。
            </ErrorTxt>
          </TooWeakPasswordErrorBox>
        )}
        <SettingsSection title="申込内容">
          <Form.Section>
            <Form.Row>
              <Form.Title>企業名</Form.Title>
              <Form.Item>
                <Form.Required>必須</Form.Required>
                <Form.Field>
                  <InputText
                    {...register('organizationName')}
                    placeholder="例）株式会社スタジアム"
                    autoComplete="organization"
                    size="l"
                    onBlurError={errors['organizationName']?.message}
                  />
                </Form.Field>
              </Form.Item>
            </Form.Row>
          </Form.Section>
          <Form.Section>
            <Form.Row>
              <Form.Title>氏名</Form.Title>
              <Form.Item>
                <Form.Label>氏名</Form.Label>
                <Form.Required>必須</Form.Required>
                <Form.Field>
                  <NameInput
                    {...register('familyName')}
                    placeholder="山田"
                    autoComplete="first-name"
                    onBlurError={errors['familyName']?.message}
                  />
                  <NameInput
                    {...register('givenName')}
                    placeholder="太郎"
                    autoComplete="last-name"
                    onBlurError={errors['givenName']?.message}
                  />
                </Form.Field>
              </Form.Item>
            </Form.Row>
            <Form.Row>
              <Form.Item>
                <Form.Label>かな</Form.Label>
                <Form.Required>必須</Form.Required>
                <Form.Field>
                  <NameInput
                    {...register('familyNameKana')}
                    placeholder="やまだ"
                    onBlurError={errors['familyNameKana']?.message}
                  />
                  <NameInput
                    {...register('givenNameKana')}
                    placeholder="たろう"
                    onBlurError={errors['givenNameKana']?.message}
                  />
                </Form.Field>
              </Form.Item>
            </Form.Row>
          </Form.Section>
          <Form.Section>
            <Form.Row>
              <Form.Title>メールアドレス</Form.Title>
              <Form.Item>
                <Form.Field>
                  <Txt>ご登録いただいたメールアドレス</Txt>
                </Form.Field>
              </Form.Item>
            </Form.Row>
          </Form.Section>
          <Form.Section>
            <Form.Row>
              <Form.Title>パスワード</Form.Title>
              <Form.Item>
                <Form.Label>パスワード</Form.Label>
                <Form.Required>必須</Form.Required>
                <PasswordField>
                  <InputPassword
                    {...register('password')}
                    size="ll"
                    autoComplete="new-password"
                    placeholder="半角英数字記号で8文字以上"
                    onBlurError={errors['password']?.message}
                    hasSubmitError={hasOccurredTooWeakPasswordError}
                  />
                </PasswordField>
              </Form.Item>
            </Form.Row>
            <Form.Row>
              <Form.Item>
                <Form.Label>確認</Form.Label>
                <Form.Required>必須</Form.Required>
                <PasswordField>
                  <InputPassword
                    {...register('passwordConfirmation')}
                    size="ll"
                    placeholder="半角英数字記号で8文字以上"
                    onBlurError={errors['passwordConfirmation']?.message}
                    hasSubmitError={hasOccurredTooWeakPasswordError}
                  />
                </PasswordField>
              </Form.Item>
            </Form.Row>
          </Form.Section>
          <Form.Section>
            <Form.Row>
              <Form.Title>同意事項</Form.Title>
              <Form.Item>
                <Form.Required>必須</Form.Required>
                <Form.Field>
                  <CheckBox checked={agreement} onChange={toggleAgreement} />
                  <ConsentTxt>
                    <NewTabLink
                      href={EXTERNAL_PATHS.terms}
                      action="open_terms_page"
                    >
                      インタビューメーカー利用規約
                    </NewTabLink>
                    、株式会社スタジアムの
                    <NewTabLink
                      href={EXTERNAL_PATHS.privacy}
                      action="open_privacy_policy_page"
                    >
                      プライバシーポリシー
                    </NewTabLink>
                    に同意する
                  </ConsentTxt>
                </Form.Field>
              </Form.Item>
            </Form.Row>
          </Form.Section>
        </SettingsSection>
      </Page.Body>
      <Page.Bottom>
        <Page.BottomButtons>
          <PrimaryButton
            widthSize="L1"
            type="submit"
            comlinkPushParams={{
              action: 'click_confirm_register_organization_body_button',
            }}
          >
            登録内容を確認
          </PrimaryButton>
        </Page.BottomButtons>
      </Page.Bottom>
    </Page.Wrapper>
  )
}

const Description = styled(Txt).attrs({ size: 'l' })`
  padding: 0 6px;
  margin-bottom: 12px;
`

const TooWeakPasswordErrorBox = styled(ErrorBox)`
  margin-bottom: 16px;
`

const NameInput = styled(InputText)`
  &:not(:last-child) {
    margin-right: 20px;
  }
`

const ConsentTxt = styled(Txt).attrs({ size: 'm' })`
  margin-left: 8px;
`

const PasswordField = styled(Form.Field)`
  display: block;
`
