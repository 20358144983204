import React from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Form } from '@/components/Form'
import { TextLink } from '@/components/Link'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  overflow: auto;
`
export const Body = styled.div`
  flex: 1 1 0%;
`
export const SectionWrapper = styled.div`
  padding: 20px 0;
  :not(:last-child) {
    border-bottom: solid 1px ${theme.color.gray[4]};
  }
`

export const FormRequired = styled(Form.Required)`
  margin-top: 9px;
`
export const FormTitle = styled(Form.Title)`
  display: flex;
  height: 100%;
  align-items: center;
`
export const FormField = styled(Form.Field)`
  height: 100%;
  align-items: center;
  position: relative;
`
export const QuestionIcon = styled(Icon).attrs({ name: 'question', size: 'l' })`
  margin-left: 5px;
  cursor: pointer;
`
export const InputNote = styled(Txt).attrs({
  size: 's',
  color: theme.color.gray[1],
})``
export const BackButton = styled(TertiaryButton).attrs({ widthSize: 'L2' })``
export const SubmitButton = styled(PrimaryButton).attrs({ widthSize: 'L2' })``

export const SpecifiedStaffFormRow = styled(Form.Row)`
  :not(:first-of-type) {
    margin: 0px;
    padding: 0px;
  }
`
export const SpecifiedStaffList = styled.ul`
  padding-left: 20px;
  margin: 0;
  font-size: ${theme.fontSize.m};
`

export const CidrList = styled.ul`
  padding-left: 20px;
  margin: 0;
  font-size: ${theme.fontSize.m};
`
export const CidrAddress = styled(Txt).attrs({ size: 'm' })`
  display: inline;
`
export const CidrSubnet = styled(Txt).attrs({ size: 'm' })`
  display: inline;
  margin-left: 15px;
`

export const TxtButtonWrapper = styled.div`
  :not(:first-child) {
    margin-left: 15px;
  }
`
const TxtButtonIcon = styled(Icon)`
  padding-right: 6px;
  vertical-align: middle;
  height: 100%;
`

const DeleteCidrButton = styled(TextLink)`
  height: 100%;
  color: ${theme.color.navy[1]};
  font-size: ${theme.fontSize.m};
`
const AddCidrButton = styled(TextLink)`
  color: ${theme.color.navy[1]};
  font-size: ${theme.fontSize.m};
  justify-items: center;
`

type TxtButtonProps = {
  onClick: () => void
}

export const AddCidrTxtButton: React.VFC<TxtButtonProps> = (props) => (
  <TxtButtonWrapper>
    <AddCidrButton to="#" onClick={props.onClick}>
      <TxtButtonIcon name="add" />
      追加
    </AddCidrButton>
  </TxtButtonWrapper>
)

export const DeleteCidrTxtButton: React.VFC<TxtButtonProps> = (props) => {
  return (
    <TxtButtonWrapper>
      <DeleteCidrButton to="#" onClick={props.onClick}>
        <TxtButtonIcon name="delete" size="m" />
        削除
      </DeleteCidrButton>
    </TxtButtonWrapper>
  )
}

export const cautionText =
  '登録内容にお間違いがないか十分にご確認の上、ご登録ください。\n誤った情報を登録した場合、すべてのユーザーがアクセスできなくなってしまうためご注意ください。'
