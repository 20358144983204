import { useMutation, useQueryClient } from 'react-query'
import { useRequestSuspendAtsConnect } from '@/services/bffService/useRequestSuspendAtsConnect'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useSuspendAtsConnect(guid: string) {
  const queryClient = useQueryClient()
  const { requestSuspendAtsConnect } = useRequestSuspendAtsConnect()

  return useMutation(() => requestSuspendAtsConnect(guid), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.apiSettings)
      queryClient.invalidateQueries([QUERY_KEY.atsConnectV3, guid])
    },
  })
}
