import React from 'react'
import {
  PageLayoutWithGlonavi,
  DetailPage as Page,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { Content } from './Content'

export const StaffProfilePage: React.VFC = () => {
  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>ユーザー個人設定</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <Content />
        </Page.Body>
      </Page.Wrapper>
    </PageLayoutWithGlonavi>
  )
}
