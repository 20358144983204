import React from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { Txt as _Txt } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Form } from '@/components/Form'
import { Loading } from '@/components/Loading'
import { SettingsSection } from '@/components/SettingsSection'
import { authnTypeToText } from '@/components/authnType'
import { INTERNAL_PATHS } from '@/services/urlService'

export const Content: React.VFC<PropsWithClassName> = (props) => {
  const history = useHistory()

  const loggedInStaff = useLoggedInStaff()

  if (loggedInStaff === undefined) {
    return <Loading />
  }

  // prettier-ignore
  const fullName = loggedInStaff.name.familyName + ' ' + loggedInStaff.name.givenName
  // prettier-ignore
  const fullNameKana = loggedInStaff.nameKana.familyNameKana  + ' ' + loggedInStaff.nameKana.givenNameKana

  return (
    <div className={props.className}>
      <SettingsSection title="ユーザー情報">
        <Body>
          <Form.Section>
            <Form.Row>
              <Form.Title>氏名</Form.Title>
              <Form.Item>
                <Form.Label>氏名</Form.Label>
                <Form.Field>
                  <Txt>{fullName}</Txt>
                </Form.Field>
              </Form.Item>
            </Form.Row>
            <Form.Row>
              <Form.Item>
                <Form.Label>かな</Form.Label>
                <Form.Field>
                  <Txt>{fullNameKana}</Txt>
                </Form.Field>
              </Form.Item>
            </Form.Row>
          </Form.Section>
          <Form.Section>
            <Form.Row>
              <Form.Title>メールアドレス</Form.Title>
              <Form.Item>
                <Form.EmailField>
                  <Form.EmailTxt>{loggedInStaff.email}</Form.EmailTxt>
                </Form.EmailField>
              </Form.Item>
            </Form.Row>
          </Form.Section>
          <Form.Section>
            <Form.Row>
              <Form.Title>認証方法</Form.Title>
              <Form.Item>
                <Form.Field>
                  <AuthnTypeContainer>
                    <Txt>{authnTypeToText(loggedInStaff.authnType)}</Txt>
                    {loggedInStaff.authnType === 'password' && (
                      <>
                        <Txt>***********</Txt>
                        <EditPasswordButton
                          onClick={() =>
                            history.push(INTERNAL_PATHS.editPassword)
                          }
                          comlinkPushParams={{
                            action: 'click_edit_password',
                          }}
                        >
                          パスワード変更
                        </EditPasswordButton>
                      </>
                    )}
                  </AuthnTypeContainer>
                </Form.Field>
              </Form.Item>
            </Form.Row>
          </Form.Section>
        </Body>
      </SettingsSection>
    </div>
  )
}

const Body = styled.div`
  padding: 20px 0;
`

const Txt = styled(_Txt).attrs({ size: 'm' })`
  height: 20px;
  margin: 6px 0;
`

const EditPasswordButton = styled(TertiaryButton).attrs({
  widthSize: 'L1',
  heightSize: 'S',
})``

const AuthnTypeContainer = styled.div`
  display: inline-flex;
  column-gap: 20px;
  align-items: center;
`
