import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { Icon, theme } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SettingsSection } from '@/components/SettingsSection'
import { useAuthz } from '@/services/authzService'
import { INTERNAL_PATHS } from '@/services/urlService'

export const SettingStaffRegisterSelectPage: React.VFC = () => {
  const { authorize } = useAuthz()
  authorize()

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>ユーザー登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="登録方法の選択">
            <SelectionWrapper>
              <Selection>
                <RegistrationWhiteIcon />
                <NoStyledLink
                  to={INTERNAL_PATHS.settings.staffs.registerByForm}
                >
                  <PrimaryButton widthSize="L3" comlinkPushParams={{}}>
                    フォームから個別登録
                  </PrimaryButton>
                </NoStyledLink>
              </Selection>
              <Selection>
                <Icon name="registration-by-file" width="64" height="64" />
                <NoStyledLink
                  to={INTERNAL_PATHS.settings.staffs.registerByFile}
                >
                  <PrimaryButton widthSize="L3" comlinkPushParams={{}}>
                    ファイルで一括登録
                  </PrimaryButton>
                </NoStyledLink>
              </Selection>
            </SelectionWrapper>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <NoStyledLink to={INTERNAL_PATHS.settings.staffs.index}>
              <TertiaryButton
                widthSize="L2"
                comlinkPushParams={{
                  action: 'click_cancel_register_web_interview_button',
                }}
              >
                一覧に戻る
              </TertiaryButton>
            </NoStyledLink>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const SelectionWrapper = styled.div`
  padding: 60px 120px 60px;
  display: flex;
  justify-content: space-between;
`

const Selection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 350px;
  height: 200px;

  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${theme.color.gray[4]};

  & > *:not(:first-child) {
    margin-top: 20px;
  }
`

/* FIXME: アイコンがつぶれて表示されるので、暫定的にcssで修正している
 *  ref: https://github.com/blue-agency/biz-anakin-front/pull/673
 */
const RegistrationWhiteIcon = styled(Icon).attrs({
  name: 'registration-white',
  width: '64',
  height: '64',
})`
  path {
    fill-rule: evenodd;
  }
`

const NoStyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
