import React from 'react'
import { getInternalUrls, PageLayout } from '@blue-agency/im-shared-front'
import { Txt, NewTabLink, theme } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { PageHeader } from '@/components/PageHeader'
import { EXTERNAL_PATHS } from '@/services/urlService'

export const Registered: React.VFC = () => {
  return (
    <PageLayout backgroundColor={theme.color.gray[5]} disableLogoLink>
      <PageHeader title="インタビューメ－カーご利用開始フォーム" />
      <Wrapper>
        <Txt>
          既にインタビューメーカーへの登録が完了しています。
          <br />
          ログインしてサービスをご利用ください。
        </Txt>
        <PrimaryButton
          comlinkPushParams={{
            action: 'click_register_organization_body_button',
          }}
          widthSize="L2"
          onClick={() => (window.location.href = getInternalUrls().dashboard)}
        >
          ログイン画面へ
        </PrimaryButton>
        <Txt>
          ご不明な点がございましたら、
          <NewTabLink
            href={EXTERNAL_PATHS.help.supportDesk}
            action="open_help_page_on_register_organization"
          >
            こちら
          </NewTabLink>
          までお問い合わせください。
        </Txt>
      </Wrapper>
    </PageLayout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  width: 960px;
  margin: 0 auto;

  padding: 32px 0;

  background-color: ${theme.color.white[1]};

  > *:not(:last-child) {
    margin-bottom: 18px;
  }
`
