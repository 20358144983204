import React from 'react'
import {
  Txt as _Txt,
  RequireTxt as _RequireTxt,
  WeakColorSeparator,
  InputText,
} from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Modal } from '@/components/Modal'
import { useEditOrganizationName } from './useEditOrganizationName'

type EditOrganizationNameModalProps = {
  active: boolean
  onCloseModal: () => void
  organizationName: string
}

export const EditOrganizationNameModal = ({
  className,
  active,
  onCloseModal,
  organizationName,
}: PropsWithClassName<EditOrganizationNameModalProps>): React.ReactElement => {
  const { register, onSubmit, errors, isLoading } = useEditOrganizationName({
    onCloseModal: onCloseModal,
    defaultName: organizationName,
  })

  return (
    <Modal.Wrapper
      size="l"
      active={active}
      className={className}
      title="企業情報設定の編集"
      onClose={onCloseModal}
    >
      <Body>
        <InputWrapper>
          <Label>企業名</Label>
          <RequireTxt>必須</RequireTxt>
          <InputText
            {...register('name')}
            size="ll"
            onBlurError={errors['name']?.message}
            placeholder="株式会社スタジアム"
            autoComplete="off"
          />
        </InputWrapper>
        <Separator />
      </Body>
      <Modal.Footer>
        <Modal.CancelButton
          onClick={onCloseModal}
          comlinkPushParams={{ action: 'click_cancel_edit_organization_name' }}
        >
          キャンセル
        </Modal.CancelButton>
        <Modal.SubmitButton
          onClick={onSubmit}
          loading={isLoading}
          comlinkPushParams={{ action: 'click_save_edit_organization_name' }}
        >
          保存
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal.Wrapper>
  )
}

const Body = styled(Modal.Body)`
  margin-bottom: 159px;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled(_Txt).attrs({ size: 'm' })`
  width: 140px;
`

const RequireTxt = styled(_RequireTxt).attrs({ size: 's' })`
  width: 28px;
`

const Separator = styled(WeakColorSeparator)`
  margin-top: 20px;
`
