import React from 'react'
import { TertiaryButton, PrimaryButton } from '@blue-agency/rogue/im'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { SettingStaffEditPageContainer } from '../SettingStaffEditPageContainer'

export const Buttons: React.VFC<PropsWithClassName> = ({ className }) => {
  const { toInputStep, updateStaff } =
    SettingStaffEditPageContainer.useContainer()
  return (
    <>
      <TertiaryButton
        widthSize="L1"
        onClick={toInputStep}
        comlinkPushParams={{ action: 'back_user_edit_page' }}
      >
        入力画面に戻る
      </TertiaryButton>
      <PrimaryButton
        widthSize="L2"
        type="submit"
        onClick={updateStaff}
        comlinkPushParams={{ action: 'click_user_edit_submit' }}
      >
        保存
      </PrimaryButton>
    </>
  )
}
