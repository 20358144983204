import { theme, Txt as _Txt, Modal as _Modal } from '@blue-agency/rogue'
import {
  TertiaryButton,
  PrimaryButton,
  DangerButton,
} from '@blue-agency/rogue/im'
import styled from 'styled-components'

const Wrapper = styled(_Modal)`
  > div {
    overflow-y: visible;
  }
`

const Body = styled.div`
  padding: 20px;
  padding-bottom: 13px;
  margin: 0px;
  height: 112px;
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  border-top: 1px solid ${theme.color.gray[4]};

  > *:not(:last-child) {
    margin-right: 20px;
  }
`

const Txt = styled(_Txt).attrs({ size: 'm' })``

const CancelButton = styled(TertiaryButton).attrs({ widthSize: 'L1' })``

const SubmitButton = styled(PrimaryButton).attrs({ widthSize: 'L1' })``

const DangerSubmitButton = styled(DangerButton).attrs({ widthSize: 'L1' })``

export const Modal = {
  Wrapper,
  Body,
  Footer,
  Txt,
  CancelButton,
  SubmitButton,
  DangerSubmitButton,
}
