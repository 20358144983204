import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useRequestListWebInterviewGuidesForLink } from '@/services/bffService/useRequestListWebInterviewGuidesForLink'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useListWebInterviewGuides() {
  const { data, isLoading } = useInnerListWebInterviewGuides()

  const guides = useMemo(() => {
    if (data === undefined) {
      return []
    }
    return data.getWebInterviewGuidesList().map((g) => {
      return {
        name: g.getName(),
        guid: g.getGuid(),
      }
    })
  }, [data])

  return {
    guideList: guides,
    isLoading,
  }
}

export function useInnerListWebInterviewGuides() {
  const { requestListWebInterviewGuidesForLink } =
    useRequestListWebInterviewGuidesForLink()
  return useQuery([QUERY_KEY.webInterviewGuides], () =>
    requestListWebInterviewGuidesForLink()
  )
}
