import React from 'react'
import {
  Administrator,
  administrator,
  Assistant,
  assistant,
  AuthzType,
  Normal,
  normal,
} from '@blue-agency/im-shared-front'
import { RadioGroup } from '@blue-agency/rogue'
import styled from 'styled-components'

type Option<T extends AuthzType> = {
  value: T['type']
  label: T['name']
  helperText: React.ReactNode
}

const options: Option<AuthzType>[] = [
  {
    value: administrator.type,
    label: administrator.name,
    helperText:
      'ユーザーの管理や、面接の実施及び管理、企業情報の編集などができます。',
  } as Option<Administrator>,
  {
    value: normal.type,
    label: normal.name,
    helperText: '面接の実施や、面接の録画データの閲覧ができます。',
  } as Option<Normal>,
  {
    value: assistant.type,
    label: assistant.name,
    helperText: (
      <>
        面接の登録や面接開始ページのリンクの取得などができます。
        <br />
        なお、面接の実施や面接の録画データは閲覧できません。
      </>
    ),
  } as Option<Assistant>,
]

export const AuthzTypeRadioGroup = styled(RadioGroup).attrs({
  options,
  direction: 'column',
})``
