import { useMemo } from 'react'
import { ListSelectionStepMastersForOptionResponse } from '@blue-agency/proton/web/v2/biz_anakin_bff'
import * as v2_im_selection_pb from '@blue-agency/proton/web/v2/im/selection_pb'
import { useQuery } from 'react-query'
import { assertIsDefined } from '@/assertions'
import { useRequestListSelectionStepMastersForOption } from '@/services/bffService/useRequestListSelectionStepMastersForOption'
import { QUERY_KEY } from '@/services/queryKeyService'

export type SelectionStepMaster = {
  guid: string
  name: string
  type: '未定義' | '録画面接'
}

export const useListSelectionStepMasters = () => {
  const { isLoading, data } = useListSelectionStepMastersQuery()

  const selectionStepMasters = useMemo<SelectionStepMaster[]>(() => {
    if (isLoading) return []
    assertIsDefined(data)
    return data.getSelectionStepMastersList().map(toSelectionStepMaster)
  }, [isLoading, data])

  return {
    isLoading,
    selectionStepMasters,
  }
}

const toSelectionStepMaster = (
  response: ListSelectionStepMastersForOptionResponse.SelectionStepMaster
): SelectionStepMaster => {
  const type =
    response.getType() ===
    v2_im_selection_pb.SelectionStepType.SELECTION_STEP_TYPE_REC_INTERVIEW
      ? '録画面接'
      : '未定義'

  const selectionStepMaster: SelectionStepMaster = {
    guid: response.getGuid(),
    name: response.getName(),
    type: type,
  }

  return selectionStepMaster
}

function useListSelectionStepMastersQuery() {
  const { requestListSelectionStepMastersForOption } =
    useRequestListSelectionStepMastersForOption()

  return useQuery([QUERY_KEY.listSelectionStepMastersForOption], () =>
    requestListSelectionStepMastersForOption()
  )
}
