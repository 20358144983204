import React from 'react'
import { TertiaryButton, PrimaryButton } from '@blue-agency/rogue/im'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Link } from '@/components/Link'
import { SettingStaffEditPageContainer } from '../SettingStaffEditPageContainer'

export const Buttons: React.VFC<PropsWithClassName> = ({ className }) => {
  const { onSubmit, pathToStaffDetailPage } =
    SettingStaffEditPageContainer.useContainer()
  return (
    <>
      <Link to={pathToStaffDetailPage}>
        <TertiaryButton
          widthSize="L1"
          comlinkPushParams={{ action: 'back_user_detail_page' }}
        >
          キャンセル
        </TertiaryButton>
      </Link>
      <PrimaryButton
        widthSize="L1"
        type="submit"
        onClick={onSubmit}
        comlinkPushParams={{
          action: 'click_user_edit_confirm',
        }}
      >
        内容を確認
      </PrimaryButton>
    </>
  )
}
