import React, { useCallback } from 'react'
import { FixedBottomFormPage as Page } from '@blue-agency/im-shared-front'
import { Dropdown, RadioGroup, Txt } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { InputText } from '@blue-agency/rogue/im'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { SettingsSection } from '@/components/SettingsSection'
import { FormType, MailFlag, SharingTypeValue, Permission } from '.'
import * as C from './components'
import { SelectionStepMaster } from './useSelectionStepMastersList'

type Props = {
  initialAtsConnect?: FormType
  form?: FormType
  onCancel: () => void
  onSubmit: (form: FormType) => void
  selectionStepMasters: SelectionStepMaster[]
  guideOptions: { label: string; value: string }[]
  permission: Permission
}

type SendMailOption = {
  label: MailFlag
  value: MailFlag
}

type SharingTypeOption = {
  label: string
  value: SharingTypeValue
}

export const sharingTypeOptions: SharingTypeOption[] = [
  {
    label: '共有する',
    value: 'all',
  },
  {
    label: '共有しない',
    value: 'unshared',
  },
]

const validationSchema: yup.SchemaOf<FormType> = yup.object({
  name: yup
    .string()
    .required('入力してください')
    .max(30, '30文字以内で入力してください'),
  note: yup.string().max(30, '30文字以内で入力してください'),
  recInterviewSetting: yup.object().shape({
    selectionStepMasterGUID: yup.string(),
    requestMail: yup.mixed<MailFlag>().required('選択してください'),
  }),
  applicantSetting: yup.object().shape({
    sharingType: yup.mixed<SharingTypeValue>().required('選択してください'),
  }),
  webInterviewSetting: yup.object().shape({
    defaultWebInterviewGuideGUID: yup.string(),
  }),
})

export const Input: React.VFC<Props> = ({
  initialAtsConnect,
  form,
  onSubmit,
  onCancel,
  selectionStepMasters,
  guideOptions,
  permission,
}) => {
  const { trigger, getValues, register, formState, control } =
    useForm<FormType>({
      resolver: yupResolver(validationSchema),
      mode: 'onBlur',
      defaultValues: form ?? initialAtsConnect,
    })

  const confirm = useCallback(async () => {
    const valid = await trigger()
    if (!valid) return
    onSubmit(getValues())
  }, [onSubmit, trigger, getValues])

  // 登録日時降順でレスポンスが返ってくるので文字コード順に並び替える
  const selectionStepMasterOptions = selectionStepMasters
    .map((v) => ({
      label: v.name,
      value: v.guid,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const sendMailOptions: SendMailOption[] = [
    {
      label: '送信する',
      value: '送信する',
    },
    {
      label: '送信しない',
      value: '送信しない',
    },
  ]

  return (
    <>
      <Page.Body>
        <SettingsSection title="連携設定情報">
          <C.FormSection>
            <C.StyledFormRow required title="連携先">
              <InputText
                {...register('name')}
                onBlurError={formState.errors.name?.message}
                size="ll"
              />
            </C.StyledFormRow>
            <C.Separator />
            <C.StyledFormRow title="メモ">
              <InputText
                {...register('note')}
                onBlurError={formState.errors.note?.message}
                size="ll"
              />
            </C.StyledFormRow>
            {permission.recInterviewEnabled && (
              <>
                <C.Separator />
                <C.StyledFormRow
                  required
                  title="応募者"
                  subtitle="一般権限への共有"
                >
                  <RadioGroup
                    {...register('applicantSetting.sharingType')}
                    options={sharingTypeOptions}
                    direction="row"
                  />
                </C.StyledFormRow>
              </>
            )}
            {permission.webInterviewEnabled && (
              <>
                <C.Separator />
                <C.StyledFormRow title="Web面接" subtitle="Web面接ガイド">
                  <Dropdown
                    {...register(
                      'webInterviewSetting.defaultWebInterviewGuideGUID'
                    )}
                    size="ll"
                    options={guideOptions}
                    value={
                      getValues().webInterviewSetting
                        ?.defaultWebInterviewGuideGUID
                    }
                    allowEmpty
                  />
                </C.StyledFormRow>
              </>
            )}
            {permission.recInterviewEnabled && (
              <>
                <C.Separator />
                <C.StyledFormRow title="録画面接" subtitle="選考ステップ">
                  <Dropdown
                    {...register('recInterviewSetting.selectionStepMasterGUID')}
                    size="ll"
                    options={selectionStepMasterOptions}
                    value={
                      getValues().recInterviewSetting?.selectionStepMasterGUID
                    }
                    allowEmpty
                  />
                </C.StyledFormRow>
                <C.StyledFormRow required subtitle="メール自動送信">
                  <RadioGroup
                    {...register('recInterviewSetting.requestMail')}
                    options={sendMailOptions}
                    direction="row"
                  />
                </C.StyledFormRow>
              </>
            )}
            {permission.videoSeminarEnabled && (
              <>
                <C.Separator />
                <C.StyledFormRow title="ビデオセミナー">
                  <Txt>現在設定メニューはありません</Txt>
                </C.StyledFormRow>
              </>
            )}
          </C.FormSection>
        </SettingsSection>
      </Page.Body>
      <Page.Bottom>
        <Page.BottomButtons>
          <TertiaryButton
            onClick={onCancel}
            comlinkPushParams={{
              action: 'click_cancel_button_on_api_settings_v3_edit_page',
            }}
            widthSize="L2"
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            onClick={confirm}
            comlinkPushParams={{
              action: 'click_confirm_button_on_api_settings_v3_edit_page',
            }}
            widthSize="L2"
          >
            内容を確認
          </PrimaryButton>
        </Page.BottomButtons>
      </Page.Bottom>
      <DevTool control={control} placement="bottom-right" />
    </>
  )
}
