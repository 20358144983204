import * as yup from 'yup'
import { RegisterStaffRequestValue } from '@/services/bffService'
import {
  authzTypeSchema,
  basicStringSchema,
  emailSchema,
} from '@/services/formService'
import { authnTypeSchema } from '@/services/formService/authnTypeSchema'

export const validationScheme: yup.SchemaOf<RegisterStaffRequestValue> =
  yup.object({
    givenName: basicStringSchema,
    familyName: basicStringSchema,
    givenNameKana: basicStringSchema,
    familyNameKana: basicStringSchema,
    email: emailSchema,
    authzType: authzTypeSchema,
    authnType: authnTypeSchema,
  })
