import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateAtsConnectV3Request } from '@blue-agency/proton/biz_anakin_bff'
import { SharingTypeInput } from '@blue-agency/proton/web/v2/im/sharing_type_pb'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export type RequestUpdateAtsConnectV3Args = {
  guid: string
  name: string
  note: string
  recInterviewSetting: {
    selectionStepMasterGUID: string
    requestMail: boolean
  }
  applicantSetting: {
    sharingType: 'unshared' | 'all'
  }
  webInterviewSetting: {
    defaultWebInterviewGuideGUID: string
  }
}
export function useRequestUpdateAtsConnectV3() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateAtsConnectV3 = useCallback(
    async (args: RequestUpdateAtsConnectV3Args) => {
      const metadata = await buildMetadata()

      const recInterviewSetting =
        new UpdateAtsConnectV3Request.RecInterviewSetting()
      recInterviewSetting.setSelectionStepMasterGuid(
        args.recInterviewSetting.selectionStepMasterGUID
      )
      recInterviewSetting.setRequestMail(args.recInterviewSetting.requestMail)

      const applicantSetting = new UpdateAtsConnectV3Request.ApplicantSetting()
      const sharingType = new SharingTypeInput()
      if (args.applicantSetting.sharingType === 'unshared') {
        sharingType.setUnshared(true)
      } else {
        sharingType.setAll(true)
      }
      applicantSetting.setSharingType(sharingType)

      const webInterviewSetting =
        new UpdateAtsConnectV3Request.WebInterviewSetting()
      webInterviewSetting.setDefaultWebInterviewGuideGuid(
        args.webInterviewSetting.defaultWebInterviewGuideGUID
      )

      const req = new UpdateAtsConnectV3Request()
      req.setGuid(args.guid)
      req.setName(args.name)
      req.setNote(args.note)
      req.setRecInterviewSetting(recInterviewSetting)
      req.setApplicantSetting(applicantSetting)
      req.setWebInterviewSetting(webInterviewSetting)

      return callBizAnakinBffService(
        'updateAtsConnectV3',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestUpdateAtsConnectV3,
  }
}
