import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ChangePasswordRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export type ChangePasswordRequestValue = {
  currentPassword: string
  newPassword: string
}

export function useRequestChangePassword() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestChangePassword = useCallback(
    async (data: ChangePasswordRequestValue) => {
      const req = new ChangePasswordRequest()
      req.setCurrentPassword(data.currentPassword)
      req.setNewPassword(data.newPassword)

      const metadata = await buildMetadata()

      return callBizAnakinBffService(
        'changePassword',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestChangePassword }
}
