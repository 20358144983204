import { useCallback, useMemo, useState } from 'react'
import { toast } from '@blue-agency/rogue'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
  CustomBizAnakinBffGrpcError,
  useRequestChangePassword,
} from '@/services/bffService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { validationSchema } from './formSchema'

export type Form = {
  currentPassword: string
  newPassword: string
  newPasswordConfirm: string
}

type SubmitError = 'currentPasswordMismatch' | 'tooWeakPassword'

export function useEditPassword() {
  const history = useHistory()

  const { register, handleSubmit, setValue, setError, formState } =
    useForm<Form>({
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
      defaultValues: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
    })
  const onCancel = useCallback(() => {
    history.push(INTERNAL_PATHS.staffProfile)
  }, [history])

  const [submitError, setSubmitError] = useState<SubmitError | undefined>(
    undefined
  )

  const onTooWeekPasswordError = useCallback(() => {
    setSubmitError('tooWeakPassword')
    setValue('newPassword', '')
    setError('newPassword', { type: 'manual' }, { shouldFocus: true })
    setValue('newPasswordConfirm', '')
    setError('newPasswordConfirm', { type: 'manual' })
  }, [setError, setValue])

  const onCurrentPasswordMismatchError = useCallback(() => {
    setSubmitError('currentPasswordMismatch')
    setError('currentPassword', { type: 'manual' }, { shouldFocus: true })
  }, [setError])

  const { mutateAsync: mutatePassword } = useChangePasswordMutation()

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        if (!data) return
        setSubmitError(undefined)
        try {
          await mutatePassword(data)
          toast('パスワード情報を変更しました')
          history.push(INTERNAL_PATHS.staffProfile)
        } catch (e) {
          if (e instanceof CustomBizAnakinBffGrpcError) {
            if (
              e.hasMatchErrorDetail(
                'proton.v2.biz_anakin_bff.TooWeakPasswordErrorDetail'
              )
            ) {
              onTooWeekPasswordError()
              return
            }
            if (
              e.hasMatchErrorDetail(
                'proton.v2.biz_anakin_bff.CurrentPasswordMismatchErrorDetail'
              )
            ) {
              onCurrentPasswordMismatchError()
              return
            }
          }
          // TODO: 仕様決まったら適切に処理する
          throw e
        }
      }),
    [
      handleSubmit,
      setSubmitError,
      onTooWeekPasswordError,
      onCurrentPasswordMismatchError,
      history,
      mutatePassword,
    ]
  )

  return {
    register,
    submitError,
    errors: formState.errors,
    onSubmit,
    onCancel,
  }
}

export function useChangePasswordMutation() {
  const { requestChangePassword } = useRequestChangePassword()

  return useMutation(requestChangePassword)
}
