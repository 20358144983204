import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetAtsConnectV3Request } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetAtsConnectV3() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetAtsConnectV3 = useCallback(
    async (guid: string) => {
      const metadata = await buildMetadata()

      const req = new GetAtsConnectV3Request()
      req.setGuid(guid)

      return callBizAnakinBffService(
        'getAtsConnectV3',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestGetAtsConnectV3,
  }
}
