import Papa from 'papaparse'
import { CsvHeader, CsvBody } from '.'

export function parseCsv(text: string): [CsvHeader | undefined, CsvBody] {
  const content = Papa.parse<string[]>(text)

  if (content.errors.length > 0) {
    throw new Error('Failed to parse csv')
  }

  const [header, ...body] = content.data
  return [header, body]
}
