import { FallbackProps } from 'react-error-boundary'
import { CustomBizAnakinBffGrpcError } from '@/services/bffService'
import { AlreadyExistsPage } from './AlreadyExistsPage'
import { InvalidArgumentPage } from './InvalidArgumentPage'

export const ErrorFallback = ({ error }: FallbackProps) => {
  if (error instanceof CustomBizAnakinBffGrpcError) {
    if (error.isAlreadyExists) {
      return <AlreadyExistsPage />
    } else if (error.isInvalidArgument) {
      return <InvalidArgumentPage />
    }
  }

  throw error
}
