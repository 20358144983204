import React from 'react'
import {
  PageLayoutWithGlonavi,
  DetailPage as Page,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { Icon, NewTabLink, Txt } from '@blue-agency/rogue'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { EXTERNAL_PATHS, INTERNAL_PATHS } from '@/services/urlService'
import { Content } from './Content'

export const APISettingsDetailV3Page: React.VFC = () => {
  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>API連携設定詳細</Page.HeaderTitle>
          <HelpMessage>
            <QuestionIcon />
            各項目の詳細は
            <NewTabLink
              href={EXTERNAL_PATHS.help.atsConnect}
              action="open_help_page_on_ats_connect_v3_detail"
            >
              ヘルプページ
            </NewTabLink>
            をご確認ください
          </HelpMessage>
          <Page.HeaderRight>
            <LinkTo to={INTERNAL_PATHS.apiSettings.index}>
              <Txt>一覧に戻る</Txt>
            </LinkTo>
          </Page.HeaderRight>
        </Page.Header>
        <Page.Body>
          <Content />
        </Page.Body>
      </Page.Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const HelpMessage = styled(Txt)`
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: 32px;
`

const QuestionIcon = styled(Icon).attrs({ name: 'question', size: 'l' })`
  margin-right: 5px;
`

const LinkTo = styled(Link)`
  color: ${theme.color.navy[1]};
`
