import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  // https://github.com/blue-agency/biz-anakin-front/pull/513/files#r560612571
  title: Exclude<React.ReactChild, number>
  children: React.ReactNode
}

export const SettingsSection: React.FCX<Props> = (props) => {
  const renderTitle = () => {
    if (typeof props.title === 'string') {
      return <Txt size="l">{props.title}</Txt>
    } else {
      return props.title
    }
  }
  return (
    <div className={props.className}>
      <Header>{renderTitle()}</Header>
      {props.children}
    </div>
  )
}

const Header = styled.div`
  background-color: ${theme.color.navy[3]};
  color: ${theme.color.navy[1]};
  padding: 8px 20px;
  display: flex;
  vertical-align: middle;
`
