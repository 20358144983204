import { useCallback, useMemo, useState } from 'react'
import { getInternalUrls } from '@blue-agency/im-shared-front'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { assertIsDefined } from '@/assertions'
import { useSearchParameters } from '@/hooks/useSearchParams'
import {
  ActivateStaffRequestValue,
  CustomBizAnakinBffGrpcError,
  useRequestActivateStaff,
  useRequestGetOrganizationByRegisterToken,
} from '@/services/bffService'
import { useRequestVerifyRegisterToken } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { ActivateStaffForm, validationScheme } from './formSchema'

export function useActivateStaffPage() {
  const { requestVerifyRegisterToken } = useRequestVerifyRegisterToken()
  const { token } = useSearchParameters<{ token: string }>()

  if (token === undefined) {
    throw new Error('token not found')
  }

  const [hasOccurredTooWeakPasswordError, setHasOccurredTooWeakPasswordError] =
    useState(false)

  // NOTE: verify が失敗したとき例外を投げる
  // 正常なときの戻り値はいらない
  const { isSuccess: isTokenVerified } = useQuery(
    [QUERY_KEY.verifyRegisterToken, token],
    () => {
      return requestVerifyRegisterToken(token)
    },
    {
      useErrorBoundary: true,
    }
  )

  const { requestGetOrganizationByRegisterToken } =
    useRequestGetOrganizationByRegisterToken()
  const { isLoading, isIdle, data } = useQuery(
    [QUERY_KEY.getOrganizationByRegisterToken, token],
    () => {
      return requestGetOrganizationByRegisterToken(token)
    },
    {
      enabled: isTokenVerified,
    }
  )
  const getOrganizationName = () => {
    const name = data?.getOrganization()?.getName()
    assertIsDefined(name)
    return name
  }

  const { register, handleSubmit, reset, formState } =
    useForm<ActivateStaffForm>({
      resolver: yupResolver(validationScheme),
      mode: 'onChange',
    })

  const { mutateAsync: mutateStaff } = useActivateStaffMutation()
  const activateStaff = useCallback(
    async (data: ActivateStaffForm) => {
      const req: ActivateStaffRequestValue = {
        token,
        password: data.password,
      }

      try {
        await mutateStaff(req)
      } catch (e) {
        if (e instanceof CustomBizAnakinBffGrpcError) {
          if (
            e.hasMatchErrorDetail(
              'proton.v2.biz_anakin_bff.TooWeakPasswordErrorDetail'
            )
          ) {
            reset()
            setHasOccurredTooWeakPasswordError(true)
            return
          }
        }

        throw e
      }

      window.location.href = getInternalUrls().signIn
    },
    [mutateStaff, token, reset]
  )

  const onSubmit = useMemo(
    () => handleSubmit(activateStaff),
    [handleSubmit, activateStaff]
  )

  return {
    getOrganizationName,
    register,
    errors: formState.errors,
    isLoading: isIdle || isLoading,
    activateStaff,
    onSubmit,
    hasOccurredTooWeakPasswordError,
    isSubmitting: formState.isSubmitting,
  }
}

function useActivateStaffMutation() {
  const { requestActivateStaff } = useRequestActivateStaff()

  return useMutation(requestActivateStaff)
}
