import React, { useCallback } from 'react'
import { Txt, Modal, WeakColorSeparator, theme } from '@blue-agency/rogue'
import { TertiaryButton, DangerButton } from '@blue-agency/rogue/im'
import { Dropdown, DropdownOption, FormRow } from '@blue-agency/rogue/im'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import { authnTypeToText } from '@/components/authnType'
import type { AuthnType } from '@/services/authnService'
import { authnTypeSchema } from '@/services/formService/authnTypeSchema'
import { ReactComponent as OrangeInfoIcon } from './orange-info.svg'

export const ChangeAuthnTypeModal: React.VFC<{
  active: boolean
  close: () => void
  authnType: AuthnType['type']
  onSubmit: (newAuthnType: AuthnType['type']) => void
}> = ({ active, close, authnType, onSubmit }) => {
  const authnTypeText = authnTypeToText(authnType)

  const { register, getValues, formState, trigger, reset } = useForm<{
    authnType: AuthnType['type']
  }>({
    resolver: yupResolver(yup.object({ authnType: authnTypeSchema })),
    mode: 'onBlur',
  })

  const updateAuthnType = useCallback(async () => {
    const ok = await trigger()
    if (!ok) return
    onSubmit(getValues('authnType'))
  }, [onSubmit, getValues, trigger])

  const onCancel = useCallback(() => {
    reset()
    close()
  }, [reset, close])

  return (
    <Modal size="l" title="認証方法を変更" active={active} onClose={close}>
      <Top>
        <AuthnTypeTxt>認証方法</AuthnTypeTxt>
        <FormArea>
          <FormRow
            title={
              <Txt bold size="s">
                現在の認証方法
              </Txt>
            }
            subtitleWidth="0"
          >
            <Txt>{authnTypeText}</Txt>
          </FormRow>
          <FormRow
            title={
              <Txt bold size="s">
                変更後の認証方法
              </Txt>
            }
            required
            subtitleWidth="0"
          >
            <Dropdown
              {...register('authnType')}
              options={authnTypeOptions.filter(
                (option) => option.value !== authnType // 選択中の認証方法以外
              )}
              onBlurError={formState.errors.authnType?.message ?? ''}
            />
          </FormRow>
          <TopInfo>
            <OrangeInfoIcon />
            <TopInfoTxt>
              認証方法の変更は、即時反映されますので当該ユーザーが面接する直前などは避けて、余裕を持って実行してください。
            </TopInfoTxt>
          </TopInfo>
        </FormArea>
      </Top>
      <WeakColorSeparator />
      <Bottom>
        <TxtBox>
          <BottomCaution>
            <RedTxt>
              パスワード認証への変更：即時強制ログアウトされます。送信されたメールから、初回ログインの手続きを実施してください。
            </RedTxt>
          </BottomCaution>
          <BottomCaution>
            <RedTxt>
              その他認証方法への変更：即時反映されます。
              変更以降、過去の認証方法でのログインは無効化されます。
            </RedTxt>
          </BottomCaution>
        </TxtBox>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={onCancel}
            comlinkPushParams={{ action: 'close_change_authn_type_modal' }}
          >
            キャンセル
          </TertiaryButton>
          <DangerButton
            widthSize="L1"
            comlinkPushParams={{ action: 'change_authn_type' }}
            onClick={updateAuthnType}
          >
            変更
          </DangerButton>
        </ButtonGroup>
      </Bottom>
    </Modal>
  )
}

const authnTypeOptions: DropdownOption[] = [
  { value: 'password', label: authnTypeToText('password') },
  { value: 'microsoft', label: authnTypeToText('microsoft') },
  { value: 'google', label: authnTypeToText('google') },
]

const AuthnTypeTxt = styled(Txt).attrs({ size: 'm' })`
  width: 80px;
  display: flex;
`

const Top = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
`

const TopInfo = styled.div`
  display: inline-flex;
  background-color: ${theme.color.orange[2]};
  padding: 6px;
`

const TopInfoTxt = styled(Txt).attrs({
  size: 'm',
  color: theme.color.orange[1],
})`
  padding-left: 4.5px;
`

const FormArea = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
`

const Bottom = styled.div`
  padding: 20px;
`

const TxtBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
`

const RedTxt = styled(Txt).attrs({ color: theme.color.red[2], size: 'm' })``

const BottomCaution: React.FC = ({ children }) => {
  return (
    <BottomCautionBox>
      <RedTxt>※</RedTxt>
      {children}
    </BottomCautionBox>
  )
}

const BottomCautionBox = styled.div`
  display: inline-flex;
  width: 475px;
`

const ButtonGroup = styled.div`
  display: flex;
  padding-top: 20px;
  justify-content: center;
  column-gap: 20px;
`
