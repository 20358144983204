import { useMemo } from 'react'
import {
  commonErrorToast,
  QUERY_KEY as SHARED_QUERY_KEY,
} from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import {
  UpdateOrganizationRequestValue,
  useRequestUpdateOrganization,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { validationSchema } from './formSchema'

type Args = {
  onCloseModal: () => void
  defaultName: string
}

export function useEditOrganizationName({ onCloseModal, defaultName }: Args) {
  const { register, handleSubmit, formState, reset } =
    useForm<UpdateOrganizationRequestValue>({
      resolver: yupResolver(validationSchema),
      mode: 'onBlur',
      defaultValues: {
        name: defaultName,
      },
    })

  const { mutateAsync: mutateOrganization, isLoading } =
    useUpdateOrganizationMutation()

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        try {
          await mutateOrganization(data)
        } catch (e) {
          Sentry.captureException(e)
          commonErrorToast()
          return
        }
        toast('企業情報を保存しました')
        onCloseModal()
        reset({ name: data.name })
      }),
    [handleSubmit, reset, mutateOrganization, onCloseModal]
  )

  return {
    register,
    onSubmit,
    errors: formState.errors,
    isLoading,
  }
}

function useUpdateOrganizationMutation() {
  const queryClient = useQueryClient()
  const { requestUpdateOrganization } = useRequestUpdateOrganization()

  return useMutation(requestUpdateOrganization, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.getOrganization)
      queryClient.invalidateQueries(SHARED_QUERY_KEY.loggedInStaff)
    },
  })
}
