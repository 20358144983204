import React, { useCallback } from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { StaffTable } from './useStaffsTable'

type Option = {
  value: StaffTable
  label: string
}

type Props = {
  options: readonly Option[]
  activeTable: StaffTable
  onSelect: (table: StaffTable) => void
}

export const StaffTableOptions: React.VFC<Props> = (props) => {
  return (
    <Wrapper>
      {props.options.map((option) => (
        <Row
          key={option.value}
          option={option}
          selected={option.value === props.activeTable}
          onSelect={props.onSelect}
        />
      ))}
    </Wrapper>
  )
}

type RowProps = {
  option: Option
  selected?: boolean
  onSelect: (table: StaffTable) => void
}

const Row: React.VFC<RowProps> = (props) => {
  const txtColor = props.selected ? theme.color.white[1] : theme.color.navy[1]

  const onClick = useCallback(() => {
    props.onSelect(props.option.value)
  }, [props])

  return (
    <RowComponent selected={props.selected} onClick={onClick}>
      <Txt color={txtColor}>{props.option.label}</Txt>
    </RowComponent>
  )
}

const Wrapper = styled.div`
  background-color: ${theme.color.white[1]};
  border: 1px solid ${theme.color.gray[3]};
  border-left-width: 0;
  border-right-color: ${theme.color.navy[1]};
  border-right-width: 3px;
  border-radius: 0 4px 4px 0;
  overflow: hidden;
`

const RowComponent = styled.div<{ selected?: boolean }>`
  background-color: ${({ selected }) =>
    selected ? theme.color.navy[1] : theme.color.white[1]};
  cursor: pointer;
  padding: 16px 24px;
`
