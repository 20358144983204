import React from 'react'
import { Txt as _Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Form } from '@/components/Form'

type Props = PropsWithClassName<{
  familyName: string
  givenName: string
  familyNameKana: string
  givenNameKana: string
}>

export const NameSection = (props: Props): React.ReactElement => {
  return (
    <Form.Section className={props.className}>
      <Form.Row>
        <Form.Title>氏名</Form.Title>
        <Form.Item>
          <Form.Label>氏名</Form.Label>
          <Form.Field>
            <Txt>{props.familyName}</Txt>
            <Txt>{props.givenName}</Txt>
          </Form.Field>
        </Form.Item>
      </Form.Row>

      <Form.Row>
        <Form.Item>
          <Form.Label>かな</Form.Label>
          <Form.Field>
            <Txt>{props.familyNameKana}</Txt>
            <Txt>{props.givenNameKana}</Txt>
          </Form.Field>
        </Form.Item>
      </Form.Row>
    </Form.Section>
  )
}

const Txt = styled(_Txt).attrs({ size: 'm' })`
  height: 32px;
  &:not(:first-child) {
    margin-left: 4px;
  }
`
