import React from 'react'
import {
  PageLayoutWithGlonavi,
  FixedBottomFormPage as Page,
  usePromptBeforeUnload,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { Content } from './Content'

export const EditPasswordPage: React.VFC = () => {
  usePromptBeforeUnload()

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>パスワード変更</Page.HeaderTitle>
        </Page.Header>
        <Content />
      </Page.Wrapper>
    </PageLayoutWithGlonavi>
  )
}
