import React, { useEffect } from 'react'
import { provide } from '@blue-agency/front-state-management'
import {
  usePageLayoutComponent,
  AuthContainer,
  injectAlertBox,
  CommonErrorToastContainer,
  GeneralErrorPage,
  BizAuthorizedErrorBoundary,
  BizUnauthorizedErrorBoundary,
  NewWindowChatBot,
} from '@blue-agency/im-shared-front'
import '@blue-agency/im-shared-front/dist/assets/PageLayout.css'
import { ToastContainer } from '@blue-agency/rogue'
import '@blue-agency/rogue/dist/assets/style.css'
import * as Sentry from '@sentry/react'
import assert from 'assert'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Router, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { AuthRoutes } from '@/AuthRoutes'
import { DeviceTypeContainer } from '@/containers/DeviceTypeContainer'
import { SuspenseWithLoadingOverlay } from './components/SuspenseWithLoadingOverlay'
import { WindowSizeContainer } from './containers/WindowSizeContainer'
import { history } from './historyModule'
import { useTracking } from './hooks/useTracking'
import { initSentry } from './initSentry'
import { initializeImSharedFront } from './initializeImSharedFront'

const alertJsonUrl = process.env.REACT_APP_ALERT_JSON_URL
assert(alertJsonUrl, 'alertJsonUrl is required')

initializeImSharedFront()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      useErrorBoundary: true,
    },
  },
})

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  initSentry()
}

const App = provide(
  DeviceTypeContainer,
  WindowSizeContainer
)(() => {
  useTracking()
  usePageLayoutComponent()

  useEffect(() => {
    injectAlertBox(alertJsonUrl)
  }, [])

  return (
    <React.StrictMode>
      <Router history={history}>
        <Sentry.ErrorBoundary
          fallback={() => <GeneralErrorPage showDashboardLink={false} />}
        >
          <QueryParamProvider ReactRouterRoute={Route}>
            <SuspenseWithLoadingOverlay>
              <AuthContainer.Provider>
                <SharedErrorBoundary>
                  <QueryClientProvider client={queryClient}>
                    <AuthRoutes />
                    <ToastContainer />

                    <CommonErrorToastContainer />
                    <ReactQueryDevtools />
                    <NewWindowChatBot />
                  </QueryClientProvider>
                </SharedErrorBoundary>
              </AuthContainer.Provider>
            </SuspenseWithLoadingOverlay>
          </QueryParamProvider>
        </Sentry.ErrorBoundary>
      </Router>
    </React.StrictMode>
  )
})

export default App

const SharedErrorBoundary: React.FC = (props) => {
  const { isLoggedIn, logout } = AuthContainer.useContainer()

  return isLoggedIn ? (
    <BizAuthorizedErrorBoundary onUnauthenticated={logout}>
      {props.children}
    </BizAuthorizedErrorBoundary>
  ) : (
    <BizUnauthorizedErrorBoundary>
      {props.children}
    </BizUnauthorizedErrorBoundary>
  )
}
