import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListSelectionStepMastersForOptionRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestListSelectionStepMastersForOption() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListSelectionStepMastersForOption = useCallback(async () => {
    const metadata = await buildMetadata()
    const req = new ListSelectionStepMastersForOptionRequest()

    return callBizAnakinBffService(
      'listSelectionStepMastersForOption',
      [req, metadata],
      loggedInStaff?.guid
    )
  }, [buildMetadata, loggedInStaff])

  return { requestListSelectionStepMastersForOption }
}
