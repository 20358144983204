import React from 'react'
import { Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

export const Body: React.VFC<{ password: boolean }> = ({ password }) => {
  return (
    <Wrapper>
      <MessageTitle>ユーザー登録が完了しました</MessageTitle>
      <TxtContainer>
        <Txt>
          登録したメールアドレスに、
          {password ? '利用開始メール' : '登録完了メール'}を送信しました。
        </Txt>
        <Txt>
          メールをご確認いただき、初回ログインの手続きを完了してください。
        </Txt>
      </TxtContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 56px;
`

const MessageTitle = styled(Txt).attrs({ size: 'l' })`
  text-align: center;
`

const TxtContainer = styled.div`
  margin-top: 12px;
  text-align: center;
`
