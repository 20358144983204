import { Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

export const LineClampedTxt = styled(Txt)<{
  line: number
}>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ line }) => line};
  overflow: hidden;
  line-break: anywhere;
`
