import * as Sentry from '@sentry/react'
import { CustomBizAnakinBffGrpcError } from './services/bffService'

export const initSentry = () => {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    beforeSend(
      event: Sentry.Event,
      hint?: Sentry.EventHint
    ): PromiseLike<Sentry.Event | null> | Sentry.Event | null {
      // TODO: タイムアウト等sentryに通知不要なエラーは無視する
      if (
        hint &&
        hint.originalException instanceof CustomBizAnakinBffGrpcError
      ) {
        if (!event.tags) {
          event.tags = {}
        }
        event.tags.grpc_status_code = hint.originalException.code.toString()
        if (!event.extra) {
          event.extra = {}
        }
        event.extra.CustomGrpcError = hint.originalException
      }
      return event
    },
  })
}
