import React from 'react'
import { InputText as _InputText } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Form } from '@/components/Form'
import { SettingStaffRegisterByFormPageContainer } from '../../SettingStaffRegisterByFormPageContainer'

export const NameSection = ({
  className,
}: PropsWithClassName): React.ReactElement => {
  const { register, errors } =
    SettingStaffRegisterByFormPageContainer.useContainer()

  return (
    <Form.Section className={className}>
      <Form.Row>
        <Form.Title>氏名</Form.Title>
        <Form.Item>
          <Form.Label>氏名</Form.Label>
          <Form.Required>必須</Form.Required>
          <Form.Field>
            <InputText
              {...register('familyName')}
              placeholder="印田"
              onBlurError={errors['familyName']?.message}
              autoComplete="off"
            />
            <InputText
              {...register('givenName')}
              placeholder="芽衣華"
              onBlurError={errors['givenName']?.message}
              autoComplete="off"
            />
          </Form.Field>
          <FormHelper>「姓」の内容は面接中に応募者に表示されます。</FormHelper>
        </Form.Item>
      </Form.Row>

      <Form.Row>
        <Form.Item>
          <Form.Label>かな</Form.Label>
          <Form.Required>必須</Form.Required>
          <Form.Field>
            <InputText
              {...register('familyNameKana')}
              placeholder="いんた"
              onBlurError={errors['familyNameKana']?.message}
              autoComplete="off"
            />
            <InputText
              {...register('givenNameKana')}
              placeholder="めいか"
              onBlurError={errors['givenNameKana']?.message}
              autoComplete="off"
            />
          </Form.Field>
        </Form.Item>
      </Form.Row>
    </Form.Section>
  )
}

const InputText = styled(_InputText)`
  margin-top: -9px;
  margin-bottom: 9px;
  & + & {
    margin-left: 20px;
  }
`

const FormHelper = styled(Form.Helper)`
  margin-top: 0;
  margin-bottom: 8px;
`
