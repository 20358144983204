import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { theme, Txt as _Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { Form } from '@/components/Form'
import { LineClampedTxt } from '@/components/LineClampedTxt'
import { SettingsSection } from '@/components/SettingsSection'
import { scopeTypeNameOf } from '@/services/ipFilterService'
import {
  TooltipForIpAddress,
  TooltipForScopeType,
} from '../SettingIpFilterNewPage/Tooltip'
import * as C from '../SettingIpFilterNewPage/components'
import { useCurrentIp } from '../SettingIpFilterNewPage/useCurrentIp'
import { SettingIpFilterEditPageContainer } from './SettingIpFilterEditPageContainer'

export const Confirm: React.VFC = () => {
  const { currentIp } = useCurrentIp()
  const {
    completedForm,

    onBackToInput,
    onSubmit,
  } = SettingIpFilterEditPageContainer.useContainer()

  if (completedForm === undefined) {
    throw new Error('completedForm is undefined')
  }

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>IPアドレス制限編集</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="内容を確認">
            <Form.Section>
              <C.SectionWrapper>
                <Form.Row>
                  <C.FormTitle>フィルター名</C.FormTitle>
                  <Form.Item>
                    <Form.Field>
                      <Txt>{completedForm.name}</Txt>
                    </Form.Field>
                  </Form.Item>
                </Form.Row>
              </C.SectionWrapper>
              <C.SectionWrapper>
                <Form.Row>
                  <C.FormTitle>
                    対象範囲
                    <TooltipForScopeType />
                  </C.FormTitle>
                  <Form.Item>
                    <Form.Field>
                      <Txt>{scopeTypeNameOf(completedForm.scopeType)}</Txt>
                    </Form.Field>
                  </Form.Item>
                </Form.Row>
                {completedForm.scopeType === 'specified_staffs' && (
                  <C.SpecifiedStaffFormRow>
                    <Form.Item>
                      <Form.Field>
                        <C.SpecifiedStaffList>
                          {completedForm.scopeSpecifiedStaffs.map(
                            ({ guid, fullName }) => (
                              <li key={guid}>
                                <LineClampedTxt line={1}>
                                  {fullName}
                                </LineClampedTxt>
                              </li>
                            )
                          )}
                        </C.SpecifiedStaffList>
                      </Form.Field>
                    </Form.Item>
                  </C.SpecifiedStaffFormRow>
                )}
              </C.SectionWrapper>
              <C.SectionWrapper>
                <Form.Row>
                  <C.FormTitle>
                    IPアドレス
                    <TooltipForIpAddress />
                  </C.FormTitle>
                  <Form.Item>
                    <Form.Field>
                      <C.CidrList>
                        {Object.values(completedForm.cidrs).map((cidr, idx) => (
                          <li
                            key={`${idx}-${cidr.ipAddress}-${cidr.subnetMask}`}
                          >
                            <C.CidrAddress>{cidr.ipAddress}</C.CidrAddress>
                            <C.CidrSubnet>/{cidr.subnetMask}</C.CidrSubnet>
                          </li>
                        ))}
                      </C.CidrList>
                    </Form.Field>
                  </Form.Item>
                </Form.Row>
                <Form.Row>
                  <Form.Item>
                    <Form.Field>
                      <C.InputNote>
                        現在接続されているアドレス：{currentIp}
                      </C.InputNote>
                    </Form.Field>
                  </Form.Item>
                </Form.Row>
              </C.SectionWrapper>
            </Form.Section>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <C.BackButton
              onClick={onBackToInput}
              comlinkPushParams={{
                action: 'click_back_to_input_edit_ip_filter_page_button',
              }}
            >
              入力画面に戻る
            </C.BackButton>
            <C.SubmitButton
              type="submit"
              onClick={onSubmit}
              comlinkPushParams={{
                action: 'click_submit_edit_ip_filter_button',
              }}
            >
              保存
            </C.SubmitButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const Txt = styled(_Txt).attrs({ size: 'm' })`
  line-height: 32px;
`
