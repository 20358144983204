import React, { ComponentProps } from 'react'
import { RadioGroup, NewTabLink, Txt } from '@blue-agency/rogue'
import { AuthnTypeRadioGroup } from '@/components/AuthnTypeRadioGroup'
import { Form } from '@/components/Form'
import type { AuthnType } from '@/services/authnService'
import { EXTERNAL_PATHS } from '@/services/urlService'

export const AuthnTypeSectionInput: React.VFC<{
  defaultValue: AuthnType['type']
  registerAuthnType: Omit<
    ComponentProps<typeof RadioGroup>,
    'options' | 'direction'
  >
}> = ({ defaultValue, registerAuthnType }) => {
  return (
    <Form.Section>
      <Form.Row>
        <Form.Title>認証方法</Form.Title>
        <Form.Item>
          <Form.Required>必須</Form.Required>
          <Form.Field>
            <AuthnTypeRadioGroup
              {...registerAuthnType}
              defaultValue={defaultValue}
            />
          </Form.Field>
          <Form.Helper textAlign="right">
            <NewTabLink
              href={EXTERNAL_PATHS.help.authnType}
              action="open_authnType_help_page"
            >
              <Txt size="s" color="inherit">
                詳細はこちら
              </Txt>
            </NewTabLink>
          </Form.Helper>
        </Form.Item>
      </Form.Row>
    </Form.Section>
  )
}
