import React from 'react'
import { FixedBottomFormPage as Page } from '@blue-agency/im-shared-front'
import { Txt as _Txt } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Form } from '@/components/Form'
import { SettingsSection } from '@/components/SettingsSection'
import { RegisterOrganizationForm } from '../formScheme'

type Props = {
  form: RegisterOrganizationForm
  onBackStep: () => void
  onComplete: () => void
}

export const ConfirmStep: React.VFC<Props> = ({
  form,
  onBackStep,
  onComplete,
}): React.ReactElement => {
  return (
    <Page.Wrapper>
      <Page.Header>
        <Page.HeaderTitle>
          インタビューメーカーご利用開始フォーム
        </Page.HeaderTitle>
      </Page.Header>
      <Page.Body>
        <SettingsSection title="内容の確認">
          <Form.Section>
            <Form.Row>
              <Form.Title>企業名</Form.Title>
              <Form.Item>
                <Form.Field>
                  <Txt>{form.organizationName}</Txt>
                </Form.Field>
              </Form.Item>
            </Form.Row>
          </Form.Section>
          <Form.Section>
            <Form.Row>
              <Form.Title>氏名</Form.Title>
              <Form.Item>
                <Form.Label>氏名</Form.Label>
                <Form.Field>
                  <Txt>
                    {form.familyName} {form.givenName}
                  </Txt>
                </Form.Field>
              </Form.Item>
            </Form.Row>
            <Form.Row>
              <Form.Item>
                <Form.Label>かな</Form.Label>
                <Form.Field>
                  <Txt>
                    {form.familyNameKana} {form.givenNameKana}
                  </Txt>
                </Form.Field>
              </Form.Item>
            </Form.Row>
          </Form.Section>
          <Form.Section>
            <Form.Row>
              <Form.Title>メールアドレス</Form.Title>
              <Form.Item>
                <Form.Field>
                  <Txt>ご登録いただいたメールアドレス</Txt>
                </Form.Field>
              </Form.Item>
            </Form.Row>
          </Form.Section>
          <Form.Section>
            <Form.Row>
              <Form.Title>パスワード</Form.Title>
              <Form.Item>
                <Form.Field>
                  <Txt>ご登録いただいたパスワード</Txt>
                </Form.Field>
              </Form.Item>
            </Form.Row>
          </Form.Section>
        </SettingsSection>
      </Page.Body>
      <Page.Bottom>
        <Page.BottomButtons>
          <BackButton
            comlinkPushParams={{
              action: 'click_back_to_register_organization_form_button',
            }}
            onClick={onBackStep}
          >
            入力画面に戻る
          </BackButton>
          <CompleteButton
            comlinkPushParams={{ action: 'click_register_button' }}
            onClick={onComplete}
          >
            登録
          </CompleteButton>
        </Page.BottomButtons>
      </Page.Bottom>
    </Page.Wrapper>
  )
}

const Txt = styled(_Txt).attrs({ size: 'm' })``

const BackButton = styled(TertiaryButton).attrs({ widthSize: 'L1' })``

const CompleteButton = styled(PrimaryButton).attrs({ widthSize: 'L1' })``
