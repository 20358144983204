export const INTERNAL_PATHS = {
  registerOrganization: '/register-organization',
  settings: {
    index: '/settings',
    organization: '/settings/organization',
    staffs: {
      index: '/settings/staffs',
      registerSelect: '/settings/staffs/register',
      registerByForm: '/settings/staffs/register/form',
      registerByFile: '/settings/staffs/register/upload',
      detail: '/settings/staffs/:guid',
      edit: '/settings/staffs/:guid/edit',
    },
    ipfilters: {
      index: '/settings/ipfilters',
      new: '/settings/ipfilters/new',
      detail: '/settings/ipfilters/:guid',
      edit: '/settings/ipfilters/:guid/edit',
    },
  },
  staffProfile: '/staff-profile',
  editPassword: '/edit-password',
  inviteMail: '/invite-mail/:token',
  notFound: '/not-found',
  activateStaff: '/staff-activation',
  apiSettings: {
    index: '/api-settings',
    detailV3: '/api-settings/:guid/v3',
    editV3: '/api-settings/:guid/v3/edit',
    detailV2: '/api-settings/:guid/v2',
    editV2: '/api-settings/:guid/v2/edit',
  },
}
