import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetIPFilterRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetIpFilter() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetIpFilter = useCallback(
    async (guid: string) => {
      const metadata = await buildMetadata()
      const req = new GetIPFilterRequest()
      req.setGuid(guid)

      return callBizAnakinBffService(
        'getIPFilter',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestGetIpFilter }
}
