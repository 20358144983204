import { useCallback, useEffect, useMemo, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { toast } from '@blue-agency/rogue'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { assertIsDefined } from '@/assertions'
import {
  UpdateIpFilterRequestValue,
  useRequestUpdateIpFilter,
} from '@/services/bffService/useRequestUpdateIpFilter'
import {
  scopes,
  ScopeSpecifiedStaff,
  scopeTypeNameOf,
} from '@/services/ipFilterService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useIpFilter } from '../SettingIpFilterDetailPage/useIpFilter'
import { validationSchema } from './formSchema'

type Step = 'input' | 'confirm'

type FormScopeSpecifiedStaff = Omit<ScopeSpecifiedStaff, 'name'> & {
  fullName: string
}

export type Form = Omit<
  UpdateIpFilterRequestValue,
  'guid' | 'scopeSpecifiedStaffGuids'
> & {
  scopeSpecifiedStaffs: FormScopeSpecifiedStaff[]
}

export const scopeOptions = scopes.map((v) => ({
  label: scopeTypeNameOf(v),
  value: v,
}))

export const subnetMaskOptions = Array.from(Array(32).keys())
  .reverse()
  .map((v) => ({
    label: '/' + (v + 1),
    value: v + 1,
  }))

const useSettingIpFilterEditPage = () => {
  const { guid } = useParams<{ guid: string }>()
  const { isLoading, ipFilter } = useIpFilter(guid)

  const detailPagePath = useMemo(
    () => generatePath(INTERNAL_PATHS.settings.ipfilters.detail, { guid }),
    [guid]
  )

  const [step, setStep] = useState<Step>('input')
  const history = useHistory()

  const [completedForm, setCompletedForm] = useState<Form>()

  const {
    register,
    control,
    watch,
    reset,
    clearErrors,
    handleSubmit,
    formState,
  } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: completedForm,
  })

  const watchScopeType = watch('scopeType')
  const displayScopeUsers = watchScopeType === 'specified_staffs'

  const scopeSpecifiedStaffGuidFieldArray = useFieldArray({
    control,
    name: 'scopeSpecifiedStaffs',
  })
  const onAddScopeSpecifiedStaffGuid = useCallback(
    (guid: string, fullName: string) => {
      scopeSpecifiedStaffGuidFieldArray.append(
        { guid, fullName },
        { shouldFocus: false }
      )
      clearErrors('scopeSpecifiedStaffs')
    },
    [scopeSpecifiedStaffGuidFieldArray, clearErrors]
  )
  const onDeleteScopeSpecifiedStaffGuid = useCallback(
    (index: number) => scopeSpecifiedStaffGuidFieldArray.remove(index),
    [scopeSpecifiedStaffGuidFieldArray]
  )

  const cidrFieldArray = useFieldArray({ control, name: 'cidrs' })
  const onAddCidrInput = useCallback(() => {
    cidrFieldArray.append(
      { ipAddress: '', subnetMask: 32 },
      { shouldFocus: false }
    )
  }, [cidrFieldArray])

  const onDeleteCidrInput = useCallback(
    (index: number) => {
      index && cidrFieldArray.remove(index)
    },
    [cidrFieldArray]
  )

  useEffect(() => {
    if (isLoading) return
    assertIsDefined(ipFilter)

    const filter = {
      name: ipFilter.name,
      scopeType: ipFilter.scopeType,
      scopeSpecifiedStaffs: ipFilter.scopeSpecifiedStaffs.map((filter) => ({
        guid: filter.guid,
        fullName: filter.name.fullName,
      })),
      cidrs: ipFilter.cidrs,
    }
    reset(filter)
  }, [isLoading, ipFilter, reset])

  const onCancelInput = useCallback(() => {
    history.push(detailPagePath)
    return
  }, [history, detailPagePath])

  const onConfirm = handleSubmit((data) => {
    setCompletedForm(data)
    setStep('confirm')
  })

  const onBackToInput = () => {
    reset(completedForm)
    setStep('input')
  }

  const { mutateAsync: mutateIpFilter } = useUpdateIpFilterMutation(guid)
  const onSubmit = useCallback(async () => {
    if (!completedForm) return
    await mutateIpFilter({
      ...completedForm,
      guid: ipFilter?.guid ?? '',
      scopeSpecifiedStaffGuids: completedForm.scopeSpecifiedStaffs?.map(
        (staff) => staff.guid
      ),
    })
    toast('フィルター情報を保存しました')
    history.push(detailPagePath)
  }, [history, completedForm, mutateIpFilter, ipFilter?.guid, detailPagePath])

  return {
    isLoading,
    ipFilter,
    step,
    completedForm,

    register,
    control,
    errors: formState.errors,

    // 対象範囲:ユーザー指定
    displayScopeUsers,
    scopeSpecifiedStaffGuidFields: scopeSpecifiedStaffGuidFieldArray.fields,
    onAddScopeSpecifiedStaffGuid,
    onDeleteScopeSpecifiedStaffGuid,

    // IPアドレス
    cidrFields: cidrFieldArray.fields,
    onAddCidrInput,
    onDeleteCidrInput,

    onCancelInput,
    onConfirm,
    onBackToInput,
    onSubmit,
  }
}

export const SettingIpFilterEditPageContainer = createContainer(
  useSettingIpFilterEditPage
)

function useUpdateIpFilterMutation(guid: string) {
  const queryClient = useQueryClient()
  const { requestUpdateIpFilter } = useRequestUpdateIpFilter()

  return useMutation(requestUpdateIpFilter, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.listIpFilters)
      queryClient.invalidateQueries([QUERY_KEY.getIpFilter, guid])
    },
  })
}
