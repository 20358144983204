import React, { useCallback, useEffect, useMemo } from 'react'
import {
  FixedBottomFormPage as Page,
  getInternalUrls,
} from '@blue-agency/im-shared-front'
import { Dropdown, theme, Txt, A } from '@blue-agency/rogue'
import { InputText, PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import { Form } from '@/components/Form'
import { SettingsSection } from '@/components/SettingsSection'
import { AtsConnect, FormType } from '.'
import * as C from './components'

type Props = {
  currentAtsConnect?: AtsConnect
  guideList: { name: string; guid: string }[]
  onCancel: () => void
  onSubmit: (form: FormType) => void
}

const validationSchema: yup.SchemaOf<FormType> = yup.object().shape({
  name: yup
    .string()
    .required('入力してください')
    .max(30, '30文字以内で入力してください'),
  note: yup.string().max(30, '30文字以内で入力してください'),
  defaultWebInterviewGuideGuid: yup.string(),
})

export const Input: React.VFC<Props> = (props) => {
  const { currentAtsConnect, guideList } = props

  const { trigger, getValues, register, formState, reset, control } =
    useForm<FormType>({
      resolver: yupResolver(validationSchema),
      mode: 'onBlur',
      defaultValues: {
        name: '',
      },
    })

  const confirm = useCallback(async () => {
    const valid = await trigger()
    if (!valid) return
    props.onSubmit(getValues())
  }, [props, trigger, getValues])

  const guideOptions = useMemo(() => {
    const guides = guideList.map((g) => ({
      label: g.name,
      value: g.guid,
    }))

    if (currentAtsConnect?.defaultWebInterviewGuide === undefined) {
      return guides
    }

    const { guid, name } = currentAtsConnect.defaultWebInterviewGuide

    // 現在紐付いているガイドがアーカイブ済みの場合、`guides` にはそのアーカイブ済みのものが含まれない
    // その場合はここで追加して、選択肢上には出現するようにする
    if (guides.every((g) => g.value !== guid)) {
      guides.push({
        label: name,
        value: guid,
      })

      guides.sort((a, b) => {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
      })
    }

    return guides
  }, [guideList, currentAtsConnect])

  useEffect(() => {
    if (!currentAtsConnect) return

    reset({
      name: currentAtsConnect?.name,
      note: currentAtsConnect?.note,
      defaultWebInterviewGuideGuid:
        currentAtsConnect?.defaultWebInterviewGuide?.guid,
    })
  }, [currentAtsConnect, reset])

  return (
    <>
      <Page.Body>
        <SettingsSection title="連携設定情報">
          <Form.Section>
            <C.Row>
              <C.RowTitle>連携先</C.RowTitle>
              <InputText
                {...register('name')}
                onBlurError={formState.errors.name?.message}
                size="ll"
              />
            </C.Row>
            <C.Separator />
            <C.Row>
              <C.RowTitle>メモ</C.RowTitle>
              <InputText
                {...register('note')}
                onBlurError={formState.errors.note?.message}
                size="ll"
              />
            </C.Row>
            <C.Separator />
            <C.Row>
              <C.RowTitle>面接ガイド</C.RowTitle>
              <Dropdown
                {...register('defaultWebInterviewGuideGuid')}
                options={guideOptions}
                size="ll"
                allowEmpty
                placeholder="未設定"
              />
            </C.Row>
            <C.Row>
              <C.RowTitle />
              <LinkToGuideCreationWrapper>
                <Txt>
                  <LinkWithInternal
                    comlinkPushParams={{
                      action:
                        'click_new_web_interview_guide_link_on_api_settings_detail_v2_edit_page',
                    }}
                    href={getInternalUrls().newWebInterviewGuide}
                  >
                    新しいガイドの追加はこちら
                  </LinkWithInternal>
                </Txt>
              </LinkToGuideCreationWrapper>
            </C.Row>
          </Form.Section>
        </SettingsSection>
      </Page.Body>
      <Page.Bottom>
        <Page.BottomButtons>
          <TertiaryButton
            onClick={props.onCancel}
            comlinkPushParams={{
              action: 'click_cancel_button_on_api_settings_v2_edit_page',
            }}
            widthSize="L2"
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            onClick={confirm}
            comlinkPushParams={{
              action: 'click_confirm_button_on_api_settings_v2_edit_page',
            }}
            widthSize="L2"
          >
            内容を確認
          </PrimaryButton>
        </Page.BottomButtons>
      </Page.Bottom>
      <DevTool control={control} placement="bottom-right" />
    </>
  )
}

const LinkToGuideCreationWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding-left: 300px;
`

const LinkWithInternal = styled(A)`
  color: ${theme.color.blue[2]};
  text-decoration: none;
`
