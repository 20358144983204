import { useMemo, useEffect, useCallback, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { administrator, commonErrorToast } from '@blue-agency/im-shared-front'
import { alertToast, toast } from '@blue-agency/rogue'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { validationScheme } from '@/pages/SettingStaffEditPage/formSchema'
import {
  CustomBizAnakinBffGrpcError,
  UpdateStaffRequestValue,
  useRequestUpdateStaff,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useStaff } from '../SettingStaffDetailPage/useStaff'
import { useStep } from './hooks/useStep'

export type Form = Omit<UpdateStaffRequestValue, 'guid'>

const useSettingStaffEditPage = () => {
  const history = useHistory()
  const { guid } = useParams<{ guid: string }>()
  const pathToStaffDetailPage = useMemo(
    () => generatePath(INTERNAL_PATHS.settings.staffs.detail, { guid: guid }),
    [guid]
  )

  const { content, toInputStep: _toInputStep, toConfirmStep } = useStep()

  const [completedForm, setCompletedForm] = useState<Form>({
    familyName: '',
    givenName: '',
    familyNameKana: '',
    givenNameKana: '',
    authzType: administrator.type,
  })

  const { register, handleSubmit, formState, reset } = useForm<Form>({
    resolver: yupResolver(validationScheme),
    mode: 'onBlur',
    defaultValues: completedForm,
  })

  const { isLoading, staff } = useStaff(guid)
  useEffect(() => {
    // NOTE: ここはisLoadingとstaff両方見る必要がある
    // refs: https://github.com/blue-agency/biz-anakin-front/pull/474#discussion_r553730840
    if (isLoading || !staff) return
    reset({
      familyName: staff.name.familyName,
      givenName: staff.name.givenName,
      familyNameKana: staff.nameKana.familyName,
      givenNameKana: staff.nameKana.givenName,
      authzType: staff.authzType.type,
    })
  }, [isLoading, staff, reset, guid])

  const onSubmit = handleSubmit((data) => {
    setCompletedForm(data)
    toConfirmStep()
  })

  const toInputStep = useCallback(() => {
    reset(completedForm)
    _toInputStep()
  }, [reset, _toInputStep, completedForm])

  const { mutateAsync: mutateStaff } = useUpdateStaffMutation(guid)
  const updateStaff = useCallback(async () => {
    try {
      await mutateStaff({ ...completedForm, guid })
      toast('ユーザー情報を保存しました')
      history.push(pathToStaffDetailPage)
    } catch (e) {
      if (e instanceof CustomBizAnakinBffGrpcError && e.isFailedPrecondition) {
        alertToast(
          '処理に失敗しました。\nブラウザを再読込して、もう一度お試しください。'
        )
        return
      }
      Sentry.captureException(e)
      commonErrorToast()
      return
    }
  }, [mutateStaff, completedForm, guid, history, pathToStaffDetailPage])

  return {
    isLoading,
    staff,
    updateStaff,
    register,
    completedForm,
    onSubmit,
    errors: formState.errors,
    content,
    toInputStep,
    pathToStaffDetailPage,
  }
}

export const SettingStaffEditPageContainer = createContainer(
  useSettingStaffEditPage
)

function useUpdateStaffMutation(guid: string) {
  const queryClient = useQueryClient()
  const { requestUpdateStaff } = useRequestUpdateStaff()

  return useMutation(requestUpdateStaff, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.listStaffs)
      queryClient.invalidateQueries([QUERY_KEY.getStaff, guid])
    },
  })
}
