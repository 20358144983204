import { useCallback } from 'react'
import {
  AuthzType,
  authzTypeToProto,
  useLoggedInStaff,
} from '@blue-agency/im-shared-front'
import { RegisterStaffRequest } from '@blue-agency/proton/biz_anakin_bff'
import { Name, NameKana } from '@blue-agency/proton/web/v2/name_data_pb'
import type { AuthnType } from '@/services/authnService'
import { authnTypeToProto } from './authnType'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export type RegisterStaffRequestValue = {
  familyName: string
  givenName: string
  familyNameKana: string
  givenNameKana: string
  email: string
  authzType: AuthzType['type']
  authnType: AuthnType['type']
}

export function useRequestRegisterStaff() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestRegisterStaff = useCallback(
    async (staff: RegisterStaffRequestValue) => {
      const name = new Name()
      name.setFamilyName(staff.familyName)
      name.setGivenName(staff.givenName)

      const nameKana = new NameKana()
      nameKana.setFamilyNameKana(staff.familyNameKana)
      nameKana.setGivenNameKana(staff.givenNameKana)

      const metadata = await buildMetadata()
      const req = new RegisterStaffRequest()

      req.setEmail(staff.email)
      req.setName(name)
      req.setNameKana(nameKana)
      req.setAuthzType(authzTypeToProto(staff.authzType))
      req.setAuthnType(authnTypeToProto(staff.authnType))

      return callBizAnakinBffService(
        'registerStaff',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestRegisterStaff }
}
