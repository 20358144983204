import { Link as _Link } from 'react-router-dom'
import styled from 'styled-components'

export const Link = styled(_Link)`
  text-decoration: none;
`

export const TextLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`
