import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListAtsConnectsRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestListAtsConnects() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListAtsConnects = useCallback(async () => {
    const metadata = await buildMetadata()
    const req = new ListAtsConnectsRequest()

    return callBizAnakinBffService(
      'listAtsConnects',
      [req, metadata],
      loggedInStaff?.guid
    )
  }, [buildMetadata, loggedInStaff])

  return { requestListAtsConnects }
}
