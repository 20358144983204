import React from 'react'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { H1 } from '@/components/Heading'

type ListPageHeaderProps = {
  title?: React.ReactNode
  right?: React.ReactNode
}

export const ListPageHeader = (
  props: PropsWithClassName<ListPageHeaderProps>
): React.ReactElement => <PageHeader {...props} maxWidth="100%" />

type PageHeaderProps = ListPageHeaderProps & {
  maxWidth?: string
}

export const PageHeader = (
  props: PropsWithClassName<PageHeaderProps>
): React.ReactElement => {
  return (
    <HeaderWrapper>
      <Header className={props.className} maxWidth={props.maxWidth ?? '1160px'}>
        <H1>{props.title}</H1>
        <Right>{props.right}</Right>
      </Header>
    </HeaderWrapper>
  )
}

export const pageHeaderHeight = 60

const Right = styled.div`
  display: flex;
  align-items: center;
`

const HeaderWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  background-color: ${theme.color.white[1]};
`

type HeaderProps = { maxWidth: string }
const Header = styled.header<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  height: ${pageHeaderHeight}px;
  ${(props) => `max-width: ${props.maxWidth};`}
`
