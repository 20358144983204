import { useQuery } from 'react-query'
import { useRequestGetAtsConnectV3 } from '@/services/bffService/useRequestGetAtsConnectV3'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useGetAtsConnectV3(guid: string) {
  const { requestGetAtsConnectV3 } = useRequestGetAtsConnectV3()
  return useQuery([QUERY_KEY.atsConnectV3, guid], () =>
    requestGetAtsConnectV3(guid)
  )
}
