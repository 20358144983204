import React, { useCallback, useState, useMemo } from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { SharingType } from '@blue-agency/proton/web/v2/im'
import { theme, toast } from '@blue-agency/rogue'
import { Icon, NewTabLink, Txt } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Loading } from '@/components/Loading'
import { EXTERNAL_PATHS, INTERNAL_PATHS } from '@/services/urlService'
import { useListWebInterviewGuides } from '../APISettingsDetailV2EditPage/useListWebInterviewGuidesForLink'
import { useGetAtsConnectV3 } from '../APISettingsDetailV3Page/useGetAtsConnectV3'
import { Confirm } from './Confirm'
import { Input } from './Input'
import { useListSelectionStepMasters } from './useSelectionStepMastersList'
import { useUpdateAtsConnectV3 } from './useUpdateAtsConnectV3'

type Step = 'input' | 'confirm'
export type MailFlag = '送信する' | '送信しない'
export type SharingTypeValue = 'unshared' | 'all'
export type FormType = {
  name: string
  note?: string
  recInterviewSetting: {
    selectionStepMasterGUID?: string
    requestMail: MailFlag
  }
  applicantSetting: {
    sharingType: SharingTypeValue
  }
  webInterviewSetting: {
    defaultWebInterviewGuideGUID?: string
  }
}
export type Permission = {
  webInterviewEnabled: boolean
  recInterviewEnabled: boolean
  videoSeminarEnabled: boolean
}

export const APISettingsDetailV3EditPage: React.VFC = () => {
  const { guid } = useParams<{ guid: string }>()
  const history = useHistory()

  const [step, setStep] = useState<Step>('input')

  const getAtsConnectV3Result = useGetAtsConnectV3(guid)
  const { mutateAsync: updateAtsConnectV3 } = useUpdateAtsConnectV3()

  const atsConnect = useMemo(() => {
    const atsConnectV3 = getAtsConnectV3Result.data?.toObject()
    const requestMail = atsConnectV3?.recInterviewSetting?.requestMail ?? false
    const mailFalg: MailFlag = requestMail ? '送信する' : '送信しない'
    const sharingType: SharingTypeValue =
      atsConnectV3?.applicantSetting?.sharingType ===
      SharingType.SHARING_TYPE_UNSHARED
        ? 'unshared'
        : 'all'
    return {
      name: atsConnectV3?.name ?? '',
      note: atsConnectV3?.note,
      recInterviewSetting: {
        selectionStepMasterGUID:
          atsConnectV3?.recInterviewSetting?.selectionStepMasterGuid,
        requestMail: mailFalg,
      },
      applicantSetting: {
        sharingType: sharingType,
      },
      webInterviewSetting: {
        defaultWebInterviewGuideGUID:
          atsConnectV3?.webInterviewSetting?.defaultWebInterviewGuideGuid,
      },
    }
  }, [getAtsConnectV3Result.data])

  const permission = useMemo(() => {
    const atsConnectV3 = getAtsConnectV3Result.data?.toObject()
    return {
      webInterviewEnabled:
        atsConnectV3?.permission?.webInterviewEnabled ?? false,
      recInterviewEnabled:
        atsConnectV3?.permission?.recInterviewEnabled ?? false,
      videoSeminarEnabled:
        atsConnectV3?.permission?.videoSeminarEnabled ?? false,
    }
  }, [getAtsConnectV3Result.data])

  const [form, setForm] = useState<FormType | undefined>()

  const backToDetailPage = useCallback(() => {
    history.push(generatePath(INTERNAL_PATHS.apiSettings.detailV3, { guid }))
  }, [history, guid])

  const confirm = useCallback((form: FormType) => {
    setForm(form)
    setStep('confirm')
  }, [])

  const backToInput = useCallback(() => {
    setStep('input')
  }, [])

  const save = useCallback(async () => {
    try {
      if (form === undefined) throw new Error('form is undefined')
      await updateAtsConnectV3({
        guid,
        name: form.name ?? '',
        note: form.note ?? '',
        recInterviewSetting: {
          selectionStepMasterGUID:
            form.recInterviewSetting.selectionStepMasterGUID ?? '',
          requestMail:
            form.recInterviewSetting.requestMail === '送信する' ? true : false,
        },
        applicantSetting: {
          sharingType: form.applicantSetting.sharingType,
        },
        webInterviewSetting: {
          defaultWebInterviewGuideGUID:
            form.webInterviewSetting.defaultWebInterviewGuideGUID ?? '',
        },
      })
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }
    toast('連携設定情報を保存しました')
    backToDetailPage()
  }, [backToDetailPage, updateAtsConnectV3, form, guid])

  const listSelectionStepMastersResult = useListSelectionStepMasters()
  const listWebInterviewGuidesResult = useListWebInterviewGuides()

  const isLoading =
    getAtsConnectV3Result.isLoading ||
    listSelectionStepMastersResult.isLoading ||
    listWebInterviewGuidesResult.isLoading

  const guideOptions = useMemo(() => {
    const guides = listWebInterviewGuidesResult.guideList.map((g) => ({
      label: g.name,
      value: g.guid,
    }))

    const defaultWebInterviewGuideGUID = getAtsConnectV3Result.data
      ?.getWebInterviewSetting()
      ?.getDefaultWebInterviewGuideGuid()
    const defaultWebInterviewGuideName = getAtsConnectV3Result.data
      ?.getWebInterviewSetting()
      ?.getDefaultWebInterviewGuideName()
    if (!defaultWebInterviewGuideGUID || !defaultWebInterviewGuideName) {
      return guides
    }

    // 現在紐付いているガイドがアーカイブ済みの場合、`guides` にはそのアーカイブ済みのものが含まれない
    // その場合はここで追加して、選択肢上には出現するようにする
    if (guides.every((g) => g.value !== defaultWebInterviewGuideGUID)) {
      guides.push({
        label: defaultWebInterviewGuideName,
        value: defaultWebInterviewGuideGUID,
      })

      guides.sort((a, b) => {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
      })
    }

    return guides
  }, [listWebInterviewGuidesResult, getAtsConnectV3Result])

  if (isLoading) {
    return <Loading />
  }

  const content = (() => {
    switch (step) {
      case 'input':
        return (
          <Input
            initialAtsConnect={atsConnect}
            form={form}
            onSubmit={confirm}
            onCancel={backToDetailPage}
            selectionStepMasters={
              listSelectionStepMastersResult.selectionStepMasters
            }
            guideOptions={guideOptions}
            permission={permission}
          />
        )
      case 'confirm':
        if (form === undefined) throw new Error('form is undefined')
        return (
          <Confirm
            form={form}
            onSubmit={save}
            onBackToInput={backToInput}
            selectionStepMasters={
              listSelectionStepMastersResult.selectionStepMasters
            }
            guideOptions={guideOptions}
            permission={permission}
          />
        )
    }
  })()

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>API連携設定編集</Page.HeaderTitle>
          <HelpMessage>
            <QuestionIcon />
            各項目の詳細は
            <NewTabLink
              href={EXTERNAL_PATHS.help.atsConnect}
              action="open_help_page_on_ats_connect_v3_edit"
            >
              ヘルプページ
            </NewTabLink>
            をご確認ください
          </HelpMessage>
        </Page.Header>
        {content}
      </Page.Wrapper>
    </PageLayout>
  )
}

const HelpMessage = styled(Txt)`
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: 32px;
`

const QuestionIcon = styled(Icon).attrs({ name: 'question', size: 'l' })`
  margin-right: 5px;
`
