import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateStaffAuthnTypeRequest } from '@blue-agency/proton/web/v2/biz_anakin_bff'
import type { AuthnType } from '@/services/authnService'
import { authnTypeToProto } from './authnType'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateStaffAuthnTypeRequestValue = {
  guid: string
  authnType: AuthnType['type']
}

export const useRequestUpdateStaffAuthnType = () => {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateStaffAuthnType = useCallback(
    async (params: UpdateStaffAuthnTypeRequestValue) => {
      const req = new UpdateStaffAuthnTypeRequest()
      req.setGuid(params.guid)
      req.setAuthnType(authnTypeToProto(params.authnType))

      const metadata = await buildMetadata()

      return callBizAnakinBffService(
        'updateStaffAuthnType',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestUpdateStaffAuthnType }
}
