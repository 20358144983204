import React from 'react'
import { getInternalUrls } from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { SettingsSection } from '@/components/SettingsSection'
import { INTERNAL_PATHS } from '@/services/urlService'
import { SettingsCard as _SettingsCard } from './SettingsCard'

export const Content = (props: PropsWithClassName): React.ReactElement => {
  return (
    <Wrapper className={props.className}>
      <SettingsSection title="基本設定">
        <SectionInner>
          <SettingsCard
            title="ユーザー設定"
            href={INTERNAL_PATHS.settings.staffs.index}
          />
          <SettingsCard
            title="IPアドレス制限設定"
            href={INTERNAL_PATHS.settings.ipfilters.index}
          />
          <SettingsCard
            title="企業情報設定"
            href={INTERNAL_PATHS.settings.organization}
          />
          <SettingsCard
            title="API連携設定"
            href={INTERNAL_PATHS.apiSettings.index}
          />
        </SectionInner>
      </SettingsSection>
      <SettingsSection title="Web面接設定">
        <SectionInner>
          <SettingsCard
            title="面接ガイド設定"
            href={getInternalUrls().webInterviewGuides}
            isExternalLink
          />
        </SectionInner>
      </SettingsSection>
      <SettingsSection title="録画面接">
        <SectionInner>
          <SettingsCard
            title="録画面接テンプレート"
            href={getInternalUrls().recInterviews.templates}
            isExternalLink
          />
          <SettingsCard
            title="設問"
            href={getInternalUrls().recInterviews.questionTemplates}
            isExternalLink
          />
        </SectionInner>
      </SettingsSection>
      <SettingsSection title="選考">
        <SectionInner>
          <SettingsCard
            title="選考ステップ"
            href={getInternalUrls().selectionStepMasters}
            isExternalLink
          />
        </SectionInner>
      </SettingsSection>
      <SettingsSection title="ビデオセミナー">
        <SectionInner>
          <SettingsCard
            title="ビデオセミナー"
            href={getInternalUrls().videoSeminars}
            isExternalLink
          />
        </SectionInner>
      </SettingsSection>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${theme.color.white[1]};
  height: 100%;
  width: 960px;
`

const SectionInner = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-right: -20px;
  margin-bottom: -20px;
  padding: 20px;
`

const SettingsCard = styled(_SettingsCard)`
  width: 290px;
  margin-right: 20px;
  margin-bottom: 20px;
`
