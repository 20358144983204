import { useMemo } from 'react'
import { authzTypeFromProto } from '@blue-agency/im-shared-front'
import { useQuery } from 'react-query'
import { useRequestGetStaff } from '@/services/bffService'
import { authnTypeFromProto } from '@/services/bffService/authnType'
import { QUERY_KEY } from '@/services/queryKeyService'
import { Staff } from '@/services/staffService'
import { nameOf, nameKanaOf } from '@/services/staffService/name'
import { Status } from '@/services/staffService/status'

export const useStaff = (guid: string) => {
  const { isLoading, data } = useGetStaffQuery(guid)

  const staff = useMemo<Staff | undefined>(() => {
    if (!data) return

    const st = data.getStaff()
    return {
      guid: guid,
      name: nameOf(st?.getName()),
      nameKana: nameKanaOf(st?.getNameKana()),
      status: Status.ofGetStaffsResponse(st?.getStatus()),
      suspended: st?.getSuspended() ?? false,
      email: st?.getEmail() || '',
      authzType: authzTypeFromProto(st?.getAuthzType()),
      isRegistrationExpired: st?.getIsRegistrationExpired() ?? false,
      authnType: authnTypeFromProto(st?.getAuthnType()),

      // NOTE: 以下は`services/staffService/Staff`が必要としているが、GetStaffはそれを返さないのでダミー値として入れている
      registeredAt: new Date(),
      iconColorCode: '',
    }
  }, [data, guid])

  return {
    isLoading,
    staff,
  }
}

function useGetStaffQuery(guid: string) {
  const { requestGetStaff } = useRequestGetStaff()

  return useQuery([QUERY_KEY.getStaff, guid], () => requestGetStaff(guid))
}
