import * as v2_name_data_pb from '@blue-agency/proton/web/v2/name_data_pb'

export class Name {
  familyName: string
  givenName: string
  _fullName: string | null

  constructor(familyName: string, givenName: string) {
    this.familyName = familyName
    this.givenName = givenName
    this._fullName = null
  }

  get fullName() {
    if (this._fullName) return this._fullName
    this._fullName = this.familyName + ' ' + this.givenName
    return this._fullName
  }

  includes(q: string): boolean {
    const normalizedQuery = q.trim().replace(/[ 　]/g, '')
    return (this.familyName + this.givenName).includes(normalizedQuery)
  }

  valueOf() {
    return this.fullName
  }
}

export class NameKana {
  familyName: string
  givenName: string
  _fullName: string | null

  constructor(familyName: string, givenName: string) {
    this.familyName = familyName
    this.givenName = givenName
    this._fullName = null
  }

  get fullName(): string {
    if (this._fullName) return this._fullName
    this._fullName = this.familyName + ' ' + this.givenName
    return this._fullName
  }

  includes(q: string): boolean {
    const normalizedQuery = q.trim().replace(/[ 　]/g, '')
    return (this.familyName + this.givenName).includes(normalizedQuery)
  }

  valueOf() {
    return this.fullName
  }
}

export function nameOf(pbName?: v2_name_data_pb.Name): Name {
  if (pbName === undefined) throw new Error('name is undefined')
  return new Name(pbName.getFamilyName(), pbName.getGivenName())
}

export function nameKanaOf(pbNameKana?: v2_name_data_pb.NameKana): NameKana {
  if (pbNameKana === undefined) throw new Error('nameKana is undefined')
  return new NameKana(
    pbNameKana.getFamilyNameKana(),
    pbNameKana.getGivenNameKana()
  )
}
