import React, { useCallback, useState } from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import {
  ErrorBox,
  Icon,
  PrimaryLinkTxt,
  theme,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import * as Sentry from '@sentry/react'
import Encoding from 'encoding-japanese'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { Link } from '@/components/Link'
import { SettingsSection } from '@/components/SettingsSection'
import { INTERNAL_PATHS } from '@/services/urlService'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { ErrorModal } from '../ErrorModal'
import { StaffDraft } from '../StaffDraft'
import {
  DetailValidationError,
  validateDetail,
  validatePrecondition,
  validCsvHeader,
} from './csv'

type Props = {
  goNext?: (staffDrafts: StaffDraft[]) => void
}

type InputData = {
  fileName: string
  staffDrafts: StaffDraft[]
}

export const Input: React.VFC<Props> = ({ goNext }) => {
  const [inputData, setInputData] = useState<InputData>()
  const [errorMessageOnTop, setErrorMessageOnTop] = useState<string>()
  const [detailValidationErrors, setDetailValidationErrors] = useState<
    DetailValidationError[]
  >([])

  const downloadTemplateCsv = useCallback(() => {
    const content = validCsvHeader.join(',') + '\n'

    const shiftJISContent = Encoding.convert(content, {
      type: 'arraybuffer',
      from: 'UNICODE',
      to: 'SJIS',
    })

    const downloadEl = document.createElement('a')
    downloadEl.download = 'interview_maker_staffs.csv'
    downloadEl.href = URL.createObjectURL(
      new Blob([new Uint8Array(shiftJISContent)], { type: 'text/csv' })
    )
    downloadEl.click()
  }, [])

  const onDrop = useCallback(
    async (files: File[]) => {
      // prettier-ignore
      if (inputData && !window.confirm('既に選択されている取込ファイルが存在します。\n上書きしてよろしいでしょうか？')) {
        return
      }

      setErrorMessageOnTop(undefined)
      setInputData(undefined)
      try {
        const result = await validatePrecondition(files)

        if (result.type === 'error') {
          setErrorMessageOnTop(result.message)
          return
        }

        const detailResult = await validateDetail(result.body, {
          errorLimit: 10,
        })

        if (detailResult.type === 'error') {
          setDetailValidationErrors(detailResult.errors)
          return
        }
        setInputData({
          fileName: result.fileName,
          staffDrafts: detailResult.staffDrafts,
        })
      } catch (e) {
        Sentry.captureException(e)
        setErrorMessageOnTop(
          'ファイルの取り込みに失敗しました。ファイルの内容を確認して、再度お試しください。'
        )
        return
      }
    },
    [inputData]
  )

  const onDetailValidationErrorModalClose = useCallback(() => {
    setDetailValidationErrors([])
  }, [])

  const isShownDetailValidationErrorModal = detailValidationErrors.length >= 1

  const dropzone = useDropzone({ multiple: false, onDrop })

  const onNextButtonClick = useCallback(() => {
    if (goNext === undefined) throw new Error('goNext is undefined')
    if (inputData === undefined) throw new Error('inputData is undefined')

    goNext(inputData.staffDrafts)
  }, [goNext, inputData])

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>ユーザー一括登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          {errorMessageOnTop && (
            <ErrorBoxWrapper>
              <ErrorBox size="s">{errorMessageOnTop}</ErrorBox>
            </ErrorBoxWrapper>
          )}
          <SettingsSection title="取込ファイル設定">
            <SectionInner>
              <Txt>
                新規登録したいユーザー情報を記載したファイルをアップロードしてください。
                <br />
                登録用のCSVフォーマットのダウンロードは
                <NoStyledButton type="button" onClick={downloadTemplateCsv}>
                  <PrimaryLinkTxt>こちら</PrimaryLinkTxt>
                </NoStyledButton>
              </Txt>
              <SeparatorWrapper>
                <WeakColorSeparator />
              </SeparatorWrapper>

              <FileSelectWrapper>
                <Txt>ファイル選択</Txt>
                <FileSelectAreaWrapper>
                  {inputData && (
                    <FileNameWrapper>
                      <Txt>{inputData.fileName}</Txt>
                    </FileNameWrapper>
                  )}
                  {/* 解消しづらいエラー
                    https://github.com/blue-agency/biz-anakin-front/pull/680#discussion_r593085142
                    @ts-ignore */}
                  <FileSelectArea {...dropzone.getRootProps()}>
                    {/* 解消しづらいエラー
                    https://github.com/blue-agency/biz-anakin-front/pull/680#discussion_r593085142
                    @ts-ignore */}
                    <input {...dropzone.getInputProps()} />
                    <FileSelectTxtWrapper>
                      <Icon name="add-file" width="24" />
                      <Txt>ファイルをドラッグ＆ドロップ</Txt>
                    </FileSelectTxtWrapper>
                    <TertiaryButton
                      widthSize="L1"
                      comlinkPushParams={{ action: 'click_upload_csv_button' }}
                    >
                      ファイル選択
                    </TertiaryButton>
                  </FileSelectArea>
                </FileSelectAreaWrapper>
              </FileSelectWrapper>
            </SectionInner>
          </SettingsSection>
          <ErrorModal
            headerMessage={
              <>
                取込エラーが発生しました。
                <br />
                エラー内容をご確認の上、ファイルを修正して再度アップロードしてください。
              </>
            }
            active={isShownDetailValidationErrorModal}
            errorLimit={10}
            errorMessages={detailValidationErrors}
            onClose={onDetailValidationErrorModalClose}
            onBackToInput={onDetailValidationErrorModalClose}
          />
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <NoStyledLink to={INTERNAL_PATHS.settings.staffs.registerSelect}>
              <TertiaryButton
                widthSize="L1"
                comlinkPushParams={{
                  action: 'click_back_to_register_select_page',
                }}
              >
                選択画面に戻る
              </TertiaryButton>
            </NoStyledLink>
            <PrimaryButton
              widthSize="L1"
              comlinkPushParams={{
                action: 'click_next_button_on_register_by_file',
              }}
              disabled={inputData === undefined}
              onClick={onNextButtonClick}
            >
              内容を確認
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const ErrorBoxWrapper = styled.div`
  margin-bottom: 20px;
`

const SectionInner = styled.div`
  padding: 24px 20px;
`

const NoStyledButton = styled.button`
  ${buttonResetStyle};
`

const SeparatorWrapper = styled.div`
  margin: 20px 0px;
`

const FileSelectWrapper = styled.div`
  display: flex;
`

const FileSelectAreaWrapper = styled.div`
  margin-left: 140px;
`
const FileNameWrapper = styled.div`
  margin-bottom: 16px;
`

const FileSelectArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 460px;
  height: 76px;

  border-radius: 4px;
  border: 1px dashed ${theme.color.gray[2]};
  cursor: pointer;
`

const FileSelectTxtWrapper = styled.div`
  display: flex;
`

const NoStyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
