import React from 'react'
import { Txt, Icon, ErrorBox as _ErrorBox, theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { ErrorTxt } from '@/components/ErrorTxt'
import backgroundImage from '../background.jpg'

export const InvalidArgumentPage: React.VFC = () => {
  return (
    <Background>
      <Wrapper>
        <Content>
          <Logo />
          <Title>パスワード設定</Title>
          <ErrorBox>
            <ErrorTxt>
              有効期限切れのため、利用開始できません。
              <br />
              社内の人事担当者様にお問い合わせください。
            </ErrorTxt>
          </ErrorBox>
        </Content>
      </Wrapper>
    </Background>
  )
}

const Background = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url('${backgroundImage}');
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
`

const Wrapper = styled.div`
  margin-top: 137px;
  width: 436px;
  min-height: 206px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 26px;
`

const Logo = styled(Icon).attrs({ name: 'logo' })`
  width: 160px;
  height: 66.82px;
  margin: 16px 0 7px;
  color: ${theme.color.black[1]};
`

const Title = styled(Txt).attrs({ size: 'l' })`
  margin-bottom: 17px;
`

const ErrorBox = styled(_ErrorBox)`
  margin-bottom: 20px;
`
