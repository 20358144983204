import { useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  ListStaffsRequestOptions,
  useRequestListStaffs,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { StaffList } from '@/services/staffService'
import { StaffTable } from './useStaffsTable'

export const useStaffList = (table: StaffTable) => {
  const queryOpt = useMemo<ListStaffsRequestOptions>(() => {
    switch (table) {
      case 'staffs':
        return { suspended: false }
      case 'suspendedStaffs':
        return { suspended: true }
    }
  }, [table])

  const { isLoading, data } = useListStaffsQuery(queryOpt)

  const staffList = useMemo(() => {
    const sl = StaffList.ofListStaffsResponseStaff(data?.getStaffsList() ?? [])
    return sl
  }, [data])

  const staffLength = useMemo(() => {
    if (data === undefined) return 0
    return data.getTotalLength()
  }, [data])

  return {
    isLoading,
    staffList,
    staffLength,
  }
}

function useListStaffsQuery(opt: ListStaffsRequestOptions) {
  const { requestListStaffs } = useRequestListStaffs()

  return useQuery([QUERY_KEY.listStaffs, opt], () => requestListStaffs(opt))
}
