import React from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { NewTabLink, Txt } from '@blue-agency/rogue'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { AuthzTypeRadioGroup } from '@/components/AuthzTypeRadioGroup'
import { Form } from '@/components/Form'
import { EXTERNAL_PATHS } from '@/services/urlService'
import { SettingStaffEditPageContainer } from '../../SettingStaffEditPageContainer'

export const AuthzTypeSection: React.VFC<PropsWithClassName> = ({
  className,
}) => {
  const loggedInStaff = useLoggedInStaff()
  const { staff, register } = SettingStaffEditPageContainer.useContainer()

  return (
    <Form.Section className={className}>
      <Form.Row>
        <Form.Title>権限タイプ</Form.Title>
        {!!loggedInStaff && staff?.guid === loggedInStaff.guid ? (
          <Form.Item>
            <Form.Field>
              <Form.Txt>{staff?.authzType.name}</Form.Txt>
              <input
                {...register('authzType')}
                type="hidden"
                value={staff?.authzType.type}
              />
            </Form.Field>
          </Form.Item>
        ) : (
          <Form.Item>
            <Form.Required>必須</Form.Required>
            <Form.Field>
              <AuthzTypeRadioGroup
                {...register('authzType')}
                name="authzType"
                defaultValue={staff?.authzType.type}
              />
            </Form.Field>
            <Form.Helper textAlign="right">
              <NewTabLink
                href={EXTERNAL_PATHS.help.authzType}
                action="open_authzType_help_page"
              >
                <Txt size="s" color="inherit">
                  詳細はこちら
                </Txt>
              </NewTabLink>
            </Form.Helper>
          </Form.Item>
        )}
      </Form.Row>
    </Form.Section>
  )
}
