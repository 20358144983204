import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { SettingsSection } from '@/components/SettingsSection'
import { SettingStaffRegisterByFormPageContainer } from '../SettingStaffRegisterByFormPageContainer'
import { Body } from './Body'
import { Buttons } from './Buttons'

export const Completed: React.VFC = () => {
  const { completedForm } =
    SettingStaffRegisterByFormPageContainer.useContainer()
  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>ユーザー登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="登録完了">
            <Body password={completedForm?.authnType === 'password'} />
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <Buttons />
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}
