import React from 'react'
import { ListAtsConnectsResponse } from '@blue-agency/proton/web/v2/biz_anakin_bff'
import { Txt, theme } from '@blue-agency/rogue'
import styled from 'styled-components'

const Status = ListAtsConnectsResponse.AtsConnect.Status

type Props = {
  status: ListAtsConnectsResponse.AtsConnect.Status
}
export const StatusBadge: React.VFC<Props> = (props) => {
  switch (props.status) {
    case Status.IN_USE:
      return <InUseBadge />
    case Status.SUSPENDED:
      return <SuspendedBadge />
    default:
      return null
  }
}

const InUseBadge = () => <InUse>連携中</InUse>

const SuspendedBadge = () => <Suspended>連携停止中</Suspended>

const Badge = styled(Txt).attrs({
  size: 's',
})`
  height: 20px;
  min-width: 90px;
  padding: auto;
  text-align: center;
  line-height: 18px;
`

const InUse = styled(Badge).attrs({ color: theme.color.green[4] })`
  background-color: ${theme.color.green[7]};
`

const Suspended = styled(Badge).attrs({ color: theme.color.red[2] })`
  background-color: ${theme.color.red[4]};
`
