import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { SettingsSection } from '@/components/SettingsSection'
import { SettingStaffEditPageContainer } from '../SettingStaffEditPageContainer'
import { Body as _Body } from './Body'
import { Buttons } from './Buttons'

export const Confirm: React.VFC = () => {
  const { onSubmit, completedForm, staff } =
    SettingStaffEditPageContainer.useContainer()

  if (!(completedForm && staff)) {
    throw new Error('invalid transition')
  }

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper onSubmit={onSubmit}>
        <Page.Header>
          <Page.HeaderTitle>ユーザー詳細編集</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="内容を確認">
            <Body completedForm={completedForm} staff={staff} />
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <Buttons />
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const Body = styled(_Body)`
  padding: 20px 0;
`
