import { useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useModal } from '@/hooks/useModal'
import { useRequestUnregisterStaff } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { INTERNAL_PATHS } from '@/services/urlService'

export const useUnregisterStaff = (guid: string) => {
  const history = useHistory()

  const unregisterStaffModal = useModal(false)

  const { mutateAsync, isLoading } = useUnregisterStaffMutation(guid)

  const onSubmitUnregisterStaff = useCallback(async () => {
    if (isLoading) return

    try {
      await mutateAsync()
    } catch (e) {
      Sentry.captureException(e)
      commonErrorToast()
      return
    }

    // TODO: テーマカラーをFigmaに揃える
    // TODO: '✗'ボタンを消す
    toast('ユーザーを削除しました')
    unregisterStaffModal.close()

    history.push(INTERNAL_PATHS.settings.staffs.index)
  }, [mutateAsync, isLoading, history, unregisterStaffModal])

  return {
    unregisterStaffModal,
    onSubmitUnregisterStaff,
  }
}

function useUnregisterStaffMutation(guid: string) {
  const queryClient = useQueryClient()
  const { requestUnregisterStaff } = useRequestUnregisterStaff()

  return useMutation(() => requestUnregisterStaff(guid), {
    onSuccess: () => {
      queryClient.removeQueries(QUERY_KEY.listStaffs)
      // NOTE: 本来であれば対象ユーザーが存在しなくなるにあわせて
      // `[queryKey.getStaff, guid]`に対するremoveQueriesも必要である。
      // しかし再取得回避の仕組み実装のコスパが良くないので意図的に行わないことにしている
      //
      // refs: https://github.com/blue-agency/biz-anakin-front/pull/462/files#r551159316
    },
  })
}
