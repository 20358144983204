import React from 'react'
import { theme, Icon, Txt } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Loading } from '@/components/Loading'
import { SettingsSection } from '@/components/SettingsSection'
import { useModal } from '@/hooks/useModal'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import { EditOrganizationNameModal } from './EditOrganizationNameModal'
import { useOrganization } from './useOrganization'

export const Content = (props: PropsWithClassName): React.ReactElement => {
  const editOrganizationNameModal = useModal()

  const { isLoading, getOrganization } = useOrganization()

  if (isLoading) {
    return <Loading />
  }

  const organizationName = getOrganization().name

  return (
    <Wrapper className={props.className}>
      <SettingsSection title="企業情報設定">
        <SectionInner>
          <Container>
            <EditContainer>
              <Edit onClick={editOrganizationNameModal.open}>
                <Icon name="edit" />
                <Txt>編集</Txt>
              </Edit>
            </EditContainer>
            <OrganizationInfo>
              <OrganizationNameLabel>
                <LabelTxt>企業名</LabelTxt>
                <div
                  data-tip
                  data-for={TOOLTIP_ID_MAP.nameOnSettingOrganization}
                >
                  <QuestionIcon />
                </div>
                <Tooltip
                  id={TOOLTIP_ID_MAP.nameOnSettingOrganization}
                  arrowPosition="topLeft"
                >
                  面接開始画面など、応募者に公開される会社名となります。
                </Tooltip>
              </OrganizationNameLabel>
              <OrganizationName>{organizationName}</OrganizationName>
            </OrganizationInfo>
          </Container>
        </SectionInner>
      </SettingsSection>
      <EditOrganizationNameModal
        active={editOrganizationNameModal.active}
        onCloseModal={editOrganizationNameModal.close}
        organizationName={organizationName}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${theme.color.white[1]};
  height: 100%;
  width: 960px;
`

const SectionInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
`
const Container = styled.div`
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid ${theme.color.gray[4]};
`

const OrganizationInfo = styled.div`
  display: flex;
`

const OrganizationNameLabel = styled.div`
  width: 136px;
  display: flex;
`

const LabelTxt = styled(Txt).attrs({ size: 'm' })`
  line-height: 24px;
`

const QuestionIcon = styled(Icon).attrs({ name: 'question', size: 'l' })`
  margin-left: 5px;
  cursor: pointer;
`

const OrganizationName = styled(Txt)`
  line-height: 24px;
`

const EditContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Edit = styled.div`
  display: flex;
  cursor: pointer;
  svg {
    margin-right: 4px;
  }
`
