import { useMemo } from 'react'
import { ListIPFiltersResponse } from '@blue-agency/proton/web/v2/biz_anakin_bff'
import { useQuery } from 'react-query'
import { assertIsDefined } from '@/assertions'
import { useRequestListIpFilters } from '@/services/bffService/useRequestListIpFilters'
import { IpFilter } from '@/services/ipFilterService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { Name } from '@/services/staffService/name'

export const useIpFilters = () => {
  const { isLoading, data } = useListIpFiltersQuery()

  const ipFilters = useMemo<IpFilter[]>(() => {
    if (isLoading) return []
    assertIsDefined(data)
    return data.getIpFiltersList().map(toIpFilter)
  }, [isLoading, data])

  return {
    isLoading,
    ipFilters,
  }
}

const toIpFilter = (
  responseIpFilter: ListIPFiltersResponse.IPFilter
): IpFilter => {
  const ipFilter = new IpFilter({
    guid: responseIpFilter.getGuid(),
    name: responseIpFilter.getName(),
    scopeType: 'all_staffs',
    scopeSpecifiedStaffs: [],
    cidrs: [], // 表示に必須でないためダミーを入れる
  })
  if (!responseIpFilter.hasScope()) return ipFilter

  const scope = responseIpFilter.getScope()
  assertIsDefined(scope)
  if (scope.getAllStaffs()) return ipFilter

  ipFilter.scopeType = 'specified_staffs'
  if (!scope.hasSpecifiedStaffs()) return ipFilter

  const staffs = scope.getSpecifiedStaffs()
  assertIsDefined(staffs)

  ipFilter.scopeSpecifiedStaffs = staffs.getGuidsList().map((guid) => ({
    guid,
    name: new Name('', ''), // 表示に必須でないためダミーを入れる
  }))

  return ipFilter
}

function useListIpFiltersQuery() {
  const { requestListIpFilters } = useRequestListIpFilters()

  return useQuery([QUERY_KEY.listIpFilters], () => requestListIpFilters())
}
