import React, { useEffect, useState } from 'react'
import {
  SignInPage,
  SignInCallbackPage,
  getInternalUrls,
  AuthContainer,
} from '@blue-agency/im-shared-front'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Loading } from '@/components/Loading'
import { history } from '@/historyModule'
import { useUnauthorizedTracking } from '@/hooks/useTracking'
import { ActivateStaffPage } from '@/pages/ActivateStaffPage'
import { RegisterOrganizationPage } from '@/pages/RegisterOrganizationPage'
import { INTERNAL_PATHS } from '@/services/urlService'

// NOTE: 他のサービスでログイン済みならログインする
// 他のサービスでログイン済みだが、シームレスログインに失敗するか
// 他のサービスでも未ログインならログイン画面にリダイレクトする
const MaybeRedirectToSignIn = () => {
  const [shouldRedirect, setShouldRedirect] = useState(false)

  const { maybeLogin } = AuthContainer.useContainer()

  useEffect(() => {
    const f = async () => {
      const loggedIn = await maybeLogin()
      if (loggedIn) return
      setShouldRedirect(true)
    }
    f()
  }, [maybeLogin])

  if (!shouldRedirect) return <Loading />

  return (
    <Redirect
      to={{
        pathname: getInternalUrls().signIn,
        search: `?redirect=${
          // サインイン後にリダイレクトするパスとして、URL の origin 以降を渡す
          window.location.href.split(window.location.origin)[1]
        }`,
      }}
    />
  )
}

export const UnauthorizedRoutes = () => {
  useUnauthorizedTracking(history)

  return (
    <Switch>
      <Route
        exact
        path={INTERNAL_PATHS.registerOrganization}
        component={RegisterOrganizationPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.activateStaff}
        component={ActivateStaffPage}
      />
      <Route exact path={getInternalUrls().signIn} component={SignInPage} />
      <Route
        exact
        path={getInternalUrls().signInCallback}
        component={SignInCallbackPage}
      />
      <MaybeRedirectToSignIn />
    </Switch>
  )
}
