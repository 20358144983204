import React from 'react'
import {
  getInternalUrls,
  NotFoundPage,
  useInitializeComlinkStaff,
} from '@blue-agency/im-shared-front'
import { Route, Switch } from 'react-router-dom'
import { history } from '@/historyModule'
import { useAuthorizedTracking } from '@/hooks/useTracking'
import { APISettingsDetailV2EditPage } from '@/pages/APISettingsDetailV2EditPage'
import { APISettingsDetailV2Page } from '@/pages/APISettingsDetailV2Page'
import { APISettingsDetailV3EditPage } from '@/pages/APISettingsDetailV3EditPage'
import { APISettingsDetailV3Page } from '@/pages/APISettingsDetailV3Page'
import { APISettingsPage } from '@/pages/APISettingsPage'
import { EditPasswordPage } from '@/pages/EditPasswordPage'
import { SettingIndexPage } from '@/pages/SettingIndexPage'
import { SettingIpFilterDetailPage } from '@/pages/SettingIpFilterDetailPage'
import { SettingIpFilterEditPage } from '@/pages/SettingIpFilterEditPage'
import { SettingIpFilterListPage } from '@/pages/SettingIpFilterListPage'
import { SettingIpFilterNewPage } from '@/pages/SettingIpFilterNewPage'
import { SettingOrganizationPage } from '@/pages/SettingOrganizationPage'
import { SettingStaffDetailPage } from '@/pages/SettingStaffDetailPage'
import { SettingStaffEditPage } from '@/pages/SettingStaffEditPage'
import { SettingStaffListPage } from '@/pages/SettingStaffListPage'
import { SettingStaffRegisterByFilePage } from '@/pages/SettingStaffRegisterByFilePage'
import { SettingStaffRegisterByFormPage } from '@/pages/SettingStaffRegisterByFormPage'
import { SettingStaffRegisterSelectPage } from '@/pages/SettingStaffRegisterSelectPage'
import { StaffProfilePage } from '@/pages/StaffProfilePage'
import { INTERNAL_PATHS } from '@/services/urlService'

export const AuthorizedRoutes = () => {
  useAuthorizedTracking(history)

  useInitializeComlinkStaff()

  return (
    <Switch>
      <Route
        exact
        // ログイン後にkylo-frontのログイン画面に(ブックマーク等で)直接遷移した場合、ログインフローとして`/signin`ページに戻ってくるが、
        // AuthorizedRoutesにRouteが無く404となる。これを避けるため、`/signin`ページを含めてダッシュボードに遷移させる必要がある
        path={['/', getInternalUrls().signIn]}
        // ダッシュボードは biz-anakin-front の外( biz-hutt-front のドメイン配下)に存在しているため、window.location.href をつかってページ移動させる必要がある
        component={() => {
          window.location.href = getInternalUrls().dashboard
          return null
        }}
      />
      <Route
        exact
        path={INTERNAL_PATHS.settings.index}
        component={SettingIndexPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.settings.staffs.index}
        component={SettingStaffListPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.settings.staffs.registerSelect}
        component={SettingStaffRegisterSelectPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.settings.staffs.registerByForm}
        component={SettingStaffRegisterByFormPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.settings.staffs.registerByFile}
        component={SettingStaffRegisterByFilePage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.settings.staffs.detail}
        component={SettingStaffDetailPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.settings.staffs.edit}
        component={SettingStaffEditPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.settings.organization}
        component={SettingOrganizationPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.settings.ipfilters.new}
        component={SettingIpFilterNewPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.settings.ipfilters.index}
        component={SettingIpFilterListPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.settings.ipfilters.detail}
        component={SettingIpFilterDetailPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.settings.ipfilters.edit}
        component={SettingIpFilterEditPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.staffProfile}
        component={StaffProfilePage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.editPassword}
        component={EditPasswordPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.apiSettings.index}
        component={APISettingsPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.apiSettings.detailV3}
        component={APISettingsDetailV3Page}
      />
      <Route
        exact
        path={INTERNAL_PATHS.apiSettings.editV3}
        component={APISettingsDetailV3EditPage}
      />
      <Route
        exact
        path={INTERNAL_PATHS.apiSettings.detailV2}
        component={APISettingsDetailV2Page}
      />
      <Route
        exact
        path={INTERNAL_PATHS.apiSettings.editV2}
        component={APISettingsDetailV2EditPage}
      />
      <Route component={() => <NotFoundPage showDashboardLink />} />
    </Switch>
  )
}
