import React from 'react'
import { Txt } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import * as C from './components'

export const TooltipForIpAddress: React.VFC = () => (
  <>
    <div data-tip data-for={TOOLTIP_ID_MAP.nameOnSettingIpFilterIpAddress}>
      <C.QuestionIcon />
    </div>
    <Tooltip
      id={TOOLTIP_ID_MAP.nameOnSettingIpFilterIpAddress}
      arrowPosition="topLeft"
    >
      <TooltipSection>
        IPアドレスとサブネットマスクの組み合わせでアクセスを許可するネットワークを指定してください
        <ul>
          <li>「グローバルIPアドレス」を入力してください。</li>
          <li>複数のIPアドレスを登録可能です。</li>
        </ul>
      </TooltipSection>
    </Tooltip>
  </>
)

export const TooltipForScopeType: React.VFC = () => (
  <>
    <div data-tip data-for={TOOLTIP_ID_MAP.nameOnSettingIpFilterScopeType}>
      <C.QuestionIcon />
    </div>
    <Tooltip
      id={TOOLTIP_ID_MAP.nameOnSettingIpFilterScopeType}
      arrowPosition="topLeft"
    >
      <TooltipSection>
        <TooltipSectionTitle>全ユーザー</TooltipSectionTitle>
        <TooltipSectionContent>
          全ユーザーにフィルターが適用されます。
        </TooltipSectionContent>
      </TooltipSection>
      <TooltipSection>
        <TooltipSectionTitle>ユーザー指定</TooltipSectionTitle>
        <TooltipSectionContent>
          指定したユーザーのみフィルターが適用されます。
        </TooltipSectionContent>
      </TooltipSection>
    </Tooltip>
  </>
)

const TooltipSection = styled.div`
  max-width: 320px;
  line-height: 18px;
  :not(:last-child) {
    margin-bottom: 18px;
  }
  & > ul {
    padding-left: 15px;
  }
`
const TooltipSectionTitle = styled(Txt).attrs({ size: 'm', bold: true })``
const TooltipSectionContent = styled(Txt).attrs({ size: 'm' })``
