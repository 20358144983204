import React from 'react'
import { AuthzType, authzTypeOf } from '@blue-agency/im-shared-front'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Form } from '@/components/Form'

type Props = PropsWithClassName<{
  authzType: AuthzType['type']
}>

export const AuthzTypeSection: React.VFC<Props> = ({
  className,
  authzType,
}) => {
  return (
    <Form.Section className={className}>
      <Form.Row>
        <Form.Title>権限タイプ</Form.Title>
        <Form.Item>
          <Form.Field>
            <Form.Txt>{authzTypeOf(authzType).name}</Form.Txt>
          </Form.Field>
        </Form.Item>
      </Form.Row>
    </Form.Section>
  )
}
