import { Name } from '../staffService/name'

export const scopes = ['all_staffs', 'specified_staffs'] as const

export type ScopeSpecifiedStaff = {
  guid: string
  name: Name
}

export type Cidr = {
  ipAddress: string
  subnetMask: SubnetMaskBits
}

type IpFilterArgs = {
  guid: string
  name: string
  scopeType: Scope
  scopeSpecifiedStaffs: ScopeSpecifiedStaff[]
  cidrs: Cidr[]
}

export class IpFilter {
  guid: string
  name: string
  scopeType: Scope
  scopeSpecifiedStaffs: ScopeSpecifiedStaff[]
  cidrs: Cidr[]

  constructor(ipFilter: IpFilterArgs) {
    this.guid = ipFilter.guid
    this.name = ipFilter.name
    this.scopeType = ipFilter.scopeType
    this.scopeSpecifiedStaffs = ipFilter.scopeSpecifiedStaffs
    this.cidrs = ipFilter.cidrs
  }

  get scopeSummary() {
    switch (this.scopeType) {
      case 'all_staffs':
        return '全ユーザー'
      case 'specified_staffs':
        return this.scopeSpecifiedStaffs.length + '名'
      default:
        return ''
    }
  }
}

export type Scope = typeof scopes[number]

export const scopeTypeNameOf = (value: Scope): string | undefined => {
  switch (value) {
    case 'all_staffs':
      return '全ユーザー'
    case 'specified_staffs':
      return 'ユーザー指定'
  }
}

export type SubnetMaskBits = number
