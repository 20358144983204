import { useCallback, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { AuthzType, commonErrorToast } from '@blue-agency/im-shared-front'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { validationScheme } from '@/pages/SettingStaffRegisterByFormPage/formSchema'
import type { AuthnType } from '@/services/authnService'
import {
  CustomBizAnakinBffGrpcError,
  RegisterStaffRequestValue,
  useRequestRegisterStaff,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { useStep } from './hooks/useStep'

export type Form = {
  familyName: string
  givenName: string
  familyNameKana: string
  givenNameKana: string
  email: string
  authzType: AuthzType['type']
  authnType: AuthnType['type']
}

const useSettingUserRegisterByFormPage = () => {
  const {
    isCompleted,
    content,
    toInputStep: _toInputStep,
    toCompletedStep,
    toConfirmStep,
  } = useStep()

  const [completedForm, setCompletedForm] = useState<
    RegisterStaffRequestValue | undefined
  >()

  const { register, handleSubmit, formState, trigger, reset, clearErrors } =
    useForm<Form>({
      resolver: yupResolver(validationScheme),
      mode: 'onBlur',
      defaultValues: completedForm,
    })

  const [submitErrors, setSubmitErrors] = useState<{
    email: string | undefined
  }>({
    email: undefined,
  })

  const onSubmit = handleSubmit((data) => {
    setCompletedForm(data)
    toConfirmStep()
  })

  const toInputStep = useCallback(() => {
    reset(completedForm)
    _toInputStep()
    // FIXME: テスト実行時に全入力値が空としてバリデーションエラーとなってしまうため、
    // resetした後のFormに対してバリデーションを実行させることで回避している。
    // なお、ブラウザ上の操作においてこの呼び出しは無くとも問題ない。
    trigger()
  }, [reset, _toInputStep, completedForm, trigger])

  const { mutateAsync: mutateStaff } = useRegisterStaffMutation()
  const registerStaff = useCallback(async () => {
    if (!completedForm) {
      return
    }

    try {
      await mutateStaff(completedForm)
    } catch (e) {
      if (e instanceof CustomBizAnakinBffGrpcError && e.isAlreadyExists) {
        setSubmitErrors({
          email: `${completedForm.email}はご利用できません。`,
        })
        toInputStep()
        return
      }
      Sentry.captureException(e)
      commonErrorToast()
      return
    }
    toCompletedStep()
  }, [mutateStaff, completedForm, toCompletedStep, toInputStep])

  const restart = useCallback(() => {
    setCompletedForm(undefined)
    reset({})
    clearErrors()
    setSubmitErrors({ email: undefined })
    _toInputStep()
  }, [reset, _toInputStep, clearErrors])

  return {
    registerStaff,
    register,
    completedForm,
    onSubmit,
    errors: formState.errors,
    content,
    isCompleted,
    toInputStep,
    toConfirmStep,
    submitErrors,
    restart,
  }
}

export const SettingStaffRegisterByFormPageContainer = createContainer(
  useSettingUserRegisterByFormPage
)

function useRegisterStaffMutation() {
  const queryClient = useQueryClient()
  const { requestRegisterStaff } = useRequestRegisterStaff()

  return useMutation(requestRegisterStaff, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.listStaffs)
    },
  })
}
