import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useRequestGetConnectedIpAddress } from '@/services/bffService/useRequestGetConnectedIpAddress'
import { QUERY_KEY } from '@/services/queryKeyService'

export const useCurrentIp = () => {
  const { isLoading, data } = useGetConnectedIpAddressQuery()
  const address = useMemo(() => {
    if (isLoading || data === undefined) return
    return data.getIpAddress()
  }, [isLoading, data])

  return {
    currentIp: address,
  }
}

function useGetConnectedIpAddressQuery() {
  const { requestGetConnectedIpAddress } = useRequestGetConnectedIpAddress()

  return useQuery(QUERY_KEY.getConnectedIpAddress, () =>
    requestGetConnectedIpAddress()
  )
}
