import React, { useCallback, useState } from 'react'
import { Icon, theme } from '@blue-agency/rogue'
import { InputText as _InputText, InputTextProps } from '@blue-agency/rogue/im'
import styled from 'styled-components'

export type InputPasswordProps = InputTextProps

export const InputPassword = React.forwardRef(
  (
    props: InputPasswordProps,
    ref: React.Ref<HTMLInputElement>
  ): React.ReactElement => {
    const [isShown, setIsShown] = useState<boolean>(false)
    const toggleIsShown = useCallback(() => setIsShown((prev) => !prev), [])
    return (
      <Wrapper>
        <InputText type={isShown ? 'text' : 'password'} {...props} ref={ref} />
        <IconEyeWrapper onClick={toggleIsShown}>
          <IconEye />
        </IconEyeWrapper>
      </Wrapper>
    )
  }
)

const Wrapper = styled.div`
  display: flex;
`
const IconEyeWrapper = styled.div`
  position: relative;
  top: 6px;
  right: 34px;
  cursor: pointer;
`

const IconEye = styled(Icon).attrs({ name: 'eye' })`
  color: ${theme.color.gray[3]};
`
const InputText = styled(_InputText)`
  input {
    padding-right: 40px;
  }
`
