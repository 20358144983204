import React from 'react'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Form } from '@/components/Form'
import {
  InactiveStaffBadge,
  RegistrationExpiredStaffBadge,
  ResumedStaffBadge,
} from '@/components/StaffBadge'
import { SettingStaffEditPageContainer } from '../../SettingStaffEditPageContainer'

export const EmailSection: React.VFC<PropsWithClassName> = ({ className }) => {
  const { staff } = SettingStaffEditPageContainer.useContainer()

  if (!staff) {
    return null
  }

  const renderBadge = () => {
    if (staff.isRegistrationExpired) {
      return <RegistrationExpiredStaffBadge />
    }
    if (staff.status.isRegistered()) {
      return <InactiveStaffBadge />
    }
    if (staff.status.isResumed()) {
      return <ResumedStaffBadge />
    }
  }

  return (
    <Form.Section className={className}>
      <Form.Row>
        <Form.Title>メールアドレス</Form.Title>
        <Form.Item>
          <Form.EmailField>
            <Form.EmailTxt>{staff.email}</Form.EmailTxt>
            <Form.EmailButtons>{renderBadge()}</Form.EmailButtons>
          </Form.EmailField>
        </Form.Item>
      </Form.Row>
    </Form.Section>
  )
}
