import {
  administrator,
  assistant,
  AuthzType,
  normal,
} from '@blue-agency/im-shared-front'
import * as yup from 'yup'
import { validatePassword } from './validator'

export const basicStringSchema = yup
  .string()
  .required('入力してください')
  .max(255, '255文字以内で入力してください')

export const passwordSchema = yup
  .string()
  .required('入力してください')
  .test(
    'password',
    'パスワードは半角英数字記号で8文字以上としてください',
    validatePassword
  )

export const passwordConfirmationSchema = yup
  .string()
  .required('入力してください')
  .equals([yup.ref('password')], 'パスワードが一致しません')

export const newPasswordConfirmationSchema = (ref: string) =>
  yup
    .string()
    .required('入力してください')
    .equals([yup.ref(ref)], 'パスワードが一致しません')

export const emailSchema = yup
  .string()
  .required('入力してください')
  .email('メールアドレスを入力してください')
  .max(255, '255文字以内で入力してください')

export const authzTypeSchema = yup
  .mixed<AuthzType['type']>()
  .required()
  .oneOf([administrator.type, normal.type, assistant.type])
