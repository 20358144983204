import React, { useState, useMemo, useCallback } from 'react'
import { Completed } from '../Completed'
import { Confirm } from '../Confirm'
import { Input } from '../Input'

type Step = 'input' | 'confirm' | 'completed'

export const useStep = () => {
  const [step, setStep] = useState<Step>('input')
  const content = useMemo(() => {
    switch (step) {
      case 'input':
        return <Input />
      case 'confirm':
        return <Confirm />
      case 'completed':
        return <Completed />
      default:
        throw new Error('Invalid step')
    }
  }, [step])
  const toInputStep = useCallback(() => {
    setStep('input')
  }, [])
  const toConfirmStep = useCallback(() => {
    setStep('confirm')
  }, [])
  const toCompletedStep = useCallback(() => {
    setStep('completed')
  }, [])
  return {
    isCompleted: step === 'completed',
    content,
    toInputStep,
    toCompletedStep,
    toConfirmStep,
  }
}
