import { useCallback } from 'react'
import { ActivateStaffRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'

export type ActivateStaffRequestValue = {
  password: string
  token: string
}

export function useRequestActivateStaff() {
  const requestActivateStaff = useCallback(
    (params: ActivateStaffRequestValue) => {
      const req = new ActivateStaffRequest()
      req.setPassword(params.password)
      req.setToken(params.token)

      const metadata = { authorization: params.token }

      return callBizAnakinBffService(
        'activateStaff',
        [req, metadata],
        undefined
      )
    },
    []
  )
  return { requestActivateStaff }
}
