import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { Confirm } from './Confirm'
import { Input } from './Input'
import { SettingIpFilterEditPageContainer } from './SettingIpFilterEditPageContainer'

export const SettingIpFilterEditPage: React.VFC = provide(
  SettingIpFilterEditPageContainer
)(() => {
  usePromptBeforeUnload()

  const { step } = SettingIpFilterEditPageContainer.useContainer()

  switch (step) {
    case 'input':
      return <Input />
    case 'confirm':
      return <Confirm />
  }
})
