import { useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useMutation, useQueryClient } from 'react-query'
import { useModal } from '@/hooks/useModal'
import { useRequestResumeStaff } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export const useResumeStaff = (guid: string) => {
  const resumeStaffModal = useModal(false)

  const { mutateAsync: resumeStaff, isLoading } = useResumeStaffMutation(guid)

  const onSubmitResumeStaff = useCallback(async () => {
    if (isLoading) return

    try {
      await resumeStaff()
    } catch (e) {
      Sentry.captureException(e)
      commonErrorToast()
      return
    }

    // TODO: テーマカラーをFigmaに揃える
    // TODO: '✗'ボタンを消す
    toast('ユーザー情報を保存しました')
    resumeStaffModal.close()
  }, [resumeStaffModal, isLoading, resumeStaff])

  return {
    resumeStaffModal,
    onSubmitResumeStaff,
  }
}

function useResumeStaffMutation(guid: string) {
  const queryClient = useQueryClient()
  const { requestResumeStaff } = useRequestResumeStaff()

  return useMutation(() => requestResumeStaff(guid), {
    onSuccess: () => {
      queryClient.refetchQueries([QUERY_KEY.getStaff, guid])
      queryClient.invalidateQueries(QUERY_KEY.listStaffs)
    },
  })
}
