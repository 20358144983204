import { useCallback, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { toast } from '@blue-agency/rogue'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
  CreateIpFilterRequestValue,
  useRequestCreateIpFilter,
} from '@/services/bffService/useRequestCreateIpFilter'
import {
  scopes,
  ScopeSpecifiedStaff,
  scopeTypeNameOf,
} from '@/services/ipFilterService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { validationSchema } from './formSchema'

type Step = 'input' | 'confirm'

export type FormScopeSpecifiedStaff = Omit<ScopeSpecifiedStaff, 'name'> & {
  fullName: string
}

export type Form = Omit<
  CreateIpFilterRequestValue,
  'scopeSpecifiedStaffGuids'
> & {
  scopeSpecifiedStaffs: FormScopeSpecifiedStaff[]
}

export const scopeOptions = scopes.map((v) => ({
  label: scopeTypeNameOf(v),
  value: v,
}))

export const subnetMaskOptions = Array.from(Array(32).keys())
  .reverse()
  .map((v) => ({
    label: '/' + (v + 1),
    value: v + 1,
  }))

const useSettingIpFilterNewPage = () => {
  const [step, setStep] = useState<Step>('input')
  const history = useHistory()

  const [completedForm, setCompletedForm] = useState<Form>({
    name: '',
    scopeType: 'all_staffs',
    scopeSpecifiedStaffs: [],
    cidrs: [{ ipAddress: '', subnetMask: 32 }],
  })

  const {
    register,
    control,
    watch,
    reset,
    clearErrors,
    handleSubmit,
    formState,
  } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: completedForm,
  })

  const watchScopeType = watch('scopeType')
  const displayScopeUsers = watchScopeType === 'specified_staffs'

  const scopeSpecifiedStaffGuidFieldArray = useFieldArray({
    control,
    name: 'scopeSpecifiedStaffs',
  })
  const onAddScopeSpecifiedStaffGuid = useCallback(
    (guid: string, fullName: string) => {
      scopeSpecifiedStaffGuidFieldArray.append(
        { guid, fullName },
        { shouldFocus: false }
      )
      clearErrors('scopeSpecifiedStaffs')
    },
    [scopeSpecifiedStaffGuidFieldArray, clearErrors]
  )
  const onDeleteScopeSpecifiedStaffGuid = useCallback(
    (index: number) => scopeSpecifiedStaffGuidFieldArray.remove(index),
    [scopeSpecifiedStaffGuidFieldArray]
  )

  const cidrFieldArray = useFieldArray({ control, name: 'cidrs' })
  const onAddCidrInput = useCallback(() => {
    cidrFieldArray.append(
      { ipAddress: '', subnetMask: 32 },
      { shouldFocus: false }
    )
  }, [cidrFieldArray])
  const onDeleteCidrInput = useCallback(
    (index: number) => {
      index && cidrFieldArray.remove(index)
    },
    [cidrFieldArray]
  )

  const onCancelInput = useCallback(() => {
    history.push(INTERNAL_PATHS.settings.ipfilters.index)
    return
  }, [history])

  const onConfirm = handleSubmit((data) => {
    setCompletedForm(data)
    setStep('confirm')
  })

  const onBackToInput = useCallback(() => {
    reset(completedForm)
    setStep('input')
  }, [reset, completedForm, setStep])

  const { mutateAsync: mutateIpFilter } = useCreateIpFilterMutation()

  const onSubmit = useCallback(async () => {
    if (!completedForm) return
    try {
      await mutateIpFilter({
        ...completedForm,
        scopeSpecifiedStaffGuids: completedForm.scopeSpecifiedStaffs?.map(
          (staff) => staff.guid
        ),
      })
      toast('フィルター情報を登録しました')
      history.push(INTERNAL_PATHS.settings.ipfilters.index)
    } catch (e) {
      throw e
    }
  }, [history, mutateIpFilter, completedForm])

  return {
    step,
    completedForm,

    register,
    control,
    errors: formState.errors,

    // 対象範囲:ユーザー指定
    displayScopeUsers,
    scopeSpecifiedStaffGuidFields: scopeSpecifiedStaffGuidFieldArray.fields,
    onAddScopeSpecifiedStaffGuid,
    onDeleteScopeSpecifiedStaffGuid,

    // IPアドレス
    cidrFields: cidrFieldArray.fields,
    onAddCidrInput,
    onDeleteCidrInput,

    onCancelInput,
    onConfirm,
    onBackToInput,
    onSubmit,
  }
}

export const SettingIpFilterNewPageContainer = createContainer(
  useSettingIpFilterNewPage
)

function useCreateIpFilterMutation() {
  const queryClient = useQueryClient()
  const { requestCreateIpFilter } = useRequestCreateIpFilter()

  return useMutation(requestCreateIpFilter, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.listIpFilters)
      queryClient.invalidateQueries(QUERY_KEY.getIpFilter)
    },
  })
}
