import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { useAuthz } from '@/services/authzService'
import { Confirm } from './Confirm'
import { Input } from './Input'
import { SettingIpFilterNewPageContainer } from './SettingIpFilterNewPageContainer'

export const SettingIpFilterNewPage: React.VFC = provide(
  SettingIpFilterNewPageContainer
)(() => {
  const { authorize } = useAuthz()
  authorize()

  usePromptBeforeUnload()

  const { step } = SettingIpFilterNewPageContainer.useContainer()

  switch (step) {
    case 'input':
      return <Input />
    case 'confirm':
      return <Confirm />
  }
})
