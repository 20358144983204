import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateIPFilterRequest } from '@blue-agency/proton/biz_anakin_bff'
import {
  IPFilterCidr,
  IPFilterScope,
} from '@blue-agency/proton/web/v2/im/ip_filter_data_pb'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateIpFilterRequestValue = {
  guid: string
  name: string
  scopeType: 'all_staffs' | 'specified_staffs'
  scopeSpecifiedStaffGuids: string[]
  cidrs: IPFilterCidr.AsObject[]
}

export function useRequestUpdateIpFilter() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateIpFilter = useCallback(
    async (ipFilter: UpdateIpFilterRequestValue) => {
      const metadata = await buildMetadata()
      const req = new UpdateIPFilterRequest()
      req.setGuid(ipFilter.guid)
      req.setName(ipFilter.name)

      const scope = new IPFilterScope()
      if (ipFilter.scopeType === 'all_staffs') {
        scope.setAllStaffs(true)
      } else {
        const users = new IPFilterScope.SpecifiedStaffs()
        ipFilter.scopeSpecifiedStaffGuids.forEach((guid) =>
          users.addGuids(guid)
        )
        scope.setSpecifiedStaffs(users)
      }
      req.setScope(scope)

      ipFilter.cidrs.forEach((item) => {
        const cidr = new IPFilterCidr()
        cidr.setIpAddress(item.ipAddress)
        cidr.setSubnetMask(item.subnetMask)
        req.addCidrs(cidr)
      })

      return callBizAnakinBffService(
        'updateIPFilter',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestUpdateIpFilter }
}
