import React from 'react'
import { SecondaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { Link } from '@/components/Link'
import { INTERNAL_PATHS } from '@/services/urlService'
import { SettingStaffRegisterByFormPageContainer } from '../SettingStaffRegisterByFormPageContainer'

export const Buttons = (): React.ReactElement => {
  const { restart } = SettingStaffRegisterByFormPageContainer.useContainer()
  return (
    <>
      <Link to={INTERNAL_PATHS.settings.staffs.index}>
        <TertiaryButton
          widthSize="L2"
          comlinkPushParams={{ action: 'click_back_to_staff_table' }}
        >
          一覧へ戻る
        </TertiaryButton>
      </Link>
      <SecondaryButton
        widthSize="L2"
        comlinkPushParams={{
          action: 'click_continue_to_register_staff_button',
        }}
        onClick={restart}
      >
        続けて登録する
      </SecondaryButton>
    </>
  )
}
