import * as yup from 'yup'
import { basicStringSchema } from '@/services/formService'
import { Scope, SubnetMaskBits } from '@/services/ipFilterService'
import { isIPv4 } from '@/services/ipFilterService/ipv4'

const ipFilterScopeSchema = yup
  .mixed<Scope>()
  .required()
  .oneOf(['all_staffs', 'specified_staffs'])

const ipAddressSchema = basicStringSchema.test({
  name: 'ipAddress',
  test: (value) => typeof value === 'string' && isIPv4(value),
  message: 'IPアドレスは、正しい形式で登録してください',
})

const subnetMaskSchema: yup.NumberSchema<SubnetMaskBits> = yup
  .number()
  .required()
  .min(1)
  .max(32)
  .default(32)

const cidrSchema = yup
  .object()
  .shape({
    ipAddress: ipAddressSchema,
    subnetMask: subnetMaskSchema,
  })
  .defined()

// FIXME: SettingIpFilterNewPageContainerのFormの型で縛れるようにする
export const validationSchema = yup.object({
  name: basicStringSchema,
  scopeType: ipFilterScopeSchema,
  scopeSpecifiedStaffs: yup.array().when('scopeType', {
    is: 'all_staffs',
    then: yup.array().optional(),
    otherwise: yup
      .array()
      .of(
        yup
          .object({
            guid: basicStringSchema,
            fullName: basicStringSchema,
          })
          .defined()
      )
      .required('入力してください'),
  }),
  cidrs: yup.array().of(cidrSchema).min(1).defined(),
})
