import {
  GetStaffResponse,
  ListStaffsResponse,
} from '@blue-agency/proton/biz_anakin_bff'

// NOTE: ListStaffsResponse.StatusとGetStaffResponse.Statusは別定義であるが
// ここでは共通の定義に集約している
// https://github.com/blue-agency/proton/blob/master/proto/v2/biz_anakin_bff/biz_anakin_bff_service.proto
enum statusValue {
  UNKNOWN = 0,
  REGISTERED = 1,
  ACTIVATED = 2,
  RESUMED = 3,
}

export class Status {
  value: number

  private constructor(value: number) {
    this.value = value
  }

  isRegistered() {
    return this.value === statusValue.REGISTERED
  }

  isActivated() {
    return this.value === statusValue.ACTIVATED
  }

  isResumed() {
    return this.value === statusValue.RESUMED
  }

  static ofListStaffsResponse(value?: ListStaffsResponse.Status): Status {
    switch (value) {
      case ListStaffsResponse.Status.REGISTERED:
        return new Status(statusValue.REGISTERED)
      case ListStaffsResponse.Status.ACTIVATED:
        return new Status(statusValue.ACTIVATED)
      case ListStaffsResponse.Status.RESUMED:
        return new Status(statusValue.RESUMED)
      case ListStaffsResponse.Status.STATUS_UNKNOWN:
        return new Status(statusValue.UNKNOWN)
      default:
        throw new Error('invalid status value')
    }
  }

  static ofGetStaffsResponse(value?: GetStaffResponse.Status): Status {
    switch (value) {
      case GetStaffResponse.Status.REGISTERED:
        return new Status(statusValue.REGISTERED)
      case GetStaffResponse.Status.ACTIVATED:
        return new Status(statusValue.ACTIVATED)
      case GetStaffResponse.Status.RESUMED:
        return new Status(statusValue.RESUMED)
      case GetStaffResponse.Status.STATUS_UNKNOWN:
        return new Status(statusValue.UNKNOWN)
      default:
        throw new Error('invalid status value')
    }
  }

  static registered(): Status {
    return new Status(statusValue.REGISTERED)
  }

  static activated(): Status {
    return new Status(statusValue.ACTIVATED)
  }

  static resumed(): Status {
    return new Status(statusValue.RESUMED)
  }
}
