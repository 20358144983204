import { useCallback, useMemo } from 'react'
import { useQueryParams, StringParam, NumberParam } from 'use-query-params'
import { StaffTable } from '@/pages/SettingStaffListPage/useStaffsTable'
import {
  saveToLocalStorageJson,
  getFromLocalStorageJson,
  deleteItemFromLocalStorageJson,
  LOCAL_STORAGE_KEY,
} from '../storageService'
import { StaffListFilterCondition } from './StaffList'
import { StaffSort } from './types'

// クエリパラメータにJSON.stringifyしたものを入れるので、極力プロパティ名を短くする
type CompressedStaffSort = {
  k: 'n' | 'r'
  o: 'd' | 'a'
}

export const compressStaffSort = (s: StaffSort): CompressedStaffSort => ({
  k: s.key === 'nameKana' ? 'n' : 'r',
  o: s.order === 'asc' ? 'a' : 'd',
})

export const decompressStaffSort = (s: CompressedStaffSort): StaffSort => ({
  key: s.k === 'n' ? 'nameKana' : 'registeredAt',
  order: s.o === 'a' ? 'asc' : 'desc',
})

export type CompressedStaffListFilterCondition = {
  f: string
  s: {
    r: 't' | 'f'
    a: 't' | 'f'
    e: 't' | 'f'
  }
}

export const compressStaffListFilterCondition = (
  s: StaffListFilterCondition
): CompressedStaffListFilterCondition => ({
  f: s.freeWord,
  s: {
    r: s.status.isRegisteredOrResumed ? 't' : 'f',
    a: s.status.isActivated ? 't' : 'f',
    e: s.status.isExpired ? 't' : 'f',
  },
})

export const decompressStaffListFilterCondition = (
  s: CompressedStaffListFilterCondition
): StaffListFilterCondition => ({
  freeWord: s.f,
  status: {
    isRegisteredOrResumed: s.s.r === 't',
    isActivated: s.s.a === 't',
    isExpired: s.s.e === 't',
  },
})

export const useStaffListQueryParam = () => {
  const [query, setQuery] = useQueryParams({
    at: StringParam, // activeTable
    cp: NumberParam, // currentPage
    ss: StringParam, // staffSort
    fc: StringParam, // filterCondition
    st: NumberParam, // scrollTop
  })

  const activeTableParam = useMemo<StaffTable | undefined>(() => {
    if (query.at === 'staffs') return 'staffs'
    if (query.at === 'suspendedStaffs') return 'suspendedStaffs'
    return undefined
  }, [query.at])

  const currentPageParam = useMemo(() => {
    return query.cp ?? 1
  }, [query.cp])

  const staffSortParam = useMemo(() => {
    if (!query.ss) return undefined
    try {
      return decompressStaffSort(JSON.parse(query.ss))
    } catch {
      return undefined
    }
  }, [query.ss])

  const filterConditionParam = useMemo(() => {
    if (!query.fc) return undefined
    try {
      return decompressStaffListFilterCondition(JSON.parse(query.fc))
    } catch {
      return undefined
    }
  }, [query.fc])

  const scrollTopParam = useMemo(() => {
    return query.st ?? 0
  }, [query.st])

  const saveQueryToLocalStorage = useCallback((guid: string, query: string) => {
    saveToLocalStorageJson(LOCAL_STORAGE_KEY.staffListQuery, guid, query)
  }, [])

  const getQueryFromLocalStorage = useCallback((guid: string) => {
    return getFromLocalStorageJson(LOCAL_STORAGE_KEY.staffListQuery, guid)
  }, [])

  const clearStorages = useCallback((guid: string) => {
    deleteItemFromLocalStorageJson(LOCAL_STORAGE_KEY.staffListQuery, guid)
  }, [])

  return {
    query,
    activeTableParam,
    currentPageParam,
    staffSortParam,
    filterConditionParam,
    scrollTopParam,
    setQuery,
    saveQueryToLocalStorage,
    getQueryFromLocalStorage,
    clearStorages,
  }
}

export const convertFilterConditionToQueryString = (
  filterCondition: StaffListFilterCondition
) => {
  return JSON.stringify(compressStaffListFilterCondition(filterCondition))
}

export const convertStaffSortToQueryString = (staffSort: StaffSort) => {
  return JSON.stringify(compressStaffSort(staffSort))
}
