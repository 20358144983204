import React, { useCallback, useMemo } from 'react'
import { CheckBox, InputText, theme, Txt } from '@blue-agency/rogue'
import produce from 'immer'
import styled from 'styled-components'
import { StaffListFilterCondition } from '@/services/staffService'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { StaffTableOptions } from './StaffTableOptions'
import { StaffTable } from './useStaffsTable'

type SidebarProps = {
  activeTable: StaffTable
  onSelectTable: (table: StaffTable) => void
  activeStaffCount: number
  staffLength: number
  suspendedStaffLength: number
  staffListFilterCondition: StaffListFilterCondition
  onUpdateStaffListFilterCondition: (
    condition: StaffListFilterCondition
  ) => void
  onResetStaffListFilterCondition: () => void
}

export const Sidebar = (props: SidebarProps) => {
  const statusOptions = useMemo(
    () =>
      [
        {
          value: 'staffs',
          label: `利用中（${props.staffLength}件）`,
        },
        {
          value: 'suspendedStaffs',
          label: `利用停止（${props.suspendedStaffLength}件）`,
        },
      ] as const,
    [props.staffLength, props.suspendedStaffLength]
  )

  const onChangeFreeWord = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newCondition: StaffListFilterCondition = {
        ...props.staffListFilterCondition,
        freeWord: e.target.value,
      }

      props.onUpdateStaffListFilterCondition(newCondition)
    },
    [props]
  )

  const buildStatusUpdater = (
    key: keyof typeof props.staffListFilterCondition.status
  ) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const newCondition = produce(props.staffListFilterCondition, (draft) => {
        draft.status[key] = e.target.checked
      })

      props.onUpdateStaffListFilterCondition(newCondition)
    }
  }
  return (
    <Wrapper>
      <StaffTableOptions
        options={statusOptions}
        activeTable={props.activeTable}
        onSelect={props.onSelectTable}
      />
      <SearchContainer>
        <Row>
          <CountRowInner>
            <Txt>該当数</Txt>
            <CountContainer>
              <Count>{digitSeparator(props.activeStaffCount)}</Count>
              <Unit>件</Unit>
            </CountContainer>
          </CountRowInner>
        </Row>
        <Row>
          <SearchHead>
            <Txt>検索</Txt>
            <ResetButton onClick={props.onResetStaffListFilterCondition}>
              初期状態に戻す
            </ResetButton>
          </SearchHead>
          <FreeWordContainer>
            <FreeWordTxt>フリーワード</FreeWordTxt>
            <InputText
              size="max"
              placeholder="氏名、メールアドレスで検索"
              value={props.staffListFilterCondition.freeWord}
              onChange={onChangeFreeWord}
            />
          </FreeWordContainer>
          {props.activeTable === 'staffs' && (
            <>
              <StatusTxt>ステータス</StatusTxt>
              <div>
                <StatusCheckBox
                  checked={
                    props.staffListFilterCondition.status.isRegisteredOrResumed
                  }
                  checkboxLabel="利用開始待ち / 再開待ち"
                  onChange={buildStatusUpdater('isRegisteredOrResumed')}
                />
                <StatusCheckBox
                  checked={props.staffListFilterCondition.status.isActivated}
                  checkboxLabel="利用開始済"
                  onChange={buildStatusUpdater('isActivated')}
                />
                <StatusCheckBox
                  checked={props.staffListFilterCondition.status.isExpired}
                  checkboxLabel="有効期限切れ"
                  onChange={buildStatusUpdater('isExpired')}
                />
              </div>
            </>
          )}
        </Row>
      </SearchContainer>
    </Wrapper>
  )
}

function digitSeparator(digit: number | undefined): string {
  if (typeof digit === undefined) {
    return ''
  }
  return String(digit).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

const Wrapper = styled.div`
  margin: 20px 16px 20px 0;

  > *:not(:first-child) {
    margin-top: 20px;
  }
`

const SearchContainer = styled.div`
  border-radius: 0 4px 4px 0;
  border: 1px solid ${theme.color.gray[3]};
  border-left-width: 0;
  background-color: ${theme.color.white[1]};
`

const Row = styled.div`
  padding: 10px 12px;

  &:first-child {
    border-bottom: 1px solid ${theme.color.gray[3]};
  }
`

const CountRowInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CountContainer = styled.div`
  display: flex;
  align-items: baseline;
`

const Count = styled(Txt).attrs({ size: 'xxl', color: theme.color.green[4] })``

const Unit = styled(Txt).attrs({ size: 'm' })`
  margin-left: 4px;
`

const SearchHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

const ResetButton = styled.button`
  ${buttonResetStyle};

  color: ${theme.color.navy[1]};
  font-size: ${theme.fontSize.xs};
  text-decoration: underline;
`

const FreeWordContainer = styled.div`
  margin-bottom: 16px;
`

const FreeWordTxt = styled(Txt).attrs({ size: 's' })`
  margin-bottom: 8px;
`

const StatusTxt = styled(Txt).attrs({ size: 's' })`
  margin-bottom: 8px;
`

const StatusCheckBox = styled(CheckBox)`
  &:not(:first-child) {
    margin-top: 16px;
  }
`
