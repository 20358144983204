import { useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useMutation, useQueryClient } from 'react-query'
import { useModal } from '@/hooks/useModal'
import { useRequestSendRegisterStaffMail } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export const useSendRegisterStaffMail = (guid: string) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useSendRegisterStaffMailMutation(guid)

  const sendRegisterStaffMailModal = useModal(false)

  const onSubmitSendRegisterStaffMail = useCallback(async () => {
    if (isLoading) return

    try {
      await mutateAsync()
    } catch (e) {
      Sentry.captureException(e)
      commonErrorToast()
      return
    }

    // TODO: テーマカラーをFigmaに揃える
    // TODO: '✗'ボタンを消す
    toast('利用開始メールを再送信しました')
    sendRegisterStaffMailModal.close()

    queryClient.refetchQueries([QUERY_KEY.getStaff, guid])
    queryClient.invalidateQueries(QUERY_KEY.listStaffs)
  }, [isLoading, sendRegisterStaffMailModal, queryClient, guid, mutateAsync])

  return {
    sendRegisterStaffMailModal,
    onSubmitSendRegisterStaffMail,
  }
}

function useSendRegisterStaffMailMutation(guid: string) {
  const { requestSendRegisterStaffMail } = useRequestSendRegisterStaffMail()

  return useMutation(() => requestSendRegisterStaffMail(guid))
}
