import { useCallback } from 'react'
import { RegisterOrganizationRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'

export type RegisterOrganizationRequestValue = {
  // Kylo から発行された初期ユーザー登録用トークン
  token: string
  // 企業名
  organizationName: string
  // 姓
  familyName: string
  // 名
  givenName: string
  // 姓カナ
  familyNameKana: string
  // 名カナ
  givenNameKana: string
  // 半角英数字記号で8文字以上
  password: string
}

export function useRequestRegisterOrganization(token: string) {
  const requestRegisterOrganization = useCallback(
    async (reqValue: RegisterOrganizationRequestValue) => {
      const req = new RegisterOrganizationRequest()

      req.setToken(reqValue.token)
      req.setOrganizationName(reqValue.organizationName)
      req.setFamilyName(reqValue.familyName)
      req.setGivenName(reqValue.givenName)
      req.setFamilyNameKana(reqValue.familyNameKana)
      req.setGivenNameKana(reqValue.givenNameKana)
      req.setPassword(reqValue.password)

      const metadata = { authorization: token || '' }

      return callBizAnakinBffService(
        'registerOrganization',
        [req, metadata],
        undefined
      )
    },
    [token]
  )

  return { requestRegisterOrganization }
}
