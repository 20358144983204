import React from 'react'
import {
  ErrorBox,
  Modal as _Modal,
  NewTabLink,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { EXTERNAL_PATHS } from '@/services/urlService'

type Props = {
  active: boolean
  onBackToInput: () => void
}

export const AlreadyExistsModal: React.VFC<Props> = (props) => {
  return (
    <Modal active={props.active} size="l" title="取込エラー">
      <Inner>
        <ErrorBox size="s">
          登録できないユーザーが存在します。
          <br />
          詳細は
          <NewTabLink
            href={EXTERNAL_PATHS.help.supportDesk}
            action="open_support_desk_on_batch_register_staffs_already_exists_modal"
          >
            サポートデスク
          </NewTabLink>
          までお問い合わせください。
        </ErrorBox>
      </Inner>
      <WeakColorSeparator />
      <Bottom>
        <TertiaryButton
          comlinkPushParams={{
            action: 'close_already_exists_modal_error_modal_button',
          }}
          widthSize="L1"
          onClick={props.onBackToInput}
        >
          入力画面に戻る
        </TertiaryButton>
      </Bottom>
    </Modal>
  )
}

const Modal = styled(_Modal)`
  max-height: 80%;
`

const Inner = styled.div`
  padding: 20px;
`

const Bottom = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
`
