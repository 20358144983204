import React from 'react'
import { TertiaryButton, PrimaryButton } from '@blue-agency/rogue/im'
import { SettingStaffRegisterByFormPageContainer } from '../SettingStaffRegisterByFormPageContainer'

export const cautionText =
  '登録したメールアドレスに、利用開始メールが送信されます。\nメールをご確認いただき、初回ログインの手続きを完了してください。'

export const Buttons = (): React.ReactElement => {
  const { toInputStep, registerStaff } =
    SettingStaffRegisterByFormPageContainer.useContainer()
  return (
    <>
      <TertiaryButton
        widthSize="L2"
        onClick={toInputStep}
        comlinkPushParams={{ action: 'back_users_page' }}
      >
        入力画面に戻る
      </TertiaryButton>
      <PrimaryButton
        widthSize="L2"
        type="submit"
        onClick={registerStaff}
        comlinkPushParams={{ action: 'click_user_register' }}
      >
        登録
      </PrimaryButton>
    </>
  )
}
