import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { SettingStaffEditPageContainer } from './SettingStaffEditPageContainer'

export const SettingStaffEditPage: React.VFC = provide(
  SettingStaffEditPageContainer
)(() => {
  usePromptBeforeUnload()

  const { content } = SettingStaffEditPageContainer.useContainer()

  return content
})
