import { RadioGroup } from '@blue-agency/rogue'
import styled from 'styled-components'
import { authnTypeToText } from './authnType'

export const AuthnTypeRadioGroup = styled(RadioGroup).attrs({
  options: [
    {
      value: 'password',
      label: authnTypeToText('password'),
      helperText: '「メールアドレス」「パスワード」でログインをおこないます。',
    },
    {
      value: 'microsoft',
      label: authnTypeToText('microsoft'),
      helperText: '「Microsoftアカウント」でログインをおこないます。',
    },
    {
      value: 'google',
      label: authnTypeToText('google'),
      helperText: '「Googleアカウント」でログインをおこないます。',
    },
  ],
  direction: 'column',
})``
