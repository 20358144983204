import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'

export const Table = styled.table`
  background-color: ${theme.color.white[1]};
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 73px;
`

export const TableHead = styled.thead`
  background-color: ${theme.color.navy[1]};
  position: sticky;
  top: 0;
`

export const TableBody = styled.tbody``

export const TableHeadCell = styled.th`
  position: sticky;
  top: 0;
  z-index: 1;

  color: ${theme.color.navy[1]};
  background: ${theme.color.navy[3]};
  font-size: ${theme.fontSize.m};
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 1.5;
  height: 44px;
`

export const TableBodyCell = styled.td`
  color: ${theme.color.navy[1]};
  font-size: ${theme.fontSize.m};
  min-height: 60px;
  height: 60px;
  padding: 0px 15px;
  vertical-align: middle;
  line-height: 1.5;
  overflow: hidden;
`

export const TableRow = styled.tr`
  display: flex;
  align-items: center;

  &:hover {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  }

  ${TableBody} & {
    border-bottom: 1px solid ${theme.color.gray[3]};
  }
`

export const TableHeadRow = styled.tr`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
`
