import {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
  DependencyList,
} from 'react'
import {
  StaffList,
  StaffSort,
  StaffSortKey,
  convertStaffSortToQueryString,
  useStaffListQueryParam,
} from '@/services/staffService'

export type StaffTable = 'staffs' | 'suspendedStaffs'

export const useStaffsTable = (_staffList: StaffList, sizePerPage: number) => {
  const { setQuery, staffSortParam, currentPageParam, filterConditionParam } =
    useStaffListQueryParam()
  const { sort, onSort } = useSort(staffSortParam)

  const staffList = useMemo(() => {
    _staffList.sort(sort.key, sort.order)
    return _staffList
  }, [sort.key, sort.order, _staffList])

  const { page, pageCount, onPageChange } = usePage(
    staffList,
    sizePerPage,
    currentPageParam,
    [filterConditionParam]
  )

  useEffect(() => {
    setQuery(
      {
        cp: page,
        ss: convertStaffSortToQueryString(sort),
      },
      'replaceIn'
    )
  }, [page, sort, setQuery])

  return {
    staffList,
    sort,
    onSort,
    page,
    pageCount,
    onPageChange,
  }
}

const defaultSort: StaffSort = {
  key: 'registeredAt',
  order: 'desc',
}

const useSort = (initialSort: StaffSort | undefined) => {
  const [sort, setSort] = useState<StaffSort>(initialSort ?? defaultSort)
  const onSort = useCallback(
    (key: StaffSortKey) => {
      setSort((prevState) => {
        if (prevState.key !== key)
          return {
            key,
            order: 'asc',
          }

        return {
          key: prevState.key,
          order: prevState.order === 'desc' ? 'asc' : 'desc',
        }
      })
    },
    [setSort]
  )

  return { sort, onSort }
}

const usePage = (
  staffList: StaffList,
  sizePerPage: number,
  initialPage: number,
  resetDeps: DependencyList
) => {
  const [page, setPage] = useState(initialPage ?? 1)

  const pageCount = useMemo(
    () => Math.ceil(staffList.staffs.length / sizePerPage),
    [sizePerPage, staffList.staffs.length]
  )

  const onPageChange = useCallback((selected: number) => {
    setPage(selected)
  }, [])

  /**
   * 検索条件が変わった時に1ページ目に戻す
   * queryからcurrentPageを復元する場合、queryの値で初期化した後に初回useEffectが走ると1ページ目に戻されてしまうので、
   * 初回レンダリングでは処理をスキップしてvalidatedFormValueに変更があった時のみ1ページ目に戻すようにする
   */
  const isFirstRenderingRef = useRef(true)
  useEffect(() => {
    if (isFirstRenderingRef.current) {
      isFirstRenderingRef.current = false
      return
    }
    setPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, resetDeps)

  return { page, pageCount, onPageChange }
}
