import * as yup from 'yup'
import {
  passwordSchema,
  newPasswordConfirmationSchema,
} from '@/services/formService'
import { Form } from './useEditPassword'

export const validationSchema: yup.SchemaOf<Form> = yup.object({
  currentPassword: passwordSchema,
  newPassword: passwordSchema,
  newPasswordConfirm: newPasswordConfirmationSchema('newPassword'),
})
