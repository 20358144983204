import React from 'react'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Staff } from '@/services/staffService'
import { Form } from '../../SettingStaffEditPageContainer'
import { AuthzTypeSection } from './AuthzTypeSection'
import { EmailSection } from './EmailSection'
import { NameSection } from './NameSection'

type Props = PropsWithClassName<{
  completedForm: Form
  staff: Staff
}>

export const Body: React.VFC<Props> = ({ className, completedForm, staff }) => {
  return (
    <div className={className}>
      <NameSection
        familyName={completedForm.familyName}
        givenName={completedForm.givenName}
        familyNameKana={completedForm.familyNameKana}
        givenNameKana={completedForm.givenNameKana}
      />
      <EmailSection staff={staff} />
      <AuthzTypeSection authzType={completedForm.authzType} />
    </div>
  )
}
