import React from 'react'
import {
  PageLayoutWithGlonavi,
  DetailPage as Page,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { Txt } from '@blue-agency/rogue'
import { Link, useParams } from 'react-router-dom'
import { useUnmount, useMount } from 'react-use'
import styled from 'styled-components'
import { Loading } from '@/components/Loading'
import { useStaffListQueryParam } from '@/services/staffService'
import { INTERNAL_PATHS } from '@/services/urlService/internalPaths'
import { Content } from './Content'
import { useStaff } from './useStaff'

export const SettingStaffDetailPage: React.VFC = () => {
  const { guid } = useParams<{ guid: string }>()

  const { isLoading, staff } = useStaff(guid)

  const { getQueryFromLocalStorage, clearStorages } = useStaffListQueryParam()

  useUnmount(() => {
    clearStorages(guid)
  })

  useMount(() => {
    window.addEventListener('beforeunload', () => {
      clearStorages(guid)
    })
  })

  if (isLoading) return <Loading />
  if (staff === undefined) throw new Error('staff is undefined')

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>ユーザー詳細</Page.HeaderTitle>
          <Page.HeaderRight>
            <LinkTo
              to={{
                pathname: INTERNAL_PATHS.settings.staffs.index,
                search: getQueryFromLocalStorage(guid),
              }}
            >
              <Txt>一覧に戻る</Txt>
            </LinkTo>
          </Page.HeaderRight>
        </Page.Header>
        <Page.Body>
          <Content staff={staff} />
        </Page.Body>
      </Page.Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const LinkTo = styled(Link)`
  color: ${theme.color.navy[1]};
`
