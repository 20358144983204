import React from 'react'
import { TertiaryButton, PrimaryButton } from '@blue-agency/rogue/im'
import { Link } from '@/components/Link'
import { INTERNAL_PATHS } from '@/services/urlService'
import { SettingStaffRegisterByFormPageContainer } from '../SettingStaffRegisterByFormPageContainer'

export const Buttons = (): React.ReactElement => {
  const { onSubmit } = SettingStaffRegisterByFormPageContainer.useContainer()
  return (
    <>
      <Link to={INTERNAL_PATHS.settings.staffs.registerSelect}>
        <TertiaryButton
          widthSize="L2"
          comlinkPushParams={{ action: 'back_to_staff_register_select_page' }}
        >
          選択画面に戻る
        </TertiaryButton>
      </Link>
      <PrimaryButton
        widthSize="L2"
        type="submit"
        onClick={onSubmit}
        comlinkPushParams={{
          action: 'click_confirm_register_staff_body_button',
        }}
      >
        内容を確認
      </PrimaryButton>
    </>
  )
}
