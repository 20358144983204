import React from 'react'
import { AuthnTypeSection } from '@/components/AuthnTypeSection'
import { RegisterStaffRequestValue } from '@/services/bffService'
import { AuthzTypeSection } from './AuthzTypeSection'
import { EmailSection } from './EmailSection'
import { NameSection } from './NameSection'

type Props = { completedForm: RegisterStaffRequestValue }

export const Body: React.VFC<Props> = (props) => {
  return (
    <>
      <NameSection
        familyName={props.completedForm.familyName}
        givenName={props.completedForm.givenName}
        familyNameKana={props.completedForm.familyNameKana}
        givenNameKana={props.completedForm.givenNameKana}
      />
      <EmailSection email={props.completedForm.email} />
      <AuthnTypeSection authnType={props.completedForm.authnType} />
      <AuthzTypeSection authzType={props.completedForm.authzType} />
    </>
  )
}
