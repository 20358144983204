import React, { useState, useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import * as Sentry from '@sentry/react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useSearchParameters } from '@/hooks/useSearchParams'
import { CustomBizAnakinBffGrpcError } from '@/services/bffService'
import { useRequestRegisterOrganization } from '@/services/bffService/useRequestRegisterOrganization'
import { useRequestVerifyRegisterToken } from '@/services/bffService/useRequestVerifyRegisterToken'
import { QUERY_KEY } from '@/services/queryKeyService'
import { RegisterOrganizationForm } from './formScheme'
import { ConfirmStep } from './steps/ConfirmStep'
import { InputStep } from './steps/InputStep'

type Step = 'input' | 'confirm'

export function useRegisterOrganization() {
  const { token } = useSearchParameters<{ token: string }>()
  if (token === undefined) {
    throw new Error('token not found')
  }

  const { requestVerifyRegisterToken } = useRequestVerifyRegisterToken()
  const { requestRegisterOrganization } = useRequestRegisterOrganization(token)

  const [step, setStep] = useState<Step>('input')
  const [completedForm, setCompletedForm] = useState<
    RegisterOrganizationForm | undefined
  >()
  const [hasOccurredTooWeakPasswordError, setHasOccurredTooWeakPasswordError] =
    useState(false)

  // NOTE: verify が失敗したとき例外を投げる
  // 正常なときの戻り値はいらない
  useQuery([QUERY_KEY.verifyRegisterToken, token], () => {
    return requestVerifyRegisterToken(token)
  })

  const history = useHistory()

  const handleInputComplete = useCallback((form: RegisterOrganizationForm) => {
    setHasOccurredTooWeakPasswordError(false)
    setCompletedForm(form)
    setStep('confirm')
  }, [])

  const goToInput = useCallback(() => {
    setStep('input')
  }, [])

  const register = useCallback(async () => {
    if (!completedForm) {
      return
    }

    const clearPassword = () => {
      setCompletedForm((prev) => {
        if (prev) {
          return {
            ...prev,
            password: '',
            passwordConfirmation: '',
          }
        } else {
          return undefined
        }
      })
    }

    try {
      await requestRegisterOrganization({ ...completedForm, token })
    } catch (e) {
      if (e instanceof CustomBizAnakinBffGrpcError) {
        if (
          e.hasMatchErrorDetail(
            'proton.v2.biz_anakin_bff.TooWeakPasswordErrorDetail'
          )
        ) {
          clearPassword()
          setStep('input')
          setHasOccurredTooWeakPasswordError(true)
          return
        }
      }

      Sentry.captureException(e)

      commonErrorToast()
      return
    }

    alert('アカウント発行が完了しました。\nご利用を開始いただけます。')
    history.push('/')
  }, [completedForm, history, requestRegisterOrganization, token])

  const renderStep = () => {
    switch (step) {
      case 'input':
        return (
          <InputStep
            form={completedForm}
            onComplete={handleInputComplete}
            hasOccurredTooWeakPasswordError={hasOccurredTooWeakPasswordError}
          />
        )
      case 'confirm':
        if (!completedForm) {
          throw new Error('completed form not found')
        }
        return (
          <ConfirmStep
            form={completedForm}
            onComplete={register}
            onBackStep={goToInput}
          />
        )
    }
  }

  return {
    renderStep,
  }
}
