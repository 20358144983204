import React from 'react'
import { PageLayout } from '@blue-agency/im-shared-front'
import { ErrorBox, NewTabLink } from '@blue-agency/rogue'
import styled from 'styled-components'
import { ErrorTxt } from '@/components/ErrorTxt'
import { EXTERNAL_PATHS } from '@/services/urlService'

export const InvalidArgument: React.VFC = () => {
  return (
    <PageLayout disableLogoLink>
      <Wrapper>
        <ErrorBox>
          <ErrorTxt bold>メール認証エラー</ErrorTxt>
          <ErrorTxt>
            URLが不適切なため、ページを表示できません。
            <br />
            <NewTabLink
              href={EXTERNAL_PATHS.help.registerOrganizationError}
              action="open_support_desk_from_register-organization-error"
            >
              サポートデスク
            </NewTabLink>
            までお問い合わせください。
          </ErrorTxt>
        </ErrorBox>
      </Wrapper>
    </PageLayout>
  )
}

const Wrapper = styled.div`
  margin: 40px auto 0;
  display: flex;
  justify-content: center;
`
