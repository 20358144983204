import React, { useCallback, useState, useMemo } from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { theme, toast } from '@blue-agency/rogue'
import { Txt } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { Link } from 'react-router-dom'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { assertNever } from '@/assertions'
import { Loading } from '@/components/Loading'
import { useListWebInterviewGuides } from '@/pages/APISettingsDetailV2EditPage/useListWebInterviewGuidesForLink'
import { INTERNAL_PATHS } from '@/services/urlService/internalPaths'
import { useGetAtsConnectV1V2 } from '../APISettingsDetailV2Page/useGetAtsConnectV1V2'
import { Confirm } from './Confirm'
import { Input } from './Input'
import { useUpdateAtsConnectV1V2 } from './useUpdateAtsConnectV1V2'

type Step = 'input' | 'confirm'
export type FormType = {
  name: string
  note?: string
  defaultWebInterviewGuideGuid?: string
}
export type AtsConnect = {
  name: string
  note?: string
  defaultWebInterviewGuide?: {
    guid: string
    name: string
  }
}

export const APISettingsDetailV2EditPage: React.VFC = () => {
  const { guid } = useParams<{ guid: string }>()
  const history = useHistory()

  const [step, setStep] = useState<Step>('input')

  const getAtsConnectV1V2Result = useGetAtsConnectV1V2(guid)
  const { mutateAsync: updateAtsConnectV2 } = useUpdateAtsConnectV1V2()
  const listWebInterviewGuidesResult = useListWebInterviewGuides()

  const isLoading = useMemo(() => {
    return (
      getAtsConnectV1V2Result.isLoading ||
      listWebInterviewGuidesResult.isLoading
    )
  }, [getAtsConnectV1V2Result, listWebInterviewGuidesResult])

  const atsConnect = useMemo<AtsConnect | undefined>(() => {
    return (
      getAtsConnectV1V2Result.data && {
        name: getAtsConnectV1V2Result.data.getName(),
        note: getAtsConnectV1V2Result.data.getNote(),
        defaultWebInterviewGuide:
          getAtsConnectV1V2Result.data.getDefaultWebInterviewGuide() && {
            guid: getAtsConnectV1V2Result.data
              .getDefaultWebInterviewGuide()!
              .getGuid(),
            name: getAtsConnectV1V2Result.data
              .getDefaultWebInterviewGuide()!
              .getName(),
          },
      }
    )
  }, [getAtsConnectV1V2Result])

  const guideList = useMemo(
    () => listWebInterviewGuidesResult.guideList,
    [listWebInterviewGuidesResult]
  )

  const [form, setForm] = useState<FormType>({
    name: '',
  })

  const backToDetailPage = useCallback(() => {
    history.push(generatePath(INTERNAL_PATHS.apiSettings.detailV2, { guid }))
  }, [history, guid])

  const confirm = useCallback((form: FormType) => {
    setForm(form)
    setStep('confirm')
  }, [])

  const backToInput = useCallback(() => {
    setStep('input')
  }, [])

  const save = useCallback(async () => {
    try {
      await updateAtsConnectV2({
        guid,
        name: form.name ?? '',
        note: form.note ?? '',
        defaultWebInterviewGuideGuid: form.defaultWebInterviewGuideGuid ?? '',
      })
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }
    toast('連携設定情報を保存しました')
    backToDetailPage()
  }, [backToDetailPage, updateAtsConnectV2, form, guid])

  const guideNameMap = useMemo(() => {
    const nameMap = Object.fromEntries(
      guideList.map((guide) => [guide.guid, guide.name])
    )
    // 現在設定されている面接ガイドがArchiveされている場合、一覧の中に含まれない。
    // 設定されている面接ガイドのguidとnameも登録し、確認画面で表示できるようにする。
    if (atsConnect?.defaultWebInterviewGuide !== undefined) {
      nameMap[atsConnect.defaultWebInterviewGuide.guid] =
        atsConnect.defaultWebInterviewGuide.name
    }
    return nameMap
  }, [guideList, atsConnect])

  if (isLoading) {
    return <Loading />
  }

  const content = (() => {
    switch (step) {
      case 'input':
        return (
          <Input
            currentAtsConnect={atsConnect}
            guideList={guideList}
            onSubmit={confirm}
            onCancel={backToDetailPage}
          />
        )
      case 'confirm':
        if (form === undefined) throw new Error('form is undefined')
        return (
          <Confirm
            form={form}
            guideNameMap={guideNameMap}
            onSubmit={save}
            onBackToInput={backToInput}
          />
        )
      default:
        assertNever(step)
    }
  })()

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>API連携設定編集</Page.HeaderTitle>
          <Page.HeaderRight>
            <LinkTo to={INTERNAL_PATHS.apiSettings.index}>
              <Txt>一覧に戻る</Txt>
            </LinkTo>
          </Page.HeaderRight>
        </Page.Header>
        {content}
      </Page.Wrapper>
    </PageLayout>
  )
}

const LinkTo = styled(Link)`
  color: ${theme.color.navy[1]};
`
