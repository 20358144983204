import React from 'react'
import { Modal } from '@/components/Modal'

type Props = {
  name: string
  active: boolean
  onClose: () => void
  onSubmit: () => void
}

export const DeleteIpFilterModal: React.VFC<Props> = ({
  name,
  active,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal.Wrapper
      active={active}
      title="フィルター情報の削除"
      stopScroll
      onClose={onClose}
    >
      <Modal.Body>
        <Modal.Txt>「{name}」を削除します。</Modal.Txt>
        <Modal.Txt>削除したデータを復元することはできません。</Modal.Txt>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_delete_ipfilter_button',
          }}
        >
          キャンセル
        </Modal.CancelButton>
        <Modal.DangerSubmitButton
          onClick={onSubmit}
          comlinkPushParams={{
            action: 'click_submit_delete_ipfilter_button',
          }}
        >
          削除
        </Modal.DangerSubmitButton>
      </Modal.Footer>
    </Modal.Wrapper>
  )
}
