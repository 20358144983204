import { useLocation } from 'react-router-dom'

type Constraint = {
  [k: string]: string
}

export function useSearchParameters<T extends Constraint>() {
  type Response = Partial<T>

  const params = new URLSearchParams(useLocation().search)

  const res: { [key: string]: string } = {}

  for (const [key, value] of params.entries()) {
    res[key] = value
  }

  return res as Response
}
