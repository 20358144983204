import React, { useCallback, useState } from 'react'
import {
  authzTypeOf,
  commonErrorToast,
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { theme, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { LineClampedTxt } from '@/components/LineClampedTxt'
import { SettingsSection } from '@/components/SettingsSection'
import { authnTypeToText } from '@/components/authnType'
import { useModal } from '@/hooks/useModal'
import { authnTypeOf } from '@/services/authnService'
import {
  CustomBizAnakinBffGrpcError,
  useRequestBatchRegisterStaffs,
} from '@/services/bffService'
import { ErrorModal } from '../ErrorModal'
import { StaffDraft } from '../StaffDraft'
import { AlreadyExistsModal } from './AlreadyExistsModal'

type Props = {
  staffDrafts: StaffDraft[]
  goBackToInput: () => void
  goNext: () => void
}

const limit = 10

type BffValidationError = {
  rowNumber: number
  message: string
}

export const Confirm: React.VFC<Props> = (props) => {
  const { requestBatchRegisterStaffs } = useRequestBatchRegisterStaffs()

  const [bffValidationErrors, setBffValidationErrors] =
    useState<BffValidationError[]>()
  const alreadyExistsModal = useModal()

  const onNextButtonClick = useCallback(async () => {
    try {
      await requestBatchRegisterStaffs(props.staffDrafts)
    } catch (e) {
      if (e instanceof CustomBizAnakinBffGrpcError && e.isAlreadyExists) {
        if (e.hasMatchErrorDetail('STAFF_EMAIL_ALREADY_EXISTS')) {
          const metadata = e.getErrorDetailMetadataMap(
            'STAFF_EMAIL_ALREADY_EXISTS'
          )

          if (metadata !== undefined) {
            // エラーの中身は ↓ を参照
            // https://github.com/blue-agency/biz-anakin-bff/pull/112/files#r598468447
            type Index = string
            type Email = string
            const ary = metadata.toArray() as Array<[Index, Email]>

            // 辞書順から数値の昇順にソートする
            ary.sort((a, b) => Number(a[0]) - Number(b[0]))

            const errors: BffValidationError[] = ary.map((value) => {
              return {
                rowNumber: Number(value[0]) + 1,
                message: value[1] + 'のメールアドレスは利用できません。',
              }
            })

            setBffValidationErrors(errors)
            return
          } else {
            alreadyExistsModal.open()
            return
          }
        }
      }
      commonErrorToast()
      return
    }
    props.goNext()
  }, [alreadyExistsModal, props, requestBatchRegisterStaffs])

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper bottomHeight={127}>
        <Page.Header>
          <Page.HeaderTitle>ユーザー一括登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="登録内容の確認">
            <SectionInner>
              <CountWrapper>
                <Txt>登録対象件数 </Txt>
                <CountWrapperInner>
                  <Count>{props.staffDrafts.length}</Count>
                  <CountUnit>件</CountUnit>
                </CountWrapperInner>
              </CountWrapper>
              <Txt>※取込詳細は最大{limit}件の表示となります。</Txt>
              <SeparatorWrapper>
                <WeakColorSeparator />
              </SeparatorWrapper>
              <Content>
                <Label>取込詳細</Label>
                <TableWrapper>
                  <Table>
                    <Thead>
                      <tr>
                        <Th width={6}>
                          <Txt>姓</Txt>
                        </Th>
                        <Th width={6}>
                          <Txt>名</Txt>
                        </Th>
                        <Th width={8}>
                          <Txt>姓(かな)</Txt>
                        </Th>
                        <Th width={8}>
                          <Txt>名(かな)</Txt>
                        </Th>
                        <Th width={22}>
                          <Txt>メールアドレス</Txt>
                        </Th>
                        <Th width={16}>
                          <Txt>認証方法</Txt>
                        </Th>
                        <Th width={10}>
                          <Txt>権限タイプ</Txt>
                        </Th>
                      </tr>
                    </Thead>
                    <tbody>
                      {props.staffDrafts
                        .slice(0, limit)
                        .map((staffDraft, i) => (
                          <TbodyTr key={i}>
                            <Td>
                              <LineClampedTxt line={2}>
                                {staffDraft.familyName}
                              </LineClampedTxt>
                            </Td>
                            <Td>
                              <LineClampedTxt line={2}>
                                {staffDraft.givenName}
                              </LineClampedTxt>
                            </Td>
                            <Td>
                              <LineClampedTxt line={2}>
                                {staffDraft.familyNameKana}
                              </LineClampedTxt>
                            </Td>
                            <Td>
                              <LineClampedTxt line={2}>
                                {staffDraft.givenNameKana}
                              </LineClampedTxt>
                            </Td>
                            <Td>
                              <LineClampedTxt line={2}>
                                {staffDraft.mail}
                              </LineClampedTxt>
                            </Td>
                            <Td>
                              <LineClampedTxt line={2}>
                                {authnTypeToText(
                                  authnTypeOf(staffDraft.authnType).type
                                )}
                              </LineClampedTxt>
                            </Td>
                            <Td>
                              <LineClampedTxt line={2}>
                                {authzTypeOf(staffDraft.authzType).name}
                              </LineClampedTxt>
                            </Td>
                          </TbodyTr>
                        ))}
                    </tbody>
                  </Table>
                </TableWrapper>
              </Content>
            </SectionInner>
          </SettingsSection>
        </Page.Body>
        {bffValidationErrors && (
          <ErrorModal
            // フロントのバリデーションに成功して、BFF にリクエストを送っているとき、次のエラーパターンしかありえない前提
            headerMessage={
              <>
                既に登録済のユーザーのメールアドレスが存在しています。
                <br />
                該当ユーザーをご確認の上、ファイルを修正して再度アップロードしてください。
              </>
            }
            errorMessages={bffValidationErrors}
            errorLimit={10}
            active={bffValidationErrors.length > 0}
            onBackToInput={props.goBackToInput}
          />
        )}
        <AlreadyExistsModal
          active={alreadyExistsModal.active}
          onBackToInput={props.goBackToInput}
        />
        <Page.Bottom>
          <Page.BottomCautionText>
            登録したメールアドレスに、利用開始メールが送信されます。
            <br />
            メールをご確認いただき、ログインしてください。
          </Page.BottomCautionText>
          <Page.BottomButtons>
            <TertiaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: 'click_back_to_file_select',
              }}
              onClick={props.goBackToInput}
            >
              入力画面に戻る
            </TertiaryButton>
            <PrimaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: 'click_next_button_on_confirm_data',
              }}
              onClick={onNextButtonClick}
            >
              登録
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const SectionInner = styled.div`
  padding: 24px 20px;
`

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const CountWrapperInner = styled.div`
  margin-left: 10px;
`

const Count = styled.span`
  color: ${theme.color.green[4]};
  font-size: ${theme.fontSize.xxl};
`

const CountUnit = styled.span`
  margin-left: 4px;
  color: ${theme.color.navy[1]};
  font-size: ${theme.fontSize.m};
`

const SeparatorWrapper = styled.div`
  margin: 20px 0px;
`

const Content = styled.div`
  display: flex;
`

const Label = styled(Txt).attrs({ size: 'm' })`
  word-break: keep-all;
`

const TableWrapper = styled.div`
  margin-left: 48px;
  width: 100%;
`

const Table = styled.table``

const Thead = styled.thead`
  background-color: ${theme.color.gray[4]};
`

const TbodyTr = styled.tr`
  &:nth-child(even) {
    background-color: ${theme.color.gray[5]};
  }
`

const Th = styled.th<{ width: number }>`
  width: ${({ width }) => width}%;
  text-align: left;
  padding: 12px;
`

const Td = styled.td`
  padding: 12px;
`
