import React from 'react'
import {
  PageLayoutWithGlonavi,
  DetailPage as Page,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { Txt } from '@blue-agency/rogue'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { INTERNAL_PATHS } from '@/services/urlService/internalPaths'
import { Content } from './Content'

export const APISettingsDetailV2Page: React.VFC = () => {
  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>API連携設定詳細</Page.HeaderTitle>
          <Page.HeaderRight>
            <LinkTo to={INTERNAL_PATHS.apiSettings.index}>
              <Txt>一覧に戻る</Txt>
            </LinkTo>
          </Page.HeaderRight>
        </Page.Header>
        <Page.Body>
          <Content />
        </Page.Body>
      </Page.Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const LinkTo = styled(Link)`
  color: ${theme.color.navy[1]};
`
