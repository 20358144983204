import React from 'react'
import styled from 'styled-components'
import { PropsWithChildren, PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName &
  PropsWithChildren & {
    sidebar?: React.ReactNode
  }

export const ListContentLayout = (props: Props): React.ReactElement => {
  return (
    <Wrapper unSidebar={!props.sidebar}>
      <Content>{props.children}</Content>
      <Sidebar>{props.sidebar}</Sidebar>
    </Wrapper>
  )
}
type WrapperProps = { unSidebar: boolean }
const Wrapper = styled.div<WrapperProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.unSidebar ? '0 center' : '240px 1fr'};
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar content';

  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Content = styled.main`
  grid-area: content;
  overflow: hidden;
`

const Sidebar = styled.aside`
  grid-area: sidebar;
  overflow-y: auto;
`
