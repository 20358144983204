import React from 'react'
import { FixedBottomFormPage as Page } from '@blue-agency/im-shared-front'
import { Txt } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { SettingsSection } from '@/components/SettingsSection'
import { FormType, Permission } from '.'
import { sharingTypeOptions } from './Input'
import * as C from './components'
import { SelectionStepMaster } from './useSelectionStepMastersList'

type Props = {
  form: FormType
  onBackToInput: () => void
  onSubmit: () => void
  selectionStepMasters: SelectionStepMaster[]
  guideOptions: { label: string; value: string }[]
  permission: Permission
}
export const Confirm: React.VFC<Props> = (props) => {
  const recInterviewSetting = props.selectionStepMasters.find(
    (v) => v.guid === props.form.recInterviewSetting.selectionStepMasterGUID
  )
  const selectecSharingType = sharingTypeOptions.find(
    (v) => v.value === props.form.applicantSetting.sharingType
  )
  const webInterviewGuide = props.guideOptions.find(
    (v) =>
      v.value === props.form.webInterviewSetting.defaultWebInterviewGuideGUID
  )

  return (
    <>
      <Page.Body>
        <SettingsSection title="連携設定情報">
          <C.FormSection>
            <C.StyledFormRow title="連携先">
              <Txt>{props.form.name}</Txt>
            </C.StyledFormRow>
            <C.Separator />
            <C.StyledFormRow title="メモ">
              <Txt>{props.form.note}</Txt>
            </C.StyledFormRow>
            {props.permission.recInterviewEnabled && (
              <>
                <C.Separator />
                <C.StyledFormRow title="応募者" subtitle="一般権限への共有">
                  <Txt>{selectecSharingType?.label}</Txt>
                </C.StyledFormRow>
              </>
            )}
            {props.permission.webInterviewEnabled && (
              <>
                <C.Separator />
                <C.StyledFormRow title="Web面接" subtitle="Web面接ガイド">
                  <Txt>{webInterviewGuide?.label ?? ''}</Txt>
                </C.StyledFormRow>
              </>
            )}
            {props.permission.recInterviewEnabled && (
              <>
                <C.Separator />
                <C.StyledFormRow title="録画面接" subtitle="選考ステップ">
                  <Txt>{recInterviewSetting?.name ?? ''}</Txt>
                </C.StyledFormRow>
                <C.StyledFormRow subtitle="メール自動送信">
                  <Txt>{props.form.recInterviewSetting.requestMail}</Txt>
                </C.StyledFormRow>
              </>
            )}
            {props.permission.videoSeminarEnabled && (
              <>
                <C.Separator />
                <C.StyledFormRow title="ビデオセミナー">
                  <Txt>現在設定メニューはありません</Txt>
                </C.StyledFormRow>
              </>
            )}
          </C.FormSection>
        </SettingsSection>
      </Page.Body>
      <Page.Bottom>
        <Page.BottomButtons>
          <TertiaryButton
            onClick={props.onBackToInput}
            comlinkPushParams={{
              action: 'click_back_to_input_button_on_api_settings_v3_edit_page',
            }}
            widthSize="L2"
          >
            入力画面に戻る
          </TertiaryButton>
          <PrimaryButton
            onClick={props.onSubmit}
            comlinkPushParams={{
              action: 'click_save_button_on_api_settings_v3_edit_page',
            }}
            widthSize="L2"
          >
            保存
          </PrimaryButton>
        </Page.BottomButtons>
      </Page.Bottom>
    </>
  )
}
