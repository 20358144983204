import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListStaffsRequest } from '@blue-agency/proton/biz_anakin_bff'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export type ListStaffsRequestOptions = {
  suspended: boolean
}

export function useRequestListStaffs() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListStaffs = useCallback(
    async (opt: ListStaffsRequestOptions) => {
      const metadata = await buildMetadata()
      const req = new ListStaffsRequest()
      if (opt?.suspended) {
        req.setSuspended(new BoolValue().setValue(true))
      }

      return callBizAnakinBffService(
        'listStaffs',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestListStaffs }
}
