import React from 'react'
import { AuthzType, authzTypeOf } from '@blue-agency/im-shared-front'
import { Txt } from '@blue-agency/rogue'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Form } from '@/components/Form'

type Props = PropsWithClassName<{
  authzType: AuthzType['type']
}>

export const AuthzTypeSection: React.VFC<Props> = ({
  authzType,
  className,
}) => {
  return (
    <Form.Section className={className} unBordered>
      <Form.Row>
        <Form.Title>権限タイプ</Form.Title>
        <Form.Item>
          <Form.Field>
            <Txt>{authzTypeOf(authzType).name}</Txt>
          </Form.Field>
        </Form.Item>
      </Form.Row>
    </Form.Section>
  )
}
