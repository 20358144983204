import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UnregisterStaffRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestUnregisterStaff() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUnregisterStaff = useCallback(
    async (guid: string) => {
      const metadata = await buildMetadata()

      const req = new UnregisterStaffRequest()
      req.setGuid(guid)

      return callBizAnakinBffService(
        'unregisterStaff',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestUnregisterStaff }
}
