import React from 'react'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { useAuthz } from '@/services/authzService'
import { Completed } from './Completed'
import { Confirm } from './Confirm'
import { Input } from './Input'
import { useSettingStaffRegisterByFilePage } from './useSettingStaffRegisterByFilePage'

export const SettingStaffRegisterByFilePage: React.VFC = () => {
  const { authorize } = useAuthz()
  authorize()

  usePromptBeforeUnload()

  const ctx = useSettingStaffRegisterByFilePage()

  switch (ctx.step) {
    case 'input':
      return <Input goNext={ctx.goNext} />
    case 'confirm':
      return (
        <Confirm
          staffDrafts={ctx.staffDrafts}
          goBackToInput={ctx.goBackToInput}
          goNext={ctx.goNext}
        />
      )
    case 'completed':
      return <Completed staffDrafts={ctx.staffDrafts} />
  }
}
