import { useMutation, useQueryClient } from 'react-query'
import { useRequestResumeAtsConnect } from '@/services/bffService/useRequestResumeAtsConnect'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useResumeAtsConnect(guid: string) {
  const queryClient = useQueryClient()
  const { requestResumeAtsConnect } = useRequestResumeAtsConnect()

  return useMutation(() => requestResumeAtsConnect(guid), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.apiSettings)
      queryClient.invalidateQueries([QUERY_KEY.atsConnectV1V2, guid])
    },
  })
}
