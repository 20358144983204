import { useMemo } from 'react'
import { authzTypeFromProto } from '@blue-agency/im-shared-front'
import { useQuery } from 'react-query'
import {
  ListStaffsRequestOptions,
  useRequestListStaffs,
} from '@/services/bffService'
import { authnTypeFromProto } from '@/services/bffService/authnType'
import { dateOf } from '@/services/bffService/date'
import { QUERY_KEY } from '@/services/queryKeyService'
import { Staff } from '@/services/staffService'
import { nameKanaOf, nameOf } from '@/services/staffService/name'
import { Status } from '@/services/staffService/status'

export const useStaffs = () => {
  const { isLoading, data } = useListStaffsQuery({ suspended: false })

  const staffs: Staff[] = useMemo<Staff[]>(() => {
    if (isLoading || data === undefined) return []
    return data
      .getStaffsList()
      .filter((staff) => staff !== undefined)
      .map(
        (staff): Staff => ({
          guid: staff.getGuid(),
          email: staff.getEmail(),
          name: nameOf(staff.getName()),
          nameKana: nameKanaOf(staff.getNameKana()),
          registeredAt: dateOf(staff.getRegisteredAt()),
          authzType: authzTypeFromProto(staff.getAuthzType()),
          status: Status.ofListStaffsResponse(staff.getStatus()),
          isRegistrationExpired: staff.getIsRegistrationExpired(),
          suspended: staff.getSuspended(),
          iconColorCode: staff.getIconColorCode(),
          authnType: authnTypeFromProto(staff.getAuthnType()),
        })
      )
  }, [isLoading, data])

  return {
    isLoading,
    staffs,
  }
}

function useListStaffsQuery(opt: ListStaffsRequestOptions) {
  const { requestListStaffs } = useRequestListStaffs()

  return useQuery([QUERY_KEY.listStaffs, opt], () => requestListStaffs(opt))
}
