import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetOrganizationRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetOrganization() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetOrganization = useCallback(async () => {
    const req = new GetOrganizationRequest()
    const metadata = await buildMetadata()

    return callBizAnakinBffService(
      'getOrganization',
      [req, metadata],
      loggedInStaff?.guid
    )
  }, [buildMetadata, loggedInStaff])

  return { requestGetOrganization }
}
