import React from 'react'
import {
  ErrorBox,
  Modal as _Modal,
  theme,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'

type ErrorMessage = {
  rowNumber: number
  message: string
}

type Props = {
  active: boolean
  errorLimit: number
  errorMessages: ErrorMessage[]
  headerMessage: React.ReactElement
  onClose?: () => void
  onBackToInput?: () => void
}

export const ErrorModal: React.VFC<Props> = (props) => {
  return (
    <Modal
      active={props.active}
      onClose={props.onClose}
      size="l"
      title="取込エラー"
    >
      <Inner>
        <ErrorBox>{props.headerMessage}</ErrorBox>
        <AnnotationWrapper>
          <Txt>※エラー対象は最大{props.errorLimit}件の表示となります。</Txt>
        </AnnotationWrapper>
        <Header>
          <Row>
            <LineCol>
              <Txt>エラー対象</Txt>
            </LineCol>
            <MessageCol>
              <Txt>エラー内容</Txt>
            </MessageCol>
          </Row>
        </Header>
        <Body>
          {props.errorMessages.slice(0, props.errorLimit).map((error, i) => (
            <Row key={i}>
              <LineCol>
                <Txt color={theme.color.red[2]}>{error.rowNumber}人目</Txt>
              </LineCol>
              <MessageCol>
                <Txt color={theme.color.red[2]}>{error.message}</Txt>
              </MessageCol>
            </Row>
          ))}
        </Body>
      </Inner>
      <WeakColorSeparator />
      <Bottom>
        <TertiaryButton
          comlinkPushParams={{ action: 'close_csv_error_modal_button' }}
          widthSize="L1"
          onClick={props.onBackToInput}
        >
          入力画面に戻る
        </TertiaryButton>
      </Bottom>
    </Modal>
  )
}

const Modal = styled(_Modal)`
  max-height: 80%;
`

const Inner = styled.div`
  padding: 20px;
`

const AnnotationWrapper = styled.div`
  margin-top: 16px;
`

const Header = styled.div`
  background-color: ${theme.color.gray[4]};
  margin-top: 16px;
`

const Row = styled.div`
  display: flex;
  min-height: 40px;
  padding: 16px 0;
  align-items: center;
`

const LineCol = styled.div`
  padding: 0 20px;
  width: 160px;
`

const MessageCol = styled.div`
  padding: 0 20px;
  flex: 1;
`

const Body = styled.div`
  > ${Row}:nth-child(even) {
    background-color: ${theme.color.gray[5]};
  }
`

const Bottom = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
`
