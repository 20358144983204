import { useState, useEffect, useMemo, useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { WindowSizeContainer } from './WindowSizeContainer'

type DeviceType = 'pc' | 'sp'

function useDeviceType() {
  const { width } = WindowSizeContainer.useContainer()

  const getDeviceType = useCallback<() => DeviceType>(
    () => (width > 1024 ? 'pc' : 'sp'),
    [width]
  )

  const [deviceType, setDeviceType] = useState<DeviceType>(() =>
    getDeviceType()
  )

  const isPc = useMemo(() => deviceType === 'pc', [deviceType])
  const isSp = useMemo(() => deviceType === 'sp', [deviceType])

  useEffect(() => {
    const deviceType = getDeviceType()
    setDeviceType(deviceType)
  }, [getDeviceType])

  return {
    deviceType,
    isPc,
    isSp,
  }
}

export const DeviceTypeContainer = createContainer(useDeviceType)
