import {
  // eslint-disable-next-line rulesdir/use-extended-custom-grpc-error
  CustomGrpcError,
  GrpcError,
  PermissionDenied,
} from '@blue-agency/im-shared-front'
import { Code } from '@blue-agency/proton/web/google/rpc/code_pb'
import { ErrorReason } from '@blue-agency/proton/web/v2/biz_anakin_bff/error_reason_pb'
import { ErrorInfo } from '@blue-agency/proton/web/v2/errdetails/error_details_pb'
import * as jspb from 'google-protobuf'

const nonGoogleApisErrorDetailTypeNames = [
  'proton.v2.biz_anakin_bff.TooWeakPasswordErrorDetail',
  'proton.v2.biz_anakin_bff.CurrentPasswordMismatchErrorDetail',
] as const
type NonGoogleApisErrorDetailTypeName =
  typeof nonGoogleApisErrorDetailTypeNames[number]

type ErrorInfoReason = keyof typeof ErrorReason

export class CustomBizAnakinBffGrpcError extends CustomGrpcError {
  /**
   * 特定のErrorDetailを持っているか確かめる
   * @param key ErrorInfoで定義されたErrorReason or kyloなどで定義されたGoogleApisではないErrorDetailTypeName
   * @returns 引数のkey === 実際のkey
   */
  hasMatchErrorDetail(
    key: ErrorInfoReason | NonGoogleApisErrorDetailTypeName
  ): boolean {
    // NOTE: GoogleApisではないErrorDetailで `this.errorDetailsOfGoogleApis` を呼ぶとエラーになる
    if (
      nonGoogleApisErrorDetailTypeNames.includes(
        key as NonGoogleApisErrorDetailTypeName
      )
    ) {
      return this.errorDetails.some((d) => {
        return d.getTypeName() === key
      })
    } else {
      return this.errorDetailsOfGoogleApis.some((d) => {
        if (d instanceof ErrorInfo) {
          const r = d.getReason() as ErrorInfoReason
          return r === key
        }
        return false
      })
    }
  }

  /**
   * @param reason ErrorInfoで定義されたErrorReason
   * @returns 該当のErrorReasonのMetadata
   */
  getErrorDetailMetadataMap(
    reason: ErrorInfoReason
  ): jspb.Map<string, string> | undefined {
    let res: jspb.Map<string, string> | undefined = undefined
    this.errorDetailsOfGoogleApis.forEach((d) => {
      if (d instanceof ErrorInfo) {
        const r = d.getReason() as ErrorInfoReason
        if (r === reason) {
          res = d.getMetadataMap()
          return
        }
      }
    })
    return res
  }
}

export function buildErrorByGrpcError(error: GrpcError): Error {
  if (error.code === Code.PERMISSION_DENIED) {
    return new PermissionDenied(error as unknown as Error)
  }
  return new CustomBizAnakinBffGrpcError(error)
}
