export const isIPv4 = (value: string) => {
  const blocks = value.split('.')
  if (blocks.length !== 4) return false
  return blocks.every((v) => {
    if (!/^[0-9]{1,3}$/.test(v)) {
      return false
    }
    const n = Number(v)
    return n.toString() === v && n < 256
  })
}
