import { useMutation, useQueryClient } from 'react-query'
import { useRequestUpdateAtsConnectV3 } from '@/services/bffService/useRequestUpdateAtsConnectV3'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateAtsConnectV3() {
  const queryClient = useQueryClient()
  const { requestUpdateAtsConnectV3 } = useRequestUpdateAtsConnectV3()

  return useMutation(requestUpdateAtsConnectV3, {
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(QUERY_KEY.apiSettings)
      queryClient.invalidateQueries([QUERY_KEY.atsConnectV3, req.guid])
    },
  })
}
