import React from 'react'
import { Loading } from '@/components/Loading'

type Props = {
  children: React.ReactNode
}

export const SuspenseWithLoadingOverlay = ({
  children,
}: Props): React.ReactElement => {
  return <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
}
