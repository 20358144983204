import React from 'react'
import { getInternalUrls } from '@blue-agency/im-shared-front'
import { Txt, WeakColorSeparator, Icon, theme } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import backgroundImage from '../background.jpg'

export const AlreadyExistsPage: React.VFC = () => {
  return (
    <Background>
      <Wrapper>
        <Content>
          <Logo />
          <Title>パスワード設定</Title>
          <Description>パスワードは既に設定されています。</Description>
        </Content>
        <WeakColorSeparator />
        <Bottom>
          <LoginButton
            onClick={() => {
              window.location.href = getInternalUrls().signIn
            }}
            comlinkPushParams={{
              action: 'click_move_to_login_page_button',
            }}
          >
            ログイン画面へ
          </LoginButton>
        </Bottom>
      </Wrapper>
    </Background>
  )
}

const Background = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url('${backgroundImage}');
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
`

const Wrapper = styled.div`
  margin-top: 137px;
  width: 436px;
  min-height: 239px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 26px;
`

const Logo = styled(Icon).attrs({ name: 'logo' })`
  width: 160px;
  height: 66.82px;
  margin: 16px 0 7px;
  color: ${theme.color.black[1]};
`

const Title = styled(Txt).attrs({ size: 'l' })`
  margin-bottom: 12px;
`

const Description = styled(Txt).attrs({ size: 'm' })`
  align-self: start;
  margin-bottom: 43px;
`

const LoginButton = styled(PrimaryButton).attrs({ widthSize: 'L1' })``

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 26px;
`
