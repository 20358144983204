import { useMutation, useQueryClient } from 'react-query'
import { useRequestUpdateAtsConnectV1V2 } from '@/services/bffService/useRequestUpdateAtsConnectV1V2'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateAtsConnectV1V2() {
  const queryClient = useQueryClient()
  const { requestUpdateAtsConnectV1V2 } = useRequestUpdateAtsConnectV1V2()

  return useMutation(requestUpdateAtsConnectV1V2, {
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(QUERY_KEY.apiSettings)
      queryClient.invalidateQueries([QUERY_KEY.atsConnectV1V2, req.guid])
    },
  })
}
