import { useCallback } from 'react'
import {
  administrator,
  PermissionDenied,
  settingEditor,
  useLoggedInStaff,
} from '@blue-agency/im-shared-front'
import { useLocation } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'

export function useAuthz() {
  const loggedInStaff = useLoggedInStaff()
  const location = useLocation()

  const authorize = useCallback(() => {
    // NOTE: biz-hutt-frontと違って許可RPCリストがないため、
    // 権限タイプごとに特定のURLへのアクセス制御を行っている
    switch (location.pathname) {
      case INTERNAL_PATHS.editPassword:
      case INTERNAL_PATHS.staffProfile:
        return
    }
    if (loggedInStaff === undefined) return
    if (
      loggedInStaff.authzType.type === administrator.type ||
      loggedInStaff.authzType.type === settingEditor.type
    )
      return

    throw new PermissionDenied()
  }, [location.pathname, loggedInStaff])

  return {
    authorize,
  }
}
