import React, { useCallback, useMemo } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { GetAtsConnectV3Response } from '@blue-agency/proton/web/v2/biz_anakin_bff'
import { theme, Txt, Icon as _Icon } from '@blue-agency/rogue'
import { SecondaryDangerButton, SecondaryButton } from '@blue-agency/rogue/im'
import { captureException } from '@sentry/react'
import { generatePath, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Form } from '@/components/Form'
import { TextLink } from '@/components/Link'
import { Loading } from '@/components/Loading'
import { SettingsSection } from '@/components/SettingsSection'
import { useModal } from '@/hooks/useModal'
import { INTERNAL_PATHS } from '@/services/urlService/internalPaths'
import { APISettingsResumeModal } from '../../components/APISettingsResumeModal'
import { APISettingsSuspendModal } from '../../components/APISettingsSuspendModal'
import { useGetAtsConnectV1V2 } from './useGetAtsConnectV1V2'
import { useResumeAtsConnect } from './useResumeAtsConnect'
import { useSuspendAtsConnect } from './useSuspendAtsConnect'

const Status = GetAtsConnectV3Response.Status

export const Content: React.VFC = () => {
  const { guid } = useParams<{ guid: string }>()

  const { mutateAsync: suspendAtsConnect } = useSuspendAtsConnect(guid)
  const { mutateAsync: resumeAtsConnect } = useResumeAtsConnect(guid)
  const { data, isLoading } = useGetAtsConnectV1V2(guid)

  const atsConnect = useMemo(() => {
    return data?.toObject()
  }, [data])

  const isSuspended = atsConnect?.status === Status.SUSPENDED

  const suspendModal = useModal()
  const resumeModal = useModal()

  const suspend = useCallback(async () => {
    try {
      await suspendAtsConnect()
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }
    suspendModal.close()
  }, [suspendModal, suspendAtsConnect])

  const resume = useCallback(async () => {
    try {
      await resumeAtsConnect()
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }
    resumeModal.close()
  }, [resumeModal, resumeAtsConnect])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Wrapper>
      <SettingsSection
        title={
          <SettingSectionTitle>
            <SettingSectionTitleTxt size="l">
              連携設定情報
            </SettingSectionTitleTxt>
            <SettingSectionTitleItem>
              {isSuspended && <SuspendedBadge />}
            </SettingSectionTitleItem>
          </SettingSectionTitle>
        }
      >
        <Controls>
          <EditButton
            to={generatePath(INTERNAL_PATHS.apiSettings.editV2, {
              guid,
            })}
          >
            <Icon name="edit" />
            編集
          </EditButton>
          {isSuspended ? (
            <ResumeButton
              onClick={resumeModal.open}
              comlinkPushParams={{
                action: 'open_api_connect_resume_modal_on_api_settings_v2_page',
              }}
            >
              連携再開
            </ResumeButton>
          ) : (
            <SuspendButton
              onClick={suspendModal.open}
              comlinkPushParams={{
                action:
                  'open_api_connect_suspend_modal_on_api_settings_v2_page',
              }}
            >
              連携停止
            </SuspendButton>
          )}
        </Controls>
        <Form.Section>
          <Form.Row>
            <Form.Title>連携先</Form.Title>
            <Form.Item>
              <Form.Field>
                <Txt>{atsConnect?.name}</Txt>
              </Form.Field>
            </Form.Item>
          </Form.Row>
        </Form.Section>
        <Form.Section>
          <Form.Row>
            <Form.Title>メモ</Form.Title>
            <Form.Item>
              <Form.Field>
                <Txt>{atsConnect?.note}</Txt>
              </Form.Field>
            </Form.Item>
          </Form.Row>
        </Form.Section>
        <Form.Section>
          <Form.Row>
            <Form.Title>面接ガイド</Form.Title>
            <Form.Item>
              <Form.Field>
                <Txt>{atsConnect?.defaultWebInterviewGuide?.name}</Txt>
              </Form.Field>
            </Form.Item>
          </Form.Row>
        </Form.Section>
      </SettingsSection>
      <APISettingsSuspendModal
        active={suspendModal.active}
        onClose={suspendModal.close}
        onSubmit={suspend}
        name={atsConnect?.name ?? ''}
      />
      <APISettingsResumeModal
        active={resumeModal.active}
        onClose={resumeModal.close}
        onSubmit={resume}
        name={atsConnect?.name ?? ''}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div``

const SettingSectionTitle = styled.div`
  display: flex;
  vertical-align: middle;
`

const SettingSectionTitleTxt = styled(Txt)`
  margin: auto 0;
`

const SettingSectionTitleItem = styled.div`
  margin: auto 0;
`

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 20px;
`

const EditButton = styled(TextLink)`
  color: ${theme.color.navy[1]};
`

const Icon = styled(_Icon)`
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 3px;
`

const SuspendButton = styled(SecondaryDangerButton).attrs({
  widthSize: 'M',
})`
  margin-left: 30px;
`

const ResumeButton = styled(SecondaryButton).attrs({
  widthSize: 'M',
})`
  margin-left: 30px;
`

const SuspendedBadge = styled(Txt).attrs({
  size: 's',
  children: '連携停止中',
  color: theme.color.red[1],
})`
  height: 20px;
  min-width: 90px;
  padding: auto;
  margin-left: 20px;
  text-align: center;
  line-height: 18px;
  background-color: ${theme.color.white[1]};
  border: 1px solid ${theme.color.red[1]};
`
