import React, { useMemo, useState } from 'react'
import { theme, InputText } from '@blue-agency/rogue'
import Autosuggest from 'react-autosuggest'
import styled from 'styled-components'
import { Staff } from '../services/staffService'
import { LineClampedTxt } from './LineClampedTxt'

type Props = {
  staffs: StaffsItem[]
  ignoreStaffGuids: string[]
  suggestionsMax?: number
  onSelected: (staff: StaffsItem) => void
  onBlurError?: string
  onSubmitError?: string
}

export const StaffsAutosuggest: React.VFC<Props> = ({
  staffs: _staffs,
  ignoreStaffGuids,
  onSelected,
  onBlurError,
  onSubmitError,
  suggestionsMax = 10,
}) => {
  const staffs = useMemo(() => new Staffs(_staffs), [_staffs])

  const [value, setValue] = useState<string>('')

  const suggestions = useMemo(
    () => staffs.filter(value, suggestionsMax, ignoreStaffGuids),
    [staffs, value, suggestionsMax, ignoreStaffGuids]
  )

  const getSuggestionValue = (_staff: StaffsItem) => value

  const renderSuggestion: Autosuggest.RenderSuggestion<StaffsItem> = (
    suggestion,
    { isHighlighted }
  ) => <Option staff={suggestion} isHighlighted={isHighlighted} />

  // FIXME: ESCの後にinputを2回クリックしないとサジェストが再表示されない
  const [suggestionsRevealed, setSuggestionsRevealed] = useState<
    boolean | undefined
  >()

  return (
    <Autosuggest
      theme={myTheme}
      suggestions={suggestions}
      getSuggestionValue={getSuggestionValue}
      renderInputComponent={(props) => (
        <Input
          {...props}
          onBlurError={onBlurError}
          onSubmitError={onSubmitError}
        />
      )}
      renderSuggestion={renderSuggestion}
      focusInputOnSuggestionClick={false}
      shouldRenderSuggestions={(_value, reason) => {
        switch (reason) {
          case 'suggestions-revealed':
            setSuggestionsRevealed(true)
            return true
          case 'input-blurred':
          case 'escape-pressed':
            setSuggestionsRevealed(false)
            return false
          case 'render':
            return suggestionsRevealed ?? false
        }
        return true
      }}
      inputProps={{
        placeholder: '氏名、メールアドレスを入力または選択',
        value,
        onChange: (_event, { method, newValue }) => {
          switch (method) {
            case 'type':
              setSuggestionsRevealed(true)
              setValue(newValue)
              return
            case 'enter':
              setSuggestionsRevealed(false)
              return
          }
        },
        onClick: (event) => {
          if (suggestionsRevealed) {
            const target = event.target as HTMLInputElement
            target.blur()
          }
          setSuggestionsRevealed(!suggestionsRevealed)
        },
      }}
      onSuggestionsFetchRequested={({ value }) => {
        setValue(value)
      }}
      onSuggestionsClearRequested={() => {
        setSuggestionsRevealed(true)
      }}
      onSuggestionSelected={(_event, { suggestion }) => {
        setSuggestionsRevealed(false)
        setValue('')
        onSelected(suggestion)
      }}
    />
  )
}

export type StaffsItem = Pick<Staff, 'guid' | 'email' | 'name' | 'nameKana'>
export class Staffs {
  private staffs: StaffsItem[]

  constructor(staffs: StaffsItem[]) {
    this.staffs = staffs.sort((a, b) => a.email.localeCompare(b.email))
  }

  get all() {
    return this.staffs
  }

  filter(value: string, max: number, ignores: string[]): StaffsItem[] {
    return this.staffs.filter(this.filterStaff(value, ignores)).slice(0, max)
  }

  private filterStaff(query: string, ignoreGuids: string[]) {
    return (staff: StaffsItem): boolean => {
      if (ignoreGuids.includes(staff.guid)) return false
      if (query === '') return true
      const strings = [
        staff.email,
        staff.name.fullName,
        staff.nameKana.fullName,
        staff.nameKana.familyName,
        staff.nameKana.givenName,
      ]
      return query
        .split(/ |　/)
        .every((q) => strings.some((s) => s.includes(q.trim())))
    }
  }
}

type OptionProps = {
  staff: StaffsItem
  isHighlighted: boolean
}
const Option: React.VFC<OptionProps> = ({ staff, isHighlighted }) => (
  <OptionRow isHighlighted={isHighlighted}>
    <LineClampedTxt line={1}>
      {staff.email} ({staff.name.fullName})
    </LineClampedTxt>
  </OptionRow>
)

type OptionRowParams = {
  isHighlighted: boolean
}
const OptionRow = styled.div<OptionRowParams>`
  width: 100%;
  height: 45px;
  padding: 12px 20px;
  :hover {
    cursor: pointer;
  }
  border-bottom: solid 1px ${theme.color.gray[4]};
  ${(props) =>
    props.isHighlighted && `background-color: ${theme.color.gray[4]}`}
`

type InputProps = Omit<
  Autosuggest.RenderInputComponentProps,
  'size' | 'type'
> & {
  label?: string
  onBlurError?: string
  onSubmitError?: string
}
const Input: React.VFC<InputProps> = (props) => {
  return (
    <InputText
      {...{
        ...props,
        height: props.height as string | undefined,
        defaultValue: props.defaultValue as string | undefined,
      }}
      size="ll"
      css={undefined}
    />
  )
}

const myTheme: Autosuggest.Theme = {
  suggestionsContainer: {
    width: '460px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '0px 0px 4px 4px',
    display: 'block',
    position: 'absolute',
    top: '32px',
    backgroundColor: theme.color.white[1],
    zIndex: 1,
  },
  suggestion: {
    listStyleType: 'none',
  },
  input: {
    cursor: 'pointer',
  },
}
