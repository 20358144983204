import React from 'react'
import { administrator } from '@blue-agency/im-shared-front'
import { WeakColorSeparator, NewTabLink, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { AuthzTypeRadioGroup } from '@/components/AuthzTypeRadioGroup'
import { Form } from '@/components/Form'
import { EXTERNAL_PATHS } from '@/services/urlService'
import { SettingStaffRegisterByFormPageContainer } from '../../SettingStaffRegisterByFormPageContainer'

export const AuthzTypeSection = ({
  className,
}: PropsWithClassName): React.ReactElement => {
  const { register, completedForm } =
    SettingStaffRegisterByFormPageContainer.useContainer()

  return (
    <Form.Section className={className}>
      <Form.Row>
        <Form.Title>権限タイプ</Form.Title>
        <Form.Item>
          <Form.Required>必須</Form.Required>
          <Form.Field>
            <AuthzTypeRadioGroup
              {...register('authzType')}
              defaultValue={completedForm?.authzType ?? administrator.type}
            />
          </Form.Field>
          <Form.Helper textAlign="right">
            <NewTabLink
              href={EXTERNAL_PATHS.help.authzType}
              action="open_authzType_help_page"
            >
              <Txt size="s" color="inherit">
                詳細はこちら
              </Txt>
            </NewTabLink>
          </Form.Helper>
        </Form.Item>
      </Form.Row>
      <Separator />
    </Form.Section>
  )
}

const Separator = styled(WeakColorSeparator)`
  margin-top: 20px;
`
