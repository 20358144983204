import React from 'react'
import {
  Txt,
  Icon,
  WeakColorSeparator,
  theme,
  NewTabLink,
  ErrorBox as _ErrorBox,
  TxtProps,
} from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { ErrorBoundary } from 'react-error-boundary'
import styled from 'styled-components'
import { ErrorTxt } from '@/components/ErrorTxt'
import { InputPassword } from '@/components/InputPassword'
import { Loading } from '@/components/Loading'
import { useActivateStaffPage } from '@/pages/ActivateStaffPage/useActivateStaffPage'
import { EXTERNAL_PATHS } from '@/services/urlService'
import backgroundImage from './background.jpg'
import { ErrorFallback } from './error/ErrorFallback'

export const ActivateStaffPage = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ActivateStaffForm />
    </ErrorBoundary>
  )
}

const ActivateStaffForm = () => {
  const {
    getOrganizationName,
    isLoading,
    register,
    errors,
    onSubmit,
    hasOccurredTooWeakPasswordError,
    isSubmitting,
  } = useActivateStaffPage()

  if (isLoading) {
    return <Loading />
  }

  return (
    <Wrapper>
      <FormWrapper>
        <form onSubmit={onSubmit}>
          <Content>
            <Logo />
            <Title>パスワード設定</Title>
            <Description noMargin={hasOccurredTooWeakPasswordError}>
              {getOrganizationName()}
              より
              <br />
              「インタビューメーカー」に登録されました。
              <br />
              パスワードを設定して、ログインしてください。
            </Description>
            {hasOccurredTooWeakPasswordError && (
              <ErrorBox>
                <ErrorTxt>
                  パスワードが安全ではないため、登録できませんでした。
                  <br />
                  新しいパスワードを設定してください。
                </ErrorTxt>
              </ErrorBox>
            )}
            <InputWrapper>
              <InputLabel>パスワード</InputLabel>
              <InputPassword
                {...register('password')}
                placeholder="半角英数字記号で8文字以上"
                size="l"
                onBlurError={errors['password']?.message}
                hasSubmitError={hasOccurredTooWeakPasswordError}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel>パスワード確認</InputLabel>
              <InputPassword
                {...register('passwordConfirmation')}
                placeholder="半角英数字記号で8文字以上"
                size="l"
                onBlurError={errors['passwordConfirmation']?.message}
                hasSubmitError={hasOccurredTooWeakPasswordError}
              />
            </InputWrapper>
          </Content>
          <WeakColorSeparator />
          <Bottom>
            <AgreementTxt>
              <NewTabLink href={EXTERNAL_PATHS.terms} action="open_terms_page">
                利用規約
              </NewTabLink>
              、
              <NewTabLink href={EXTERNAL_PATHS.privacy} action="open_pp_page">
                プライバシーポリシー
              </NewTabLink>
              に同意の上、
              <br />
              ログインしてください。
            </AgreementTxt>
            <LoginButton
              type="submit"
              loading={isSubmitting}
              comlinkPushParams={{ action: 'click_activate_staff_button' }}
            >
              同意してログイン
            </LoginButton>
          </Bottom>
        </form>
      </FormWrapper>
      <Copyright size="s">© 2020 STADIUM</Copyright>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-image: url('${backgroundImage}');
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  min-height: 700px;
`

const FormWrapper = styled.div`
  margin-top: 137px;
  width: 436px;
  min-height: 439px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 26px;
`

const Logo = styled(Icon).attrs({ name: 'logo' })`
  width: 160px;
  height: 66.82px;
  margin: 16px 0 7px;
  color: ${theme.color.black[1]};
`

const Title = styled(Txt).attrs({ size: 'l' })`
  margin-bottom: 12px;
`

const Description = styled(Txt).attrs({ size: 'm' })<
  TxtProps & { noMargin?: boolean }
>`
  align-self: flex-start;
  margin-bottom: 43px;
  ${({ noMargin }) =>
    noMargin &&
    `
    margin-bottom: 0px;
  `}
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const InputLabel = styled.label`
  font-size: ${theme.fontSize.m};
  width: 100px;
`

const Copyright = styled(Txt)`
  position: relative;
  bottom: 10px;
  text-align: center;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 130px;
  width: 100%;
  padding: 20px 26px;
`
const AgreementTxt = styled(Txt).attrs({ size: 'm' })`
  margin-bottom: 18px;
`

const LoginButton = styled(PrimaryButton).attrs({ widthSize: 'L1' })``

const ErrorBox = styled(_ErrorBox)`
  margin: 19px 0 23px;
`
