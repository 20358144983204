import * as v2_im_staff_pb from '@blue-agency/proton/web/v2/im/staff_pb'
import type { AuthnType } from '@/services/authnService'
import { microsoft, google, password } from '@/services/authnService'

export const authnTypeToProto = (
  authnType: AuthnType['type']
): v2_im_staff_pb.AuthnType => {
  switch (authnType) {
    case 'password':
      return v2_im_staff_pb.AuthnType.PASSWORD
    case 'microsoft':
      return v2_im_staff_pb.AuthnType.MICROSOFT
    case 'google':
      return v2_im_staff_pb.AuthnType.GOOGLE
  }
}

export const authnTypeFromProto = (
  authnTypePb: v2_im_staff_pb.AuthnType | undefined
): AuthnType => {
  switch (authnTypePb) {
    case v2_im_staff_pb.AuthnType.MICROSOFT:
      return microsoft
    case v2_im_staff_pb.AuthnType.GOOGLE:
      return google
    default:
      return password
  }
}
