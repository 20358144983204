import { useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { QueryClient, useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { useModal } from '@/hooks/useModal'
import { useRequestDeleteIpFilter } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { INTERNAL_PATHS } from '@/services/urlService'

export const useDeleteIpFilter = (guid: string) => {
  const history = useHistory()
  const deleteIpFilterModal = useModal(false)

  const { mutateAsync, isLoading } = useDeleteIpFilterMutation(guid)

  const onDeleteIpFilter = useCallback(async () => {
    if (isLoading) return

    try {
      await mutateAsync()
    } catch (e) {
      Sentry.captureException(e)
      commonErrorToast()
      return
    }

    // TODO: テーマカラーをFigmaに揃える
    // TODO: '✗'ボタンを消す
    toast('フィルター情報を削除しました')
    deleteIpFilterModal.close()
    history.push(INTERNAL_PATHS.settings.ipfilters.index)
  }, [deleteIpFilterModal, isLoading, mutateAsync, history])

  return {
    deleteIpFilterModal,
    onDeleteIpFilter,
  }
}

function useDeleteIpFilterMutation(guid: string) {
  const queryClient = new QueryClient()
  const { requestDeleteIpFilter } = useRequestDeleteIpFilter()

  return useMutation(() => requestDeleteIpFilter(guid), {
    onSuccess: () => {
      queryClient.removeQueries(QUERY_KEY.listIpFilters)
    },
  })
}
