import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { DeleteIPFilterRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestDeleteIpFilter() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestDeleteIpFilter = useCallback(
    async (guid: string) => {
      const metadata = await buildMetadata()

      const req = new DeleteIPFilterRequest()
      req.setGuid(guid)

      return callBizAnakinBffService(
        'deleteIPFilter',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestDeleteIpFilter }
}
