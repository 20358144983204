import React from 'react'
import { Modal } from '@/components/Modal'

type Props = {
  name: string
  active: boolean
  onClose: () => void
  onSubmit: () => void
}

export const APISettingsResumeModal: React.VFC<Props> = (props) => {
  return (
    <Modal.Wrapper
      active={props.active}
      title="連携再開"
      stopScroll
      onClose={props.onClose}
    >
      <Modal.Body>
        <Modal.Txt>「{props.name}」の連携停止を解除します。</Modal.Txt>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          onClick={props.onClose}
          comlinkPushParams={{
            action: 'click_cancel_resume_api_button',
          }}
        >
          キャンセル
        </Modal.CancelButton>
        <Modal.SubmitButton
          onClick={props.onSubmit}
          comlinkPushParams={{
            action: 'click_resume_api_button',
          }}
        >
          連携再開
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal.Wrapper>
  )
}
