import { useQueryClient, useMutation } from 'react-query'
import { useRequestUpdateStaffAuthnType } from '@/services/bffService/useRequestUpdateStaffAuthnType'
import { QUERY_KEY } from '@/services/queryKeyService'

export const useUpdateStaffAuthnType = (guid: string) => {
  const queryClient = useQueryClient()

  const { requestUpdateStaffAuthnType } = useRequestUpdateStaffAuthnType()
  return useMutation(requestUpdateStaffAuthnType, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.listStaffs)
      queryClient.invalidateQueries([QUERY_KEY.getStaff, guid])
    },
  })
}
