import type { AuthnType } from '@/services/authnService'

export const authnTypeToText = (authnType: AuthnType['type']): string => {
  switch (authnType) {
    case 'password':
      return 'パスワード認証'
    case 'microsoft':
      return 'Microsoftアカウント認証'
    case 'google':
      return 'Googleアカウント認証'
  }
}
