import { authzTypeFromProto } from '@blue-agency/im-shared-front'
import { ListStaffsResponse } from '@blue-agency/proton/biz_anakin_bff'
import { authnTypeFromProto } from '@/services/bffService/authnType'
import { dateOf } from '@/services/bffService/date'
import { nameOf, nameKanaOf } from './name'
import { Status } from './status'
import { Staff, StaffSortKey, StaffSortOrder } from './types'

export type StaffListFilterCondition = {
  freeWord: string
  status: {
    isRegisteredOrResumed: boolean
    isActivated: boolean
    isExpired: boolean
  }
}

export class StaffList {
  staffs: Staff[]
  sortKey: StaffSortKey
  order: StaffSortOrder

  constructor(staffs: Staff[] | undefined = []) {
    this.staffs = staffs
    this.sortKey = 'registeredAt'
    this.order = 'desc'
  }

  sort(key: StaffSortKey, order: StaffSortOrder) {
    if (this.sortKey === key && this.order === order) return

    this.sortKey = key
    this.order = order

    const orderValue = order === 'desc' ? -1 : 1
    this.staffs.sort((s1, s2) => {
      if (s1[key] < s2[key]) return orderValue * -1
      if (s1[key] > s2[key]) return orderValue * 1
      return 0
    })
  }

  filter(condition: StaffListFilterCondition): StaffList {
    const newStaffs = this.staffs.filter((staff) => {
      const isNotMatchedByStatus = (() => {
        const allSelected = Object.values(condition.status).every((v) => v)
        if (allSelected) {
          return false
        }
        const status = getStatusForFilter(staff)

        let isMatched = false

        if (condition.status.isActivated && status === 'isActivated') {
          isMatched = true
        }
        if (condition.status.isRegisteredOrResumed) {
          if (status === 'isRegistered' || status === 'isResumed') {
            isMatched = true
          }
        }
        if (condition.status.isExpired && status === 'isExpired') {
          isMatched = true
        }
        return !isMatched
      })()

      if (isNotMatchedByStatus) {
        return false
      }

      if (condition.freeWord === '') {
        return true
      }

      const words = condition.freeWord.split(/[ 　]/)

      return words.every(
        (word) =>
          staff.email.includes(word) ||
          staff.name.includes(word) ||
          staff.nameKana.includes(word)
      )
    })

    return new StaffList(newStaffs)
  }

  getPage(page: number, pageSize: number) {
    const start = (page - 1) * pageSize
    const end = Math.min(this.staffs.length, page * pageSize)
    return this.staffs.slice(start, end)
  }

  static ofListStaffsResponseStaff(
    _staffs: ListStaffsResponse.Staff[]
  ): StaffList {
    const staffs = _staffs.map((staff) => {
      const registeredAt = dateOf(staff.getRegisteredAt())

      const name = nameOf(staff.getName())

      const nameKana = nameKanaOf(staff.getNameKana())

      const authzType = authzTypeFromProto(staff.getAuthzType())

      const authnType = authnTypeFromProto(staff.getAuthnType())

      const status = Status.ofListStaffsResponse(staff.getStatus())

      const suspended = staff.getSuspended()

      return {
        ...staff.toObject(),
        name,
        nameKana,
        authzType,
        registeredAt,
        status,
        suspended,
        authnType,
      }
    })

    return new StaffList(staffs)
  }
}

type StatusForFilter =
  | 'isExpired'
  | 'isActivated'
  | 'isRegistered'
  | 'isResumed'
  | 'unknown'

function getStatusForFilter(staff: Staff): StatusForFilter {
  if (staff.isRegistrationExpired) {
    return 'isExpired'
  }
  if (staff.status.isActivated()) {
    return 'isActivated'
  }
  if (staff.status.isRegistered()) {
    return 'isRegistered'
  }
  if (staff.status.isResumed()) {
    return 'isResumed'
  }
  return 'unknown'
}
