import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { assertIsDefined } from '@/assertions'
import { useRequestGetIpFilter } from '@/services/bffService/useRequestGetIpFilter'
import { IpFilter } from '@/services/ipFilterService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { nameOf } from '@/services/staffService/name'

export const useIpFilter = (guid: string) => {
  const { isLoading, data } = useGetIpFilterQuery(guid)

  const ipFilter = useMemo(() => {
    if (isLoading) return undefined
    assertIsDefined(data)

    const scopeType = data.getAllStaffs() ? 'all_staffs' : 'specified_staffs'

    const scopeSpecifiedStaffs =
      data
        .getSpecifiedStaffs()
        ?.getStaffsList()
        .map((staff) => ({
          guid: staff.getGuid(),
          name: nameOf(staff.getName()),
        })) ?? []

    const cidrs = data.getCidrsList().map((cidr) => cidr.toObject())

    return new IpFilter({
      guid: data.getGuid(),
      name: data.getName(),
      scopeType,
      scopeSpecifiedStaffs,
      cidrs,
    })
  }, [isLoading, data])

  return {
    isLoading,
    ipFilter,
  }
}

function useGetIpFilterQuery(guid: string) {
  const { requestGetIpFilter } = useRequestGetIpFilter()

  return useQuery([QUERY_KEY.getIpFilter, guid], () => requestGetIpFilter(guid))
}
