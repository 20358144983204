import React from 'react'
import { FixedBottomFormPage as Page } from '@blue-agency/im-shared-front'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { Form } from '@/components/Form'
import { SettingsSection } from '@/components/SettingsSection'
import { FormType } from '.'
import * as C from './components'

type Props = {
  form: FormType
  guideNameMap: { [guid: string]: string }
  onBackToInput: () => void
  onSubmit: () => void
}
export const Confirm: React.VFC<Props> = (props) => {
  return (
    <>
      <Page.Body>
        <SettingsSection title="連携設定情報">
          <Form.Section>
            <C.Row>
              <C.RowTitle>連携先</C.RowTitle>
              <C.RowTxt>{props.form.name}</C.RowTxt>
            </C.Row>
            <C.Separator />
            <C.Row>
              <C.RowTitle>メモ</C.RowTitle>
              <C.RowTxt>{props.form.note}</C.RowTxt>
            </C.Row>
            <C.Separator />
            <C.Row>
              <C.RowTitle>面接ガイド</C.RowTitle>
              <C.RowTxt>
                {props.form.defaultWebInterviewGuideGuid &&
                  props.guideNameMap[props.form.defaultWebInterviewGuideGuid]}
              </C.RowTxt>
            </C.Row>
          </Form.Section>
        </SettingsSection>
      </Page.Body>
      <Page.Bottom>
        <Page.BottomButtons>
          <TertiaryButton
            onClick={props.onBackToInput}
            comlinkPushParams={{
              action: 'click_back_to_input_button_on_api_settings_v2_edit_page',
            }}
            widthSize="L2"
          >
            入力画面に戻る
          </TertiaryButton>
          <PrimaryButton
            onClick={props.onSubmit}
            comlinkPushParams={{
              action: 'click_save_button_on_api_settings_v2_edit_page',
            }}
            widthSize="L2"
          >
            保存
          </PrimaryButton>
        </Page.BottomButtons>
      </Page.Bottom>
    </>
  )
}
