import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateAtsConnectTokenRequest } from '@blue-agency/proton/biz_anakin_bff'
import { callBizAnakinBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestCreateAtsConnectToken() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateAtsConnectToken = useCallback(async () => {
    const metadata = await buildMetadata()

    const req = new CreateAtsConnectTokenRequest()

    return callBizAnakinBffService(
      'createAtsConnectToken',
      [req, metadata],
      loggedInStaff?.guid
    )
  }, [buildMetadata, loggedInStaff?.guid])

  return {
    requestCreateAtsConnectToken,
  }
}
