import React, { useMemo, useState } from 'react'
import { StaffIcon } from '@blue-agency/im-shared-front'
import { Icon, theme } from '@blue-agency/rogue'
import { FieldArrayWithId, UseFormRegister } from 'react-hook-form'
import styled from 'styled-components'
import { Form } from '@/components/Form'
import { LineClampedTxt } from '@/components/LineClampedTxt'
import { StaffsAutosuggest, StaffsItem } from '@/components/StaffsAutosuggest'
import { Staff } from '@/services/staffService'
import { Form as FormType } from './SettingIpFilterNewPageContainer'
import * as C from './components'

export type ScopeSpecifiedStaffsFormRowProps = {
  staffs: StaffsItem[]
  scopeSpecifiedStaffGuidFields: FieldArrayWithId<
    FormType,
    'scopeSpecifiedStaffs',
    'id'
  >[]
  onSelect: (staff: StaffsItem) => void
  onDelete: (index: number) => void
  onBlurError?: string
  register: UseFormRegister<FormType>
}

export const ScopeSpecifiedStaffsFormRow: React.VFC<ScopeSpecifiedStaffsFormRowProps> =
  ({
    staffs,
    scopeSpecifiedStaffGuidFields,
    onSelect,
    onDelete,
    onBlurError,
    register,
  }) => {
    const scopeSpecifiedStaffs = useMemo(() => {
      return scopeSpecifiedStaffGuidFields
        .map(({ guid }) => staffs.find((staff) => staff.guid === guid))
        .filter((staff): staff is Staff => staff !== undefined)
    }, [scopeSpecifiedStaffGuidFields, staffs])

    const scopeSpecifiedStaffGuids = useMemo(
      () => scopeSpecifiedStaffs.map((staff) => staff.guid),
      [scopeSpecifiedStaffs]
    )

    return (
      <>
        <Form.Row>
          <Form.Item>
            <C.FormField>
              <StaffsAutosuggest
                staffs={staffs}
                ignoreStaffGuids={scopeSpecifiedStaffGuids}
                suggestionsMax={10}
                onSelected={onSelect}
                onBlurError={onBlurError}
              />
            </C.FormField>
          </Form.Item>
        </Form.Row>
        <Form.Row>
          <Form.Item>
            <C.FormField>
              <SpecifiedStaffList>
                {scopeSpecifiedStaffs.map((staff, index) => (
                  <SpecifiedStaffItem
                    key={staff.guid}
                    staff={staff}
                    index={index}
                    onClickDelete={() => onDelete(index)}
                    register={register}
                  />
                ))}
              </SpecifiedStaffList>
            </C.FormField>
          </Form.Item>
        </Form.Row>
      </>
    )
  }

type SelectedSpecifiedStaffProps = {
  staff: Staff
  onClickDelete: () => void
  key: string
  index: number
  register: UseFormRegister<FormType>
}
export const SpecifiedStaffItem: React.VFC<SelectedSpecifiedStaffProps> = ({
  staff,
  index,
  onClickDelete,
  register,
}) => {
  const [hoverSpecifiedStaffGuid, setHoverSpecifiedStaffGuid] = useState<
    string | undefined
  >()

  return (
    <SpecifiedStaffWrapper
      key={staff.guid}
      onMouseOver={() => setHoverSpecifiedStaffGuid(staff.guid)}
      onMouseLeave={() => setHoverSpecifiedStaffGuid(undefined)}
    >
      <StaffIcon
        size="s"
        name={staff.name.familyName}
        color={staff.iconColorCode}
      />
      <SpecifiedStaffText>
        <LineClampedTxt line={1}>{staff.name.fullName}</LineClampedTxt>
        <LineClampedTxt color={theme.color.gray[1]} size="s" line={1}>
          {staff.email}
        </LineClampedTxt>
      </SpecifiedStaffText>
      {hoverSpecifiedStaffGuid === staff.guid && (
        <SpecifiedStaffDeleteButton onClick={onClickDelete}>
          <Icon name="close" size="s" />
        </SpecifiedStaffDeleteButton>
      )}
      <input
        {...register(`scopeSpecifiedStaffs.${index}.guid` as const)}
        type="hidden"
        value={staff.guid}
      />
      <input
        {...register(`scopeSpecifiedStaffs.${index}.fullName` as const)}
        type="hidden"
        value={staff.name.fullName}
      />
    </SpecifiedStaffWrapper>
  )
}

const SpecifiedStaffList = styled.div`
  width: 460px;
  margin: 0;
`
const SpecifiedStaffWrapper = styled.div`
  width: 460px;
  padding: 5px;
  display: flex;
  align-items: center;
  :hover {
    background-color: ${theme.color.gray[5]};
  }
`
const SpecifiedStaffText = styled.div`
  width: 380px;
  height: 100%;
  padding: 5px;
`
const SpecifiedStaffDeleteButton = styled.div`
  cursor: pointer;
  margin-right: 15px;
`
