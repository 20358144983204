import React, { useMemo } from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { ListAtsConnectsResponse } from '@blue-agency/proton/web/v2/biz_anakin_bff'
import { Txt, theme } from '@blue-agency/rogue'
import { SecondaryButton } from '@blue-agency/rogue/im'
import { format } from 'date-fns'
import { generatePath } from 'react-router'
import styled, { css } from 'styled-components'
import { Link } from '@/components/Link'
import { ListContentLayout } from '@/components/ListContentLayout'
import { Loading } from '@/components/Loading'
import { ListPageHeader } from '@/components/PageHeader'
import {
  Table,
  TableHead,
  TableHeadRow as _TableHeadRow,
  TableRow,
  TableBody,
} from '@/components/Table'
import { useModal } from '@/hooks/useModal'
import { INTERNAL_PATHS } from '@/services/urlService'
import { GenerateAPITokenModal } from './GenerateAPITokenModal'
import { StatusBadge } from './StatusBadge'
import { useListAPISettings } from './useListAPISettings'

export const APISettingsPage: React.VFC = () => {
  const generateAPITokenModal = useModal()

  const { isLoading, data } = useListAPISettings()

  const apiSettings = useMemo(() => {
    return data?.getAtsConnectsList() ?? []
  }, [data])

  if (isLoading) {
    return <Loading />
  }

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <ListPageHeader
        title="API連携設定一覧"
        right={
          <HeaderButtonGroups>
            <SecondaryButton
              onClick={generateAPITokenModal.open}
              widthSize="L2"
              comlinkPushParams={{ action: 'open_generate_api_token_modal' }}
            >
              連携用トークン発行
            </SecondaryButton>
          </HeaderButtonGroups>
        }
      />
      <ListContentLayout sidebar={<></>}>
        <Wrapper>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableHeadRow>
                  <TableHeadCell property="name">
                    <Txt>連携先</Txt>
                  </TableHeadCell>
                  <TableHeadCell property="status">
                    <Txt>ステータス</Txt>
                  </TableHeadCell>
                  <TableHeadCell property="note">
                    <Txt>メモ</Txt>
                  </TableHeadCell>
                  <TableHeadCell property="padding" />
                  <TableHeadCell property="registeredAt">
                    <Txt>登録日時</Txt>
                  </TableHeadCell>
                </TableHeadRow>
              </TableHead>
              <APISettingsTableBody apiSettings={apiSettings} />
            </Table>
          </TableWrapper>
        </Wrapper>
      </ListContentLayout>
      <GenerateAPITokenModal
        active={generateAPITokenModal.active}
        onClose={generateAPITokenModal.close}
      />
    </PageLayoutWithGlonavi>
  )
}

const APISettingsTableBody: React.VFC<{
  apiSettings: ListAtsConnectsResponse.AtsConnect[]
}> = (props) => {
  if (props.apiSettings.length === 0) {
    return <TableBody>{/* TODO: empty時の表示が必要であれば実装 */}</TableBody>
  }

  return (
    <TableBody>
      {props.apiSettings.map((apiSetting) => {
        const detailLink =
          apiSetting.getVersion() === 2
            ? generatePath(INTERNAL_PATHS.apiSettings.detailV2, {
                guid: apiSetting.getGuid(),
              })
            : generatePath(INTERNAL_PATHS.apiSettings.detailV3, {
                guid: apiSetting.getGuid(),
              })
        return (
          <TableRow key={apiSetting.getGuid()}>
            <TableBodyCell property="name">
              <TableCellLink to={detailLink}>
                {apiSetting.getName()}
              </TableCellLink>
            </TableBodyCell>
            <TableBodyCell property="status">
              <TableCellLink to={detailLink}>
                <StatusBadge status={apiSetting.getStatus()} />
              </TableCellLink>
            </TableBodyCell>
            <TableBodyCell property="note">
              <TableCellLink to={detailLink}>
                {apiSetting.getNote()}
              </TableCellLink>
            </TableBodyCell>
            <TableBodyCell property="padding">
              <TableCellLink to={detailLink} />
            </TableBodyCell>
            <TableBodyCell property="registeredAt">
              <TableCellLink to={detailLink}>
                {formatRegisteredAt(apiSetting.getRegisteredAt()?.toDate())}
              </TableCellLink>
            </TableBodyCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}

function formatRegisteredAt(d?: Date): string {
  if (d === undefined) return ''
  return format(d, 'yyyy/MM/dd') + '\n' + format(d, 'HH:mm')
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const HeaderButtonGroups = styled.div`
  display: flex;
  justify-content: center;
  > *:not(:first-child) {
    margin-left: 7px;
  }
`

const TableWrapper = styled.div`
  background-color: ${theme.color.white[1]};
  flex: 1;
  overflow-y: scroll;
`
const TableHeadRow = styled(_TableHeadRow)`
  background-color: ${theme.color.navy[3]};
`

type TableCellProps = {
  property: 'name' | 'note' | 'status' | 'padding' | 'registeredAt'
}
const propertyStyles = {
  name: css`
    flex: 0 0 300px;
    padding-left: 30px;
  `,
  note: css`
    flex: 0 0 200px;
  `,
  status: css`
    flex: 0 0 130px;
  `,
  padding: css`
    flex: 1 0 auto;
  `,
  registeredAt: css`
    flex: 0 0 100px;
  `,
}

const TableHeadCell = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 44px;
  font-size: ${theme.fontSize.m};
  padding: 0 10px;
  ${({ property }) => propertyStyles[property]}
`
const TableBodyCell = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 60px;
  font-size: ${theme.fontSize.m};
  white-space: pre-wrap;
  padding: 0 10px;
  ${({ property }) => propertyStyles[property]}
`
const TableCellLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  line-height: 1.5;
`
