import React from 'react'
import {
  PageLayoutWithGlonavi,
  DetailPage as Page,
} from '@blue-agency/im-shared-front'
import { Icon as _Icon, theme, Txt as _Txt } from '@blue-agency/rogue'
import { generatePath, Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { assertIsDefined } from '@/assertions'
import { Form } from '@/components/Form'
import { LineClampedTxt } from '@/components/LineClampedTxt'
import { TextLink } from '@/components/Link'
import { Loading } from '@/components/Loading'
import { SettingsSection } from '@/components/SettingsSection'
import { scopeTypeNameOf } from '@/services/ipFilterService'
import { INTERNAL_PATHS } from '@/services/urlService'
import {
  TooltipForIpAddress,
  TooltipForScopeType,
} from '../SettingIpFilterNewPage/Tooltip'
import * as C from '../SettingIpFilterNewPage/components'
import { DeleteIpFilterModal } from './DeleteIpFilterModal'
import { useDeleteIpFilter } from './useDeleteIpFilter'
import { useIpFilter } from './useIpFilter'

export const SettingIpFilterDetailPage: React.VFC = () => {
  const { guid } = useParams<{ guid: string }>()
  const { isLoading, ipFilter } = useIpFilter(guid)
  const { deleteIpFilterModal, onDeleteIpFilter } = useDeleteIpFilter(guid)

  if (isLoading) return <Loading />

  assertIsDefined(ipFilter)

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>IPアドレス制限詳細</Page.HeaderTitle>
          <Page.HeaderRight>
            <Link to={INTERNAL_PATHS.settings.ipfilters.index}>
              <Txt>一覧に戻る</Txt>
            </Link>
          </Page.HeaderRight>
        </Page.Header>
        <Page.Body>
          <C.Container>
            <C.Body>
              <SettingsSection title="フィルター情報">
                <IpFilterToolbar
                  linkToEdit={generatePath(
                    INTERNAL_PATHS.settings.ipfilters.edit,
                    { guid: ipFilter.guid }
                  )}
                  onClickDelete={deleteIpFilterModal.open}
                />
                <Form.Section>
                  <C.SectionWrapper>
                    <Form.Row>
                      <C.FormTitle>フィルター名</C.FormTitle>
                      <Form.Item>
                        <Form.Field>
                          <Txt>{ipFilter.name}</Txt>
                        </Form.Field>
                      </Form.Item>
                    </Form.Row>
                  </C.SectionWrapper>
                  <C.SectionWrapper>
                    <Form.Row>
                      <C.FormTitle>
                        対象範囲
                        <TooltipForScopeType />
                      </C.FormTitle>
                      <Form.Item>
                        <Form.Field>
                          <Txt>{scopeTypeNameOf(ipFilter.scopeType)}</Txt>
                        </Form.Field>
                      </Form.Item>
                    </Form.Row>
                    {ipFilter.scopeType === 'specified_staffs' && (
                      <C.SpecifiedStaffFormRow>
                        <Form.Item>
                          <Form.Field>
                            <C.SpecifiedStaffList>
                              {ipFilter.scopeSpecifiedStaffs.map(
                                ({ guid, name }) => (
                                  <li key={guid}>
                                    <LineClampedTxt line={1}>
                                      {name.fullName}
                                    </LineClampedTxt>
                                  </li>
                                )
                              )}
                            </C.SpecifiedStaffList>
                          </Form.Field>
                        </Form.Item>
                      </C.SpecifiedStaffFormRow>
                    )}
                  </C.SectionWrapper>
                  <C.SectionWrapper>
                    <Form.Row>
                      <C.FormTitle>
                        IPアドレス
                        <TooltipForIpAddress />
                      </C.FormTitle>
                      <Form.Item>
                        <Form.Field>
                          <CidrList>
                            {Object.values(ipFilter.cidrs).map((cidr, idx) => (
                              <li key={'cidr-' + idx}>
                                <CidrAddress>{cidr.ipAddress}</CidrAddress>
                                <CidrSubnet>/{cidr.subnetMask}</CidrSubnet>
                              </li>
                            ))}
                          </CidrList>
                        </Form.Field>
                      </Form.Item>
                    </Form.Row>
                  </C.SectionWrapper>
                </Form.Section>
              </SettingsSection>
            </C.Body>
          </C.Container>
        </Page.Body>
        <DeleteIpFilterModal
          name={ipFilter.name}
          active={deleteIpFilterModal.active}
          onClose={deleteIpFilterModal.close}
          onSubmit={onDeleteIpFilter}
        />
      </Page.Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const Txt = styled(_Txt).attrs({ size: 'm' })`
  line-height: 32px;
`

type IpFilterToolbarProps = {
  linkToEdit: string
  onClickDelete: () => void
}
const IpFilterToolbar: React.VFC<IpFilterToolbarProps> = ({
  linkToEdit,
  onClickDelete,
}) => {
  return (
    <Toolbar>
      <ToolElement>
        <EditButton to={linkToEdit}>
          <Icon name="edit" />
          編集
        </EditButton>
      </ToolElement>
      <ToolElement>
        <DeleteButton to="#" onClick={onClickDelete}>
          <Icon name="delete" />
          削除
        </DeleteButton>
      </ToolElement>
    </Toolbar>
  )
}
const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  margin-bottom: -40px;
  height: 40px;
`
const ToolElement = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 20px;
`
const Icon = styled(_Icon)`
  vertical-align: middle;
  margin-right: 7px;
`
const EditButton = styled(TextLink)`
  color: ${theme.color.navy[1]};
`
const DeleteButton = styled(TextLink)`
  color: ${theme.color.red[2]};
`
const CidrList = styled.ul`
  padding-left: 20px;
  margin: 0;
`
const CidrAddress = styled(_Txt)`
  display: inline;
`
const CidrSubnet = styled(_Txt)`
  display: inline;
  margin-left: 15px;
`
